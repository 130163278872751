@layer components {
  .related-profile {
    &__title {
      @apply mb-7 font-Avenir-NC text-2xl !important;
      @screen sm {
        @apply mb-11 text-3xl !important;
      }
    }
  }
}
