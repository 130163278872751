@layer components {
  .nft-minter-modal {
    @apply py-12 px-7 bg-gray-850 !important;
    min-height: 800px;

    &__form {
      &__disabled {
        @apply pointer-events-none;
        .nft-minter-modal__grid {
          @apply opacity-50;
        }
      }
    }

    @screen md {
      @apply p-10 !important;
    }
    &__close {
      @apply absolute right-0 z-10 cursor-pointer;
    }
    &__submit {
      @apply md:-ml-16 md:pl-16;
      @apply md:-mr-16 md:pr-16;
      @apply border-t border-black;
    }
    &__offer {
      @apply border-b border-black pt-4;
      &-title {
        @apply mb-4 uppercase font-semibold;
      }
    }
    &__bid {
      @apply border-b border-black pt-4;
      &-title {
        @apply mb-4 uppercase font-semibold;
      }
      &-subtitle {
        @apply uppercase mb-4 text-xs;
        &__subtle {
          @apply normal-case text-gray-400;
        }
      }
    }
    &__title {
      /* TODO: use correct negative margins based on modal size */
      @apply -ml-10 pl-10 md:-ml-16 md:pl-16;
      @apply -mr-10 pr-10 md:-mr-16 md:pr-16;
      @apply mb-4 border-b border-black pb-8;
      &-summary {
        @apply mb-0 pb-4 text-2xl;
      }
    }
    &__card {
      @apply pt-0;
      &-title {
        @apply uppercase font-semibold;
      }
    }
    &__owner {
      @apply border-b border-gray-700;
      &-title {
        @apply mb-4 uppercase font-semibold;
      }
      &-detail {
        @apply pb-4 flex items-center;
        &__avatar {
          @apply mr-4;
        }
        &__info {
          &-name {
            @apply mb-1 font-semibold;
          }
        }
      }
    }
    &__form {
      &__otherPayment {
        @apply flex items-center cursor-pointer !important;
      }
      &__column-2 {
        @apply grid-cols-1 !important;
        @screen lg {
          @apply grid-cols-2 !important;
        }
      }
      label {
        font-size: 0.75rem;
      }
    }
    &__summary {
      @apply pt-4;
      &-title {
        @apply uppercase font-semibold;
      }
      &__order {
        @apply py-5;
        &__wrapper {
          @screen md {
            @apply mb-1;
          }
        }
        &__title,
        &__content {
          @apply uppercase;
          &__price {
            @apply text-right;
            &__currency {
              @apply pl-1 text-gray-400;
            }
          }
        }
      }
    }
    &__release {
      @apply border-b border-black pb-4;
      &-title {
        @apply mb-4 uppercase font-semibold;
      }
    }
    &__whatsincluded {
      @apply py-4;
      &-title {
        @apply mb-4 uppercase font-semibold;
      }
      ul {
        @apply text-gray-400 list-disc;
        li:not(:first-child) {
          @apply mt-2;
        }
        a {
          @apply text-gray-400 underline;
        }
      }
    }

    &__grid {
      @apply my-4;
      @apply grid-cols-1 !important;

      @screen md {
        @apply pr-4 grid-cols-12 overflow-y-scroll !important;
        max-height: 420px;
        scrollbar-color: rgba(255, 255, 255, 0.1) rgba(0, 0, 0, 0);
        scrollbar-width: thin;
      }
      &::-webkit-scrollbar {
        @apply w-1.5;
      }

      &::-webkit-scrollbar-track {
        @apply bg-transparent;
      }

      &::-webkit-scrollbar-thumb {
        @apply rounded-lg;
        background: rgba(255, 255, 255, 0.1);
      }
    }
    &__middle {
      /* @apply col-span-1 py-4; */
      @screen md {
        /* @apply col-span-1; */
      }
    }
    &__left {
      @apply col-span-1;
      @screen md {
        @apply col-span-6;
      }
    }

    &__right {
      @apply col-span-1 pt-4;
      @screen md {
        @apply col-span-5 pt-0;
      }
    }

    &__form {
      &__radio,
      &__checkbox {
        @apply inline-block !important;
        label {
          @apply pl-6 relative flex items-center;
          .checkmark {
            @apply w-4 h-4 bg-gray-500 left-0 top-0 absolute;
            border-radius: 50%;
            content: '';
          }
          &:hover ~ .checkmark,
          input:checked ~ .checkmark {
            @apply bg-white;
          }
        }
        input {
          @apply absolute opacity-0 h-0 w-0;
        }
      }
      &__checkbox {
        label {
          .checkmark {
            border-radius: 0px !important;
            &::after {
              @apply absolute hidden border-black;
              content: '';
              left: 6px;
              top: 2px;
              width: 5px;
              height: 10px;
              border-width: 0 1px 1px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
          input:checked ~ .checkmark::after {
            @apply block;
          }
        }
      }
      &--group {
        @apply pb-8;
        &__title {
          @apply mb-4 border-b border-black pb-4 text-xl;
          @screen md {
            @apply text-2xl;
          }
        }
      }
      &__column-2 {
        @apply grid-cols-1 !important;
        @screen lg {
          @apply grid-cols-2 !important;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .nft-minter-modal {
      @apply pb-40 relative overflow-y-scroll !important;
      max-height: 100vh;
      .container {
        @apply p-0 !important;
      }
      &__submit {
        @apply border-transparent fixed left-0 bottom-0 w-full bg-black p-7 pt-3;
        z-index: 9999;
      }
    }
  }

  .nft-minter__form__radio .checkmark {
    @apply mt-2;
  }

  .nft-minter__radio-icon {
    @apply flex;

    > div {
      width: 50%;
    }
  }

  .nft-minter__radio_label {
    @apply flex items-center;

    > svg {
      @apply ml-2 mr-4;
    }
  }
}
