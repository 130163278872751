@layer components {
  .checkout_frame {
    @apply px-5 pb-5;
    iframe {
      /* This fixes a mobile Safari bug */
      height: 38px !important;
    }

    &__validation_error {
      @apply relative w-full h-0 transition mb-2 text-red-400 py-2 px-3 text-xs;
      &.visible {
        @apply h-5;
      }
    }

    &__checkbox {
      @apply inline-block !important;
      label {
        @apply pl-6 relative flex items-center;
        .checkmark {
          @apply w-4 h-4 bg-red-400 left-0 top-0 absolute;
          border-radius: 50%;
          content: '';
        }
        &:hover ~ .checkmark,
        input:checked ~ .checkmark {
          @apply bg-red-400;
        }
        a {
          @apply underline !important;
        }
      }
      input {
        @apply absolute opacity-0 h-0 w-0;
      }
    }
    &__checkbox {
      label {
        .checkmark {
          border-radius: 0px !important;
          &::after {
            @apply absolute hidden;
            content: '';
            left: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
        input:checked ~ .checkmark::after {
          @apply block;
        }
      }
    }
  }
}
