@layer components {
  .all-notifications {
    @apply py-16;

    &__header {
      @apply relative flex justify-between items-center mb-6 border-b border-white border-opacity-30 pb-6;

      .notification-menu {
        @apply text-2xl;
      }
    }

    &__heading {
      @apply text-2xl;

      @screen sm {
        @apply text-3xl;
      }
    }
  }

  .notifications-group {
    @apply mb-8;

    &__name {
      @apply mb-4;
    }

    &__items {
      .notification-item {
        @apply mb-4 border-0 p-4 bg-gray-800;

        &__avatar {
          @apply w-14 h-14 shadow-none;

          .avatar {
            @apply shadow-none;
          }
        }

        &__content {
          @screen sm {
            @apply flex items-center;
          }

          &__detail {
            @apply flex-1;
          }

          &__time {
            @screen sm {
              @apply w-32 text-right;
            }
          }
        }

        &.unread {
          background-color: #383838;

          &:before {
            display: none;
          }
        }
      }
    }
  }

  .notification-empty {
    &__icon {
      @apply flex items-center justify-center;
    }

    &__title {
      @apply flex items-center justify-center font-bold my-3;
    }

    &__body {
      @apply flex items-center justify-center text-center;
    }

    @apply py-4;
  }
}