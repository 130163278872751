@layer components {
  .profile-artist {
    @apply text-primary overflow-y-hidden;
    &__container {
      @apply relative;
      .vertical-line {
        @apply absolute flex w-full px-5;
        z-index: -1;
        gap: 25%;
      }
      .line {
        z-index: -1;
        height: 9999px;
        width: 1px;
        background: #e9e9e9;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
      }
    }
    &__responsive {
      @apply hidden items-center justify-between;
      @media screen and (max-width: 675px) {
        @apply flex;
      }
      &__title {
        @apply font-bold;
        font-size: 26px;
        line-height: 150%;
      }
    }
    &__top {
      @apply grid grid-cols-2;
      @media screen and (max-width: 1024px) {
        @apply grid grid-cols-1;
        &__right {
          @apply mt-5;
        }
      }
      padding-top: 30px;
      &__left {
        @media screen and (max-width: 675px) {
          @apply hidden;
        }
        @apply font-bold;
        margin-right: 150px;
        font-size: 40px;
        &__name {
          @apply underline;
        }
      }
      &__right {
        @apply text-2xl font-medium flex items-center;
        &__name {
          @media screen and (max-width: 675px) {
            @apply text-4xl font-bold;
          }
          font-weight: 900;
          font-size: 70px;
          line-height: 120%;
          letter-spacing: -0.02em;
        }
        &__description {
          @apply font-medium;
          @media screen and (max-width: 675px) {
            @apply text-base;
          }
          margin-top: 30px;
          font-size: 24px;
          line-height: 170%;
          letter-spacing: -0.02em;
        }
      }
    }
    .collectible-profile {
      &__wrapper {
        @apply relative;
        &__description {
          @apply absolute bottom-0 pl-7 pr-12 transition-all ease-in-out duration-500 overflow-hidden;
          height: 50px;
          &__title {
            @apply text-white text-2xl font-bold overflow-hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          &__value {
            @apply text-white text-lg font-medium mt-5 overflow-hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
        &__description:hover {
          @apply transition-all ease-in-out duration-500;
          height: 160px;
        }
      }
    }
    &__cards {
      @apply pt-11 relative;
      @media screen and (max-width: 450px) {
        padding-top: 26px;
      }
      z-index: 2;
      .collectible-slider {
        @apply relative;
      }
      &__slider {
        width: 1280px;
        @media screen and (max-width: 1300px) {
          width: 700px;
        }
        @media screen and (max-width: 450px) {
          width: 100%;
        }
        .slick-custom-pagging {
          @apply absolute w-full;
          max-width: 410px;
          left: 50%;
          @media screen and (max-width: 450px) {
            max-width: 250px;
            left: 20%;
          }
          transform: translateX(-50%);
          bottom: -5px;
          margin-top: 10px;
          /* display: flex !important; */
          display: none !important;
          flex-direction: row;
          justify-content: center;
          li {
            width: 100%;
            height: 5px;
            background-color: #ebebeb;
          }
          li:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
          li:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
          li.slick-active {
            background-color: #2a2a2a;
            border-radius: 5px;
          }
        }
      }
      .slider-arrow {
        pointer-events: none;
        display: flex;
        position: absolute;
        top: 45%;
        z-index: 9999;
        height: 100%;
        width: 100%;
        @media screen and (max-width: 450px) {
          svg {
            height: 40px;
            width: 40px;
          }
        }
      }
      .slide-arrow-prev {
        pointer-events: none;
        @apply mr-auto;
        &-button {
          pointer-events: fill;
          @apply rounded cursor-pointer;
          backdrop-filter: blur(5px);
        }
      }
      .slide-arrow-next {
        pointer-events: none;
        &-button {
          pointer-events: fill;
          @apply rounded cursor-pointer;
          backdrop-filter: blur(5px);
        }
      }
      .next {
        float: right;
      }
      .slick-list {
        overflow: visible !important;
        @media screen and (max-width: 450px) {
          overflow: hidden !important;
        }
      }
      &__wrapper {
        width: 600px !important;
        @media screen and (max-width: 450px) {
          width: 100% !important;
        }
      }
    }
    &__collectible {
      @apply mt-20;
      @media screen and (max-width: 675px) {
        margin-top: 50px;
      }
      &__filter {
        @apply flex;
        .marketplace-page_search {
          @media screen and (max-width: 675px) {
            @apply hidden;
          }
        }
        &__field {
          @apply text-xl font-bold mr-2.5;
          color: #a0a0a0;
        }
        &__option {
          @apply text-xl font-bold;
        }
        &__left {
          @apply flex mr-auto;
        }
        &__right {
          @apply flex;
        }
      }
      &__grid {
        @apply mt-12 text-center;
        @media screen and (max-width: 675px) {
          margin-top: 26px;
        }
        &__wrapper {
          @apply grid grid-cols-3 gap-5;
          @media screen and (max-width: 1024px) {
            @apply grid grid-cols-2;
          }
          @media screen and (max-width: 600px) {
            @apply grid grid-cols-1;
          }
        }
      }
    }
    &__empty__box {
      @apply mt-24;
      &__icon {
        @apply w-full flex justify-center;
        &__wrapper {
          width: 200px;
        }
      }
      &__title {
        @apply text-4xl font-bold;
      }
      &__description {
        @apply text-lg text-secondary mt-4;
      }
    }
    &__about {
      padding-top: 80px;
      padding-bottom: 80px;
      @media screen and (max-width: 675px) {
        padding-top: 50px;
        padding-bottom: 50px;
      }
      margin-top: 50px;
      background-color: #f2f2f2;
      &__wrapper {
        @apply grid grid-cols-2;
        @media screen and (max-width: 1024px) {
          @apply grid grid-cols-1;
          &__right {
            @apply mt-5;
          }
        }
        &__left {
          max-width: 500px;
          &__title {
            @apply text-secondary font-medium uppercase;
            @media screen and (max-width: 675px) {
              @apply text-base;
            }
            font-size: 26px;
            line-height: 100%;
          }
          &__creator {
            @media screen and (max-width: 675px) {
              @apply text-4xl mt-0;
            }
            @apply mt-4;
            font-weight: 900;
            font-size: 70px;
            line-height: 120%;
            letter-spacing: -0.02em;
          }
        }
        &__right {
          @media screen and (max-width: 675px) {
            @apply text-base;
          }
          font-weight: 400;
          font-size: 18px;
          line-height: 170%;
          letter-spacing: -0.02em;
        }
      }
    }
  }
  .profile-fans {
    @apply text-primary;
    &__top {
      @apply flex items-center;
      &__wrapper {
        @apply m-auto;
        max-width: 500px;
        @media screen and (max-width: 675px) {
          margin-top: 76px;
        }
        @apply mt-28 text-center;
        &__name {
          @media screen and (max-width: 675px) {
            font-size: 26px;
            line-height: 150%;
          }
          @apply text-4xl font-bold;
        }
        &__bio {
          @apply text-lg text-secondary;
          @media screen and (max-width: 675px) {
            @apply text-base;
          }
          margin-top: 10px;
        }
      }
    }
    &__collectible {
      margin-top: 40px;
      @media screen and (max-width: 675px) {
        .tab-buttons {
          margin-left: -1.5rem;
          margin-right: -1.5rem;
        }
      }
      @media screen and (max-width: 675px) {
        margin-top: 26px;
        margin-bottom: 0px;
      }
      margin-bottom: 80px;
      &__grid {
        @apply pt-12 text-center;
        .btn {
          @apply uppercase;
        }
        @media screen and (max-width: 675px) {
          margin-left: -1.5rem;
          margin-right: -1.5rem;
          padding-left: 1.5rem;
          padding-right: 1.5rem;
          background-color: #f3f3f3;
          padding-bottom: 50px;
        }
        &__wrapper {
          @apply grid grid-cols-3 gap-7;
          min-height: 100px;
          @media screen and (max-width: 1024px) {
            @apply grid grid-cols-2;
          }
          @media screen and (max-width: 675px) {
            @apply grid grid-cols-1;
          }
        }
      }
    }
  }
}
