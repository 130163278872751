@layer components {
    .artist-profile {
        @apply flex items-center;
        &__detail {
          @apply ml-3;
          &__name {
            @apply font-bold text-left;
            font-size: 20px;
          }
          &__description {
            @apply text-left mt-3;
            color: #5C5C5C;
            font-size: 16px;
          }
        }
      }
  }
  