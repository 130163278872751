body {
  @apply text-sm text-white bg-gray-50 font-Satoshi overflow-x-hidden;
}

main {
  padding-top: 85px;
  overflow: unset;
}

a {
  @apply text-primary;
}

h3 {
  @apply font-Satoshi;
}

h2 {
  @apply font-Satoshi;
}

/* custom tooltip */
.type-dark {
  background-color: #2b2b2b !important;
  opacity: 1 !important;
  transition: opacity 1s ease-out 0s !important;
  border-radius: 8px;
}

.type-dark > div {
  max-width: 450px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.__react_component_tooltip {
  border-radius: 8px !important;
  padding: 12px !important;
}

/* custom toast */
.Toastify__toast {
  min-height: unset !important;
}

.Toastify__toast-container {
  width: unset !important;
  max-width: 50vw;
  @media screen and (max-width: 1280px) {
    max-width: unset !important;
  }
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #05a763 !important;
  padding: 11.5px !important;
  padding-right: 0px !important;
  .Toastify__close-button > svg {
    height: 20px !important;
    width: 20px !important;
  }
  .Toastify__close-button {
    margin: auto 15px;
  }
  .Toastify__toast-body {
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    font-weight: 500;
    font-size: 16px;
    line-height: 170%;
    padding: 0px;
    padding-right: 15px;
  }
  .Toastify__toast-icon {
    svg {
      display: none;
    }
    background-image: url('@assets/img/check_circle.png');
    width: 20px;
    height: 20px;
  }
}

/* PLEASE SHORT ALPHABETICALLY */
@import '@components/alert/patrons/style.css';
@import '@components/artist-card/style.css';
@import '@components/artist-profile/style.css';
@import '@components/avatar/patrons/style.css';
@import '@components/button/patrons/style.css';
@import '@components/card-slider/style.css';
@import '@components/collectible-card/patrons/style.css';
@import '@components/collectible-card.v2/patrons/style.css';
@import '@components/collectible-owner-actions/patrons/style.css';
@import '@components/connect-wallet-button/patrons/style.css';
@import '@components/custom-dropdown/style.css';
@import '@components/exchange-table/patrons/style.css';
@import '@components/follow-button/patrons/style.css';
@import '@components/footer/patrons/style.css';
@import '@components/footer/coming-soon/patrons/style.css';
@import '@components/form/patrons-style.css';
@import '@components/gallery-card/style.css';
@import '@components/header/patrons/style.css';
@import '@components/header/coming-soon/patrons/style.css';
@import '@components/media/patrons/style.css';
@import '@components/modal/patrons/style.css';
@import '@components/nft-address/patrons/style.css';
@import '@components/notification-menu/patrons/style.css';
@import '@components/not-found/patrons/style.css';
@import '@components/offer-modal/patrons/style.css';
@import '@components/order-history-table/patrons/style.css';
@import '@components/profile-header/patrons/style.css';
@import '@components/purchase-card/patrons/style.css';
@import '@components/release-checkout/patrons/style.css';
@import '@components/responsive-modal/style.css';
@import '@components/separator/patrons/style.css';
@import '@components/skeleton-loading/patrons/style.css';
@import '@components/social-login/patrons/style.css';
@import '@components/social-media/patrons/style.css';
@import '@components/share-link/patrons/style.css';
@import '@components/table/patrons/style.css';
@import '@components/tabs/patrons/style.css';
@import '@components/user-menu/patrons/style.css';
@import '@components/user-link/patrons/style.css';
@import '@components/wallet-history-table/patrons/style.css';
@import '@components/withdrawal-modal/patrons/style.css';

@import '@pages/artists/style.css';
@import '@pages/auth/patrons/style.css';
@import '@pages/checkout/patrons/style.css';
@import '@pages/collect-collectible/style.css';
@import '@pages/collectible/patrons/style.css';
@import '@pages/collectors/style.css';
@import '@pages/confirmation/patrons/style.css';
@import '@pages/congratulation/patrons/style.css';
@import '@pages/flex/patrons/style.css';
@import '@pages/galleries/style.css';
@import '@pages/gallery-profile/style.css';
@import '@pages/landing/patrons/style.css';
@import '@pages/marketplace/patrons/style.css';
@import '@pages/mint/patrons/style.css';
@import '@pages/not-found/patrons/style.css';
@import '@pages/print-collectible/patrons/style.css';
@import '@pages/print-collectible/patrons/print-checkout/style.css';
@import '@pages/print-collectible/patrons/print-checkout/print-collectible-summary/style.css';
@import '@pages/profile/patrons/style.css';
@import '@pages/sell/patrons/style.css';
@import '@pages/settings/patrons/transactions/style.css';
@import '@pages/settings/patrons/style.css';
@import '@pages/single-page/patrons-style.css';
@import '@pages/release/patrons/style.css';
