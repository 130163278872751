@layer components {
  .table-responsive {
    @apply whitespace-nowrap overflow-x-auto block;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      @apply hidden;
    }
  }

  .table-responsive-mobile-only {
    @apply whitespace-nowrap;

    @screen sm {
      @apply whitespace-pre-wrap;
    }
  }

  .table {
    @apply w-full table-auto;

    &-border {
      thead,
      tbody {
        td,
        th {
          @apply relative;

          &::after {
            @apply w-full block absolute bottom-0 left-0;
            background-color: #f0f0f0;
            content: '';
            height: 1px;
          }
        }
      }
    }

    th {
      @apply text-base text-left text-primary capitalize font-bold;
    }

    td {
      @apply font-Satoshi text-base capitalize;

      @screen sm {
        @apply text-base;
      }
    }

    th,
    td {
      @apply py-3 px-2;

      &:first-child {
        @apply pl-5;
      }

      &:last-child {
        @apply pr-5;
      }
    }

    tbody {
      tr {
        &:last-child {
          th,
          td {
            @apply border-none;
          }
        }

        &.active {
          @apply bg-gray-750;
        }
      }
    }
  }

  .vertical-table {
    td {
      @apply font-Satoshi text-sm normal-case !important;

      &:first-child {
        @apply font-semibold pl-0;
        width: 28%;
      }
    }

    tr {
      &:last-child {
        @apply border-0;

        &::after {
          @apply hidden;
        }
      }
    }
  }
}
