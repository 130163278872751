.alert {
  @apply my-3 border-white border-0 rounded-sm p-3 bg-white text-black;
  &.alert--default {
    a {
      @apply text-black;
    }
    .alert__action {
      .btn {
        @apply border-black text-black;
        &:hover {
          @apply bg-black text-white;
        }
      }
    }
  }
  &.alert--info {
    @apply bg-blue-solid text-white;
  }
  &.alert--success {
    /* @apply bg-green-solid text-white; */
    @apply text-primary;
    background: rgba(32, 199, 94, 0.1);
    border: 2px solid #15803D;
    border-radius: 4px;
  }
  &.alert--warning {
    @apply bg-orange-solid text-white;
  }
  &.alert--danger {
    @apply text-primary;
    background: rgba(251, 87, 88, 0.1);
    border: 2px solid #FB5758;
    border-radius: 4px;
  }
  &__heading {
    @apply mb-1 font-bold;
  }
  &__content {
    a {
      @apply underline;
      &:hover {
        @apply no-underline;
      }
    }
  }
  &__action {
    @apply mt-3;
    .btn {
      @apply bg-transparent text-white text-lg leading-tight;
      &:hover {
        @apply bg-white text-black;
      }
    }
  }
  &__close {
    @apply absolute top-0 bottom-0 right-0 px-4 py-3;
  }
}

.Toastify__progress-bar {
  @apply opacity-70 !important;
}
.Toastify__toast {
  padding: 7px 4px 7px 10px !important;
}
.Toastify__close-button > svg {
  width: 12px !important;
  height: 12px !important;
}

.Toastify__toast-body {
  @apply antialiased font-sans !important;
}
.Toastify__toast-theme--colored.Toastify__toast {
  &--success {
    @apply bg-green-solid !important;
  }
  &--info {
    @apply bg-blue-solid !important;
  }
  &--error {
    @apply bg-red-solid !important;
  }
  &--warning {
    @apply bg-orange-solid !important;
  }
}
