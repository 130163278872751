@layer components {
  .collectible-skeleton {
    @apply mb-5;
    &__image {
      height: 400px;
    }
    &__new {
      height: 512px;
    }
    &__title {
      @apply mt-3;
      height: 60px;
    }
    &__button-group {
      @apply flex items-center justify-end space-x-4;
    }
    &__button {
      @apply mt-3 w-1/3;
      height: 40px;
    }
  }
}
