@layer components {
  .listing-table-infinite-scroll {
    @apply mb-6;

    @screen sm {
      @apply mb-14;
    }

    &__header {
      @apply sticky top-0 left-0 w-full z-10;
      @apply bg-primary;
      @apply grid-cols-1 pb-6 !important;

      @screen md {
        @apply pb-2 grid-cols-2 !important;
      }

      &__name {
        @apply flex;
        @apply items-center justify-between md:justify-start;
        @media screen and (max-width: 420px) {
          @apply block;
        }
        &__title {
          @apply mr-10;
        }
        &__display {
          @apply flex;
          &__filter {
            @apply flex;
            @apply items-center;
            @apply mr-5;
            @media screen and (max-width: 420px) {
              @apply mr-1;
            }
          }
          &__sort {
            @apply flex;
            @apply items-center;
          }
        }
      }
      .search-form {
        @apply mt-4 md:mt-2 pl-11 float-none w-full leading-loose md:leading-relaxed font-sans text-sm uppercase;
        -moz-appearance: textfield;
        padding-block: 6px;
        @screen md {
          @apply mt-0 float-right;
          width: 295px;
        }
        background: url('@assets/svg/white-search.svg') no-repeat 11px center, #1a1a1a !important;
        &::placeholder {
          @apply text-gray-400;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
      }
    }

    &__group {
      @apply shadow-inner-section bg-gray-800 py-6;
      max-height: 640px;

      @apply relative overflow-scroll;

      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
      &__loading {
        @apply py-3;
      }
      &__empty {
        @apply p-6 pb-0;
      }
    }

    &__edition {
      @apply flex;
    }

    .user-link__tooltip {
      @apply left-12;
    }

    &__opensea-tooltip {
      @apply absolute left-24 cursor-default font-sans text-sm;

      .collectible-tooltip-content {
        @apply bg-black;

        &-show {
          @apply z-10;
        }
      }

      .collectible-tooltip-arrow {
        @apply border-black;
      }
    }

    .table td {
      padding-block: 15px;
    }

    &__load-more-component {
      @apply mt-4;
    }
  }
}
