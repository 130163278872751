@layer components {
  .collectible {
    .claim-collectible-card {
      @apply mb-6 p-6 border border-gray-800 bg-white-gradient w-full;
      &__user {
        @apply mb-3 md:mb-5 flex items-center;
        &__avatar {
          @apply mr-4 shadow-none !important;
        }
      }
      &__title {
        @apply mb-3 md:mb-5 text-lg font-Avenir-NC font-semibold uppercase;
      }
      &__description {
        @apply mb-3 md:mb-5;
      }
      &__icon {
        @apply mt-1 mx-auto mb-6;
      }
      &__column {
        @apply grid-cols-1 !important;
        @screen lg {
          @apply grid-cols-2 !important;
        }
      }
      @screen md {
        @apply p-8;
      }
    }
  }

  .claim-collectible-card {
    &__modal {
      .modal {
        max-width: 935px;
      }
      &__grid {
        @apply grid-cols-1 md:grid-cols-2 !important;
      }
      &__title {
        @apply my-2 md:mt-0 mb-2 text-center text-2xl md:text-3xl !important;
        line-height: 40px;
      }
      &__description {
        @apply text-center;
        font-size: 14px;
        line-height: 22px;
      }
      &__own {
        @apply w-full my-4;
      }
      .collectible-card-new {
        @apply mx-auto;
        max-width: 387px;
      }
    }
  }
}
