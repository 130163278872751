@layer components {
  .collectible-tier {
    @apply border border-gray-700 font-Avenir-NC text-xs leading-4 uppercase bg-primary bg-opacity-80 font-semibold antialiased;
    margin-right: 5px;
    padding: 5px 10px;
    &-do {
      @apply border-white text-black bg-digital-original !important;
    }
  }
}
