@layer components {
  .currency {
    @apply inline-block text-black relative;
    &__list {
      @apply absolute right-0 bottom-full mb-3 bg-white;
      box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
      li {
        @apply pl-1 pr-5 cursor-pointer;
        padding-top: 2px;
        padding-bottom: 2px;
        font-size: 10px;
        &:hover {
          @apply bg-gray-200;
        }
      }
      &__item {
        @apply flex items-center;
        &-icon {
          @apply mr-1;
          svg {
            height: 15px;
            width: 15px;
          }
        }
      }
    }
    &__selected {
      @apply ml-5 flex items-center cursor-pointer;
      &-label {
        margin-right: 5px;
        font-size: 10px;
      }
      &-flag {
        svg {
          margin-right: 5px;
          width: 20px;
          height: 20px;
        }
      }
      &-icon {
        svg {
          height: 7px;
        }
      }
    }
  }
}
