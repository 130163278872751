@layer components {
  .withdrawal-history {
    @apply py-2 w-full;
    @screen sm {
      @apply py-3 flex justify-between;
      &-right {
        @apply text-right;
      }
    }
    &-date,
    &-status {
      @apply text-gray-400;
    }
  }
}
