@layer components {
  .release-checkout {
    .edition-badge--digital {
      svg {
        width: 56px;
        height: 56px;
      }

      .edition-badge__edition {
        @apply top-3 text-2xl;
      }
    }

    &__media {
      @apply relative;

      .media__image {
        height: 385px;
        --tw-aspect-h: 0 !important;
      }
    }

    &__title {
      @apply font-semibold capitalize text-xl text-primary mb-2 mt-5;
      @screen md {
        @apply text-2xl text-primary;
        @apply mt-0;
      }
    }

    &__tier {
      @apply text-primary text-base leading-7;
    }

    &__url {
      @apply text-primary hover:underline;
    }
    &__owner {
      @apply text-primary text-base font-normal;
      &__bold {
        @apply font-bold;
      }
    }

    &-right {
      @apply block md:flex items-center antialiased;
    }

    &__thumbnail {
      @apply w-36 h-36 mr-4;
      .media__image {
        border-radius: 4px;
        min-width: 150px !important;
        min-height: 150px !important;
      }
    }
  }
}
