@layer components {
  .collectible-timer-new {
    @apply bg-gray-850 font-Avenir-NC uppercase font-semibold antialiased;
    padding: 14px 10px;

    .countdown {
      @apply flex items-center justify-between text-xs;

      &-separator {
        @apply bg-white bg-opacity-20;
        width: 1px;
        height: 10px;

        &:nth-child(2) {
          @apply hidden;

          @screen md {
            @apply block;
          }
        }
      }

      @screen xl {
        @apply text-sm;
      }
    }

    @screen xl {
      padding: 12px 10px;
    }

    &__label {
      @apply hidden;

      @screen md {
        @apply block;
      }
    }
  }
}
