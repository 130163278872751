@layer components {
  .skeleton-loading {
    display: block;
    min-height: 8px;
    background: linear-gradient(to right,
        rgba(200, 200, 200, 1),
        rgba(220, 220, 220, 1) 50%,
        rgba(200, 200, 200, 1) 100%),
      #c8c8c8;
    background-repeat: repeat-y;
    background-size: 100px 500px;
    background-position: 0 0;
    animation: shine 1s infinite;

    @keyframes shine {
      to {
        background-position: 100% 0,
          /* move highlight to right */
          0 0;
      }
    }
  }
}