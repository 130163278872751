@layer components {
  .modal-wrapper {
    @apply fixed justify-center w-full h-full left-0 top-0 overflow-y-scroll;

    z-index: 9999;
    padding: 6% 0 5%;

    &__full {
      padding: 0;
    }

    &::-webkit-scrollbar {
      @apply hidden;
    }

    &-checkout {
      padding: 0px;
      .modal {
        @apply bg-gray-850 !important;
      }
      @screen md {
        padding: 5vh 0 5vh;
      }
      @media screen and (max-width: 768px) {
        .modal {
          @apply pb-60;
        }
      }
      @media screen and (max-width: 768px) {
        &-print {
          .modal {
            @apply pb-20 min-h-screen;
          }
        }
      }
      .container {
        &--xl {
          @apply pr-0 pl-0 !important;
        }
      }
    }
    &__overlay {
      @apply fixed w-full h-full top-0 left-0 bg-black z-10;
    }
    &-open {
      animation: fadeIn 250ms !important;
    }
    &-close {
      animation: fadeOut 250ms !important;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .modal {
    @apply mx-auto relative z-20 bg-white text-primary;

    &.unpadded {
      @apply p-0;
    }

    &-vertical {
      &-center {
        @apply absolute left-0 right-0;

        top: 50%;
        transform: translateY(-50%);
      }
    }

    &-loading {
      @apply relative;
      &::after {
        @apply w-full h-full absolute top-0 left-0 bg-black bg-opacity-50 bg-no-repeat bg-center;
        background-image: url('@assets/svg/spinner-white.svg');
        background-size: 30px;
        content: '';
        z-index: 99;
      }
    }

    &-md {
      max-width: 600px;
    }

    &-profile {
      @screen sm {
        min-height: 30rem;
        max-width: 63%;
        transform: translate(0, -50%);

        .modal__content > div {
          min-height: 20rem;
        }
      }
    }

    &-lg {
      max-width: 72rem;
    }

    &-full {
      @apply m-16;
      max-width: unset;
    }

    &-full-page {
      @apply w-full h-full;
      .modal__content {
        @apply p-0;
      }
    }

    &-fit {
      width: fit-content;
    }

    &__content {
      @apply py-6 px-5;
      @screen sm {
        @apply px-9;
      }
    }

    &__heading {
      @apply p-5 pb-0 relative;
      @screen sm {
        @apply py-6 pb-0 px-9;
      }
      &__title {
        @apply pr-8 text-2xl capitalize text-primary font-bold;
        @screen md {
          @apply text-2xl;
        }
      }
      &__close {
        @apply absolute right-0 z-10 cursor-pointer text-primary;
        top: 50%;
        transform: translateY(calc(-50%));
        &-only {
          @apply absolute right-4 top-4 z-10 w-5 h-5 cursor-pointer;
        }
      }
      &__error {
        @apply absolute right-0 z-10 text-red-300;
        top: 50%;
        transform: translateY(calc(-50% - 10px));
      }
    }
    &__action {
      @apply mt-12 text-right;
      &__cancel {
        @apply mr-3;
      }
    }
  }
}
