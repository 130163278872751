@layer components {
  .mint-nft-button {
    button {
      @apply my-2;
    }

    &__status {
      @apply mb-2;
    }
  }
}
