@layer components {
  .purchase-modal {
    .modal {
      max-width: 588px !important;
    }
    &-header {
      @apply border-gray-750 border-b pb-5 text-center;
      svg {
        @apply m-auto mb-5 w-40;
      }
    }
    &-body {
      @apply border-gray-750 border-b py-5;
      @screen sm {
        @apply flex;
      }
      &__image {
        @apply mb-4;
        @screen sm {
          @apply mr-4 w-40 h-40;
        }
      }
      &__description {
        &__title {
          @apply pb-5 text-2xl;
        }
      }
      &__table {
        @apply flex;
        &:not(&:last-child) {
          @apply pb-2;
        }
        &-first {
          min-width: 100px;
        }
      }
    }
    &-summary {
      @apply pb-5;
      @screen sm {
        @apply pb-9;
      }
      &-title {
        @apply py-4 uppercase;
      }
      &-content {
        @apply flex justify-between items-center pb-2 uppercase;
      }

      .price {
        @apply mr-1;
      }
    }
    &-buttons {
      @apply text-center;
      .btn {
        min-width: 100%;
        @screen sm {
          min-width: 170px;
        }
        &:first-child {
          @apply mb-1;
          @screen sm {
            @apply mr-2 mb-0;
          }
        }
      }
    }
  }
}
