@layer components {
  .mint-landing {
    @apply text-primary;
    &__top {
      @apply flex justify-center;
      margin-top: 50px;
      margin-bottom: 80px;
      &__title {
        font-weight: 900;
        font-size: 70px;
        line-height: 120%;
        letter-spacing: -0.02em;
      }
      &__description {
        @apply text-2xl font-medium text-secondary;
        letter-spacing: -0.02em;
        margin-top: 30px;
      }
    }
    &__wrapper {
      @apply text-center;
      max-width: 850px;
    }
    &__section {
      @apply flex items-center justify-center w-full;
      @media screen and (max-width: 768px) {
        align-items: start;
        flex-direction: column;
        &__middle__left {
          @apply order-last;
        }
        &__bottom {
          flex-direction: column;
          align-items: start !important;
        }
      }
      margin-bottom: 30px;
      gap: 20px;
      &__wrapper {
        width: 1047px;
      }
      &__right {
        &__title {
          @apply text-4xl font-bold;
          letter-spacing: -0.02em;
        }
        &__description {
          @apply text-lg text-secondary;
          margin-top: 30px;
          max-width: 585px;
          letter-spacing: -0.02em;
        }
      }
      &__left {
        margin-right: auto;
      }
      &__middle {
        &__right {
          &__title {
            @apply text-4xl font-bold;
            letter-spacing: -0.02em;
          }
          &__description {
            @apply text-lg text-secondary;
            margin-top: 30px;
            max-width: 585px;
            letter-spacing: -0.02em;
          }
        }
        &__left {
          margin-right: auto;
        }
      }
      img {
        width: 386px;
        height: 366px;
        max-width: unset;
      }
      &__bottom {
        @apply flex items-center;
        background-color: #f2f2f2;
        padding: 50px;
        gap: 20px;
        margin-top: 70px;
        margin-bottom: 69px;
        &__left {
          @apply mr-auto;
          max-width: 592px;
          &__title {
            @apply text-4xl font-bold;
            letter-spacing: -0.02em;
          }
          &__description {
            @apply text-lg mt-4;
            letter-spacing: -0.02em;
            max-width: 484px;
          }
        }
      }
    }
  }
}
