@layer components {
  .social-media {
    @apply text-black;

    &__menu {
      @apply flex;

      a {
        @apply relative inline-block px-2 text-black;

        svg {
          height: 20px;
          width: 20px;
        }

        &::before {
          @apply absolute left-0 right-0 h-px transition duration-200 ease-in origin-right bg-black -bottom-2;
          content: '';
          transform: scaleX(0);
        }

        &:hover {
          &::before {
            @apply origin-left;
            transform: scaleX(1);
          }
        }
      }
    }

    &--dark {
      a {
        @apply text-white;

        &::before {
          @apply bg-white;
        }
      }
    }
  }
}