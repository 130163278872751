@layer components {
  .landing-page {
    color: #2b2b2b;
    &__top {
      &__container {
        @apply relative;
        .vertical-line {
          @apply absolute flex w-full px-5;
          gap: 25%;
        }
        .line {
          z-index: -1;
          height: 800px;
          width: 1px;
          background: #e9e9e9;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }
      }
      &__grid {
        @apply grid grid-cols-2;
        @media screen and (max-width: 1000px) {
          @apply grid grid-cols-1;
          &__left {
            @apply my-10;
            &__body {
              @apply m-0 !important;
              &__title {
                font-size: 40px !important;
              }
            }
          }
        }
        &__right {
          @apply items-center w-full pr-0 pl-32;
          padding-top: 30px;
          @media screen and (max-width: 1000px) {
            @apply p-0;
          }
        }
        &__left {
          @apply flex p-2;
          &__body {
            &__button {
              @apply uppercase;
            }
            @apply m-auto;
            &__title {
              @apply text-primary;
              font-weight: 900;
              font-size: 70px;
              line-height: 120%;
            }
            &__description {
              @apply text-2xl font-medium py-10;
              line-height: 170%;
              &__wrapper {
                max-width: 516px;
              }
            }
          }
        }
      }
    }
    &__featured {
      @apply text-white;
      background-color: #2b2b2b;
      &__container {
        @apply relative;
        .vertical-line {
          @apply absolute flex w-full px-5;
          gap: 25%;
        }
        .line {
          z-index: 1;
          height: 772px;
          width: 1px;
          background: #414141;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }
      }
      &__body {
        padding-top: 80px;
        padding-bottom: 80px;
        &__title {
          @media screen and (max-width: 450px) {
            font-size: 26px;
          }
          @apply font-bold text-4xl;
          background-color: #2b2b2b;
        }
      }
    }
    &__description {
      @apply relative z-10;
      background-color: #f2f2f2;
      &__body {
        @media screen and (max-width: 1000px) {
          @apply pl-0;
        }
        @apply pl-10;
        padding-top: 80px;
        padding-bottom: 80px;
        &__title {
          @apply w-full flex font-bold text-center;
          &__wrapper {
            @apply m-auto;
            @media screen and (max-width: 450px) {
              font-size: 26px;
            }
            font-size: 36px;
            line-height: 150%;
            max-width: 990px;
          }
        }
        &__steps {
          @apply grid grid-cols-3 mt-8;
          @media screen and (max-width: 1000px) {
            @apply grid grid-cols-2 mt-8;
          }
          @media screen and (max-width: 750px) {
            @apply grid grid-cols-1 mt-8;
          }
          &__section {
            &__second {
              @apply relative pl-4;
              padding-top: 50px;
            }
            @apply relative pl-4;
            padding-top: 50px;
            &__number {
              @media screen and (max-width: 750px) {
                font-size: 140px;
              }
              @apply absolute font-Owners-Text font-bold;
              right: 0px;
              z-index: 1;
              top: 31px;
              font-size: 180px;
              line-height: 100%;
              background: linear-gradient(180deg, #e9e9e9 0%, rgba(222, 222, 222, 0) 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }
            &__title {
              @apply font-bold pt-4;
              font-size: 26px;
              line-height: 150%;
            }
            &__description {
              @media screen and (max-width: 1000px) {
                max-width: unset;
                font-weight: 400;
              }
              @apply text-lg pt-4 font-medium;
              max-width: 310px;
            }
          }
        }
      }
    }
    &__explore {
      &__container {
        .collectible-slider-test {
          @apply relative;
          padding-bottom: 0px;
        }
        @apply relative;
        .vertical-line {
          @apply absolute flex w-full px-5;
          gap: 25%;
        }
        .line {
          z-index: -1;
          height: 1000px;
          width: 1px;
          background: #e9e9e9;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }
        &__body {
          padding-top: 80px;
          padding-bottom: 80px;
          &__top {
            @apply flex w-full justify-between items-center;
            &__title {
              @media screen and (max-width: 450px) {
                font-size: 26px;
              }
              @apply font-bold text-4xl;
            }
            &__filter {
              @apply flex hidden;
              @media screen and (max-width: 960px) {
                @apply hidden;
              }
              &__field {
                @apply text-xl font-bold mr-2.5;
                color: #a0a0a0;
              }
              &__option {
                @apply text-xl font-bold;
              }
              &__left {
                @apply flex mr-5;
              }
              &__right {
                @apply flex;
              }
            }
            &__link {
              z-index: 2;
            }
            &__explore {
              @apply flex items-center font-bold text-lg cursor-pointer;
              &__text {
                @media screen and (max-width: 830px) {
                  @apply hidden;
                }
              }
            }
          }
          &__bottom {
            @apply text-center;
            margin-top: 42px;
          }
          .slick-custom-pagging {
            display: none !important;
            flex-direction: row;
            justify-content: center;
            li {
              width: 72px;
              height: 5px;
              background-color: #ebebeb !important;
            }
            li:first-child {
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
            }
            li:last-child {
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            }
            li.slick-active {
              background-color: #2a2a2a !important;
              border-radius: 5px;
            }
          }
        }
      }
    }
    &__artists {
      background-color: #f2f2f2;
      z-index: 2;
      &__body {
        padding: 80px 0px;
        &__top {
          @apply flex w-full items-center;
          &__title {
            @media screen and (max-width: 450px) {
              font-size: 26px;
            }
            @apply font-bold text-4xl mr-auto;
          }
          &__filter {
            @apply flex;
            &__field {
              @apply text-xl font-bold mr-2.5;
              color: #a0a0a0;
            }
            &__option {
              @apply text-xl font-bold;
            }
          }
          &__link {
            z-index: 2;
          }
          &__explore {
            &__text {
              @media screen and (max-width: 830px) {
                @apply hidden;
              }
            }
            @apply flex items-center font-bold text-lg cursor-pointer;
          }
        }
        &__table {
          .artist-profile {
            padding-bottom: 0px;
          }
          @apply mt-11;
          thead,
          tbody {
            td,
            th {
              @apply relative;
              &::after {
                background-color: #dbdbdb;
              }
            }
            td:last-child {
              width: 420px;
              overflow: hidden;
            }
          }
        }
        &__bottom {
          @apply text-center;
          margin-top: 42px;
        }
      }
    }
    &__collector {
      .collectible-slider {
        @apply relative;
      }
      &__container {
        @apply relative;
        .vertical-line {
          @apply absolute flex w-full px-5;
          gap: 25%;
        }
        .line {
          z-index: -1;
          height: 840px;
          width: 1px;
          background: #e9e9e9;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }
        &__body {
          padding: 80px 0px;
          &__top {
            @apply flex w-full justify-between items-center;
            &__title {
              @media screen and (max-width: 450px) {
                font-size: 26px;
              }
              @apply font-bold text-4xl;
            }
            &__filter {
              @apply flex;
              &__field {
                @apply text-xl font-bold mr-2.5;
                color: #a0a0a0;
              }
              &__option {
                @apply text-xl font-bold;
              }
            }
          }
          &__cards {
            @media screen and (max-width: 450px) {
              padding-top: 26px;
            }
            @apply pt-11 relative;
            z-index: 2;
            .header__container__middle__menu__dropdown__list__artists {
              @apply pt-6 pl-0;
            }
            &__slider {
              width: 1260px;
              @media screen and (max-width: 1300px) {
                width: 900px;
              }
              @media screen and (max-width: 800px) {
                width: 430px;
                @media screen and (max-width: 450px) {
                  width: 100%;
                }
              }
              .slick-custom-pagging {
                @apply absolute w-full;
                max-width: 410px;
                left: 50%;
                @media screen and (max-width: 450px) {
                  max-width: 250px;
                  left: 40%;
                }
                transform: translateX(-50%);
                bottom: -5px;
                /* display: flex !important; */
                display: none !important;
                flex-direction: row;
                justify-content: center;
                li {
                  width: 100%;
                  height: 5px;
                  background-color: #ebebeb;
                }
                li:first-child {
                  border-top-left-radius: 5px;
                  border-bottom-left-radius: 5px;
                }
                li:last-child {
                  border-top-right-radius: 5px;
                  border-bottom-right-radius: 5px;
                }
                li.slick-active {
                  background-color: #2a2a2a;
                  border-radius: 5px;
                }
              }
            }
            .slider-arrow {
              pointer-events: none;
              display: flex;
              position: absolute;
              top: 45%;
              z-index: 9999;
              height: 100%;
              width: 100%;
              @media screen and (max-width: 450px) {
                svg {
                  height: 40px;
                  width: 40px;
                }
              }
            }
            .slide-arrow-prev {
              pointer-events: none;
              @apply mr-auto;
              &-button {
                pointer-events: fill;
                @apply rounded cursor-pointer;
                backdrop-filter: blur(5px);
              }
            }
            .slide-arrow-next {
              pointer-events: none;
              &-button {
                pointer-events: fill;
                @apply rounded cursor-pointer;
                backdrop-filter: blur(5px);
              }
            }
            .next {
              float: right;
            }
            .slick-list {
              @media screen and (max-width: 450px) {
                overflow: hidden !important;
              }
              overflow: visible !important;
              .slick-track {
                margin-left: 0px;
              }
            }
            &__wrapper {
              width: 400px !important;
            }
          }
          &__bottom {
            @apply text-center;
            margin-top: 42px;
            .btn {
              @apply uppercase;
            }
          }
        }
      }
    }
    &__faq {
      background-color: #f2f2f2;
      &__body {
        @apply grid grid-cols-12;
        @media screen and (max-width: 760px) {
          @apply grid grid-cols-1;
          &__left {
            @apply mb-10;
            &__description {
              @apply text-lg text-secondary mt-5;
              line-height: 180%;
              max-width: unset !important;
            }
          }
        }
        padding-top: 44px;
        padding-bottom: 80px;
        &__left {
          @apply col-span-5;
          padding-top: 36px;
          &__title {
            @apply text-4xl font-bold;
          }
          &__description {
            @apply text-lg text-secondary mt-5;
            line-height: 180%;
            max-width: 477px;
          }
          &__button {
            margin-top: 30px;
          }
        }
        &__right {
          @apply col-span-7;
        }
        .faq {
          @apply pb-16 mx-auto text-primary text-justify font-Satoshi;

          @screen md {
            @apply pb-24;
          }

          &-item {
            @media screen and (max-width: 760px) {
              @apply pl-0;
            }
            @apply p-6 bg-transparent border-b;
            margin-bottom: 0px !important;

            @screen md {
              @apply mb-5 p-9;
            }

            &-active {
              @apply bg-transparent transition-all;

              .faq-content {
                @apply h-auto overflow-auto;
                margin-top: 30px;
                padding-top: 0px;
              }
            }
          }

          .single-fade {
            @apply absolute h-full;
            content: '';
            z-index: -1;
            width: 500px;
            height: 500px;
            filter: blur(100px);
            background: url('@assets/img/fade.png');
          }

          &-icon {
            @apply absolute right-0;
            top: unset;
          }

          &-heading {
            @media screen and (max-width: 760px) {
              @apply items-start;
            }
            @apply pr-8 text-lg relative cursor-pointer text-primary capitalize flex items-center;
            &-number {
              @apply text-secondary text-base font-normal;
              margin-right: 10px;
            }

            @screen md {
              @apply text-2xl;
            }
          }

          &-content {
            @apply h-0 overflow-hidden text-secondary;
            transition: ease 0.5s;
            font-size: 18px;
            line-height: 32px;

            a {
              @apply underline;

              &:hover {
                @apply no-underline;
              }
            }
          }
        }
      }
    }
  }

  .collector-card {
    &__image {
      .image-collage-container-1 {
        img {
          height: 200px !important;
        }
      }
      .image-collage-container-2 {
        img {
          height: 200px !important;
        }
      }
      .image-collage-container {
        img {
          height: 400px;
        }
      }
    }
    &__profile {
      @apply bg-white;
      &__user {
        @apply flex items-center gap-3 p-5;
        &__name {
          @apply text-xl font-bold;
        }
      }
      &__info {
        @apply grid grid-cols-2;
        &__detail {
          @apply p-5;
          &__field {
            @apply text-sm text-secondary;
          }
          &__value {
            @apply text-base font-bold;
          }
        }
      }
    }
  }

  .landing-artist-slider {
    &__slider {
      width: 600px;
      z-index: 2;
    }
    &:hover {
      .landing-artist-slider__left {
        visibility: visible;
        opacity: 1;
      }
      .landing-artist-slider__right {
        visibility: visible;
        opacity: 1;
      }
      .slide-arrow-prev {
        @apply mr-auto;
        &-button {
          visibility: visible;
          opacity: 1;
        }
      }
      .slide-arrow-next {
        &-button {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    @apply relative;
    &__slider {
      overflow: hidden;
    }
    .slider-arrow {
      pointer-events: none;
      display: flex;
      position: absolute;
      top: 30%;
      z-index: 9999;
      height: 100%;
      width: 100%;
    }
    .slick-slide {
      width: 100px !important;
    }

    .slide-arrow-prev {
      @apply mr-auto;
      &-button {
        @apply rounded cursor-pointer;
        pointer-events: fill;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0.3s, opacity 0.3s linear;
        backdrop-filter: blur(5px);
      }
    }
    .slide-arrow-next {
      &-button {
        @apply rounded cursor-pointer;
        pointer-events: fill;
        visibility: hidden;
        margin-right: -20px;
        opacity: 0;
        transition: visibility 0.3s, opacity 0.3s linear;
        backdrop-filter: blur(5px);
      }
    }
    &__left {
      pointer-events: none;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.3s, opacity 0.3s linear;
      position: absolute;
      width: 100px;
      height: 90px;
      top: 0;
      left: 0;
      background: linear-gradient(270deg, #f2f2f2 0%, rgba(242, 242, 242, 0) 92%);
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }
    &__right {
      pointer-events: none;
      visibility: hidden;
      opacity: 0;
      margin-right: -20px;
      transition: visibility 0.3s, opacity 0.3s linear;
      position: absolute;
      width: 100px;
      height: 90px;
      right: 0;
      top: 0;
      background: linear-gradient(270deg, #f2f2f2 0%, rgba(242, 242, 242, 0) 92%);
    }
    .next {
      float: right;
    }
    .slick-list {
      overflow: visible !important;
      .slick-track {
        margin-left: 0px !important;
      }
    }
  }

  .landing-card-slider {
    @media screen and (max-width: 760px) {
      @apply w-full;
    }
    width: 420px;
  }

  .landing-collectible-card {
    width: 100px;
    &__image {
      @apply relative;
      width: 90px;
      height: 90px;
      .media__image {
        @apply rounded;
      }
      &__detail {
        @apply absolute bottom-0 flex items-center w-full justify-center;
        &__wrapper {
          @apply rounded text-white text-xs font-medium mb-1 lowercase flex items-center justify-center;
          width: 78px;
          height: 22px;
          background: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .marketplace {
      &__grid {
        &__left {
          &__body {
            &__title {
              @apply text-4xl;
            }
            &__description {
              @apply text-lg;
            }
          }
        }
        &__right {
          @apply pt-10;
        }
      }
    }
  }

  @media screen and (max-width: 420px) {
    .marketplace {
      &__grid {
        @apply grid grid-cols-1;
        &__left {
          @apply text-center pt-10;
        }
        &__right {
          @apply p-4;
        }
      }
    }
  }
}
