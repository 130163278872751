/* stylelint-disable alpha-value-notation, color-function-notation, string-quotes, declaration-colon-newline-after */
@layer components {
  .print-collectible-gallery {
    @apply flex;

    margin: 1.875rem 0;

    &__inner {
      @apply flex;

      gap: 1.875rem;
    }

    &__image-box {
      height: 13.75rem;
      width: 13.75rem;

      img {
        @apply h-full w-full object-cover;
      }
    }
  }
}
