@layer components {
}

.post {
  @apply shadow-cardV2 bg-gray-850 mt-5 p-4;
  &-featured {
    @apply flex mb-2;
    &-text {
      @apply font-bold ml-2;
      font-size: 13px;
    }
  }

  &-header {
    @apply flex;

    &-detail {
      @apply pl-2.5;

      &-name {
        @apply font-Avenir-NC uppercase text-base font-bold;
      }

      &-time {
        @apply text-gray-400 text-xs mt-0.5;
      }
    }

    &-tools {
      @apply ml-auto text-right relative;

      &-dropdown {
        @apply mt-2 right-0 w-24 absolute text-left cursor-pointer;
        background-color: rgba(57, 57, 57, 1);

        li {
          @apply text-sm leading-8 pl-3;
        }

        li:hover {
          @apply bg-black;
        }
      }
    }
  }

  &-body {
    &-content {
      @apply py-4;

      &-image {
        @apply mt-6;
      }
    }

    &-response {
      @apply py-3 flex;

      &-like {
        &-owner {
          @apply cursor-default flex;
        }

        position: relative;

        &-loading {
          &:before {
            content: '';
            background-image: url('@assets/svg/spinner.svg');
            @apply absolute top-2/4 left-2/4 -ml-1 -mt-3 block w-6 h-6 bg-no-repeat bg-center animate-spin;
          }
        }

        @apply flex cursor-pointer;

        &-count {
          @apply pl-2;
        }
      }

      &-comment {
        @apply ml-auto cursor-pointer underline;
      }
    }
  }

  &-comment {
    @apply my-4;
  }

  &-create-comment {
    @apply flex;

    &-input {
      .ql-container.ql-snow {
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
          var(--tw-shadow) !important;
        --tw-shadow: inset 0px 6px 10px rgba(0, 0, 0, 0.35) !important;
        background-image: linear-gradient(
          180deg,
          rgba(43, 43, 43, 0) 0%,
          rgba(43, 43, 43, 0.526042) 24.48%,
          #2b2b2b 100%
        ) !important;
        border-color: #3d3d3d !important;
      }

      .ql-editor.ql-blank::before {
        color: #a3a3a3;
        font-style: inherit;
        font-size: 14px;
        font-family: 'Avenir';
      }

      @apply pl-3 w-full;
    }
  }

  &-modal {
    &-edit {
      &-input {
        .ql-editor {
          min-height: 4.5em;
        }

        .ql-container.ql-snow {
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
            var(--tw-shadow) !important;
          --tw-shadow: inset 0px 6px 10px rgba(0, 0, 0, 0.35) !important;
          background-image: linear-gradient(
            180deg,
            rgba(43, 43, 43, 0) 0%,
            rgba(43, 43, 43, 0.526042) 24.48%,
            #2b2b2b 100%
          ) !important;
          border-color: #3d3d3d !important;
        }

        .ql-editor.ql-blank::before {
          color: #a3a3a3;
          font-style: inherit;
          font-size: 14px;
          font-family: 'Avenir';
        }
      }

      &-button {
        @apply mt-9 text-right;
      }
    }

    &-delete {
      &-button {
        @apply mt-9 text-right;

        &-cancel {
          @apply mr-4;
        }
      }
    }

    &-report {
      .modal__action__cancel {
        @apply mr-0;
      }
      &-title {
        @apply pb-4 font-semibold font-Avenir-NC text-lg uppercase;
      }
      &-list {
        &__item {
          @apply py-2 flex justify-between items-center cursor-pointer;
          svg {
            @apply duration-150;
          }
          &:hover {
            svg {
              transform: translateX(5px);
            }
          }
        }
      }
    }
  }
}

.comment {
  @apply mt-2.5 flex;

  &-right {
    @apply w-full;

    &-create-comment {
      @apply flex mt-5;

      &-input {
        .ql-container.ql-snow {
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
            var(--tw-shadow) !important;
          --tw-shadow: inset 0px 6px 10px rgba(0, 0, 0, 0.35) !important;
          background-image: linear-gradient(
            180deg,
            rgba(43, 43, 43, 0) 0%,
            rgba(43, 43, 43, 0.526042) 24.48%,
            #2b2b2b 100%
          ) !important;
          border-color: #3d3d3d !important;
        }

        .ql-editor.ql-blank::before {
          color: #a3a3a3;
          font-style: inherit;
          font-size: 14px;
          font-family: 'Avenir';
        }

        @apply pl-3 w-full;
      }
    }

    &-body {
      @apply p-2.5;
      background-color: rgba(57, 57, 57, 1);

      &-header {
        @apply flex;

        &-name {
          @apply font-Avenir-NC uppercase text-base font-bold;
        }

        &-time {
          @apply text-gray-400 text-xs ml-1.5;
          padding-top: 5px;
        }

        &-tools {
          &-icon {
            @apply absolute -right-1;
          }

          @apply ml-auto text-right relative;

          &-dropdown {
            @apply bg-gray-850 mt-5 right-0 w-24 absolute text-left cursor-pointer;

            li {
              @apply text-sm leading-8 pl-3;
            }

            li:hover {
              @apply bg-black;
            }
          }
        }
      }

      &-content {
        @apply mt-1 pr-3;
      }
    }
  }

  &-left {
    @apply mr-3;
  }
}
