@layer components {
  .collectible-slider__skeleton {
    @apply block whitespace-nowrap;
    &__item {
      @apply mr-12 w-full h-96 inline-block !important;
      max-width: 848px;
    }
  }
  .collectible-slider-wrapper {
    @apply w-7/10 pb-20;

    .collectible-slider {
      &__item {
        &:focus {
          @apply outline-none;
        }
      }
      .collectible-slide {
        @apply flex flex-wrap z-10;

        &:hover {
          .media__image,
          .media__audio,
          .media__video {
            @apply transform scale-105;
          }
        }

        &__media {
          @apply relative w-1/2 shadow-default overflow-hidden;
          .media__image,
          .media__audio,
          .media__video {
            transition: transform 1s;
          }
        }

        &__content {
          @apply flex flex-col justify-between p-9 w-1/2 shadow-default;
          min-height: 380px;

          &__top {
            @apply overflow-hidden;
          }

          &__name {
            @apply font-medium mb-1 text-xl;
            @screen sm {
              font-size: 28px;
            }
          }

          .official-badge {
            @apply mb-5 border-t border-b border-white py-2 text-white text-center font-medium text-lg font-Avenir-NC;
            @screen sm {
              @apply text-xl;
            }
          }

          &__description {
            @apply mt-3 mb-4 leading-6 text-gray-400;
            &__link {
              @apply relative uppercase text-gray-400;
              &::before {
                @apply absolute -bottom-1 left-0 right-0 bg-white h-px origin-right transition ease-in duration-200;
                content: '';
                transform: scaleX(0);
              }

              &:hover {
                @apply text-white;
                &::before {
                  @apply origin-left;
                  transform: scaleX(1);
                }
              }
            }
          }

          &__top {
            &__link {
              @apply overflow-hidden whitespace-nowrap overflow-ellipsis font-sans leading-4.5;
            }
          }

          &__bottom {
            @apply flex flex-row justify-between items-center;
          }

          &__tag {
            @apply flex mt-4;
            &__item {
              @apply mr-2 capitalize px-2 py-1 text-gray-400 border border-gray-400 text-sm;
            }
          }

          &__owner {
            .collectible-owner-actions {
              @apply p-0 bg-transparent gap-0 justify-center !important;
              &-item {
                &-text {
                  @apply ml-0 font-Avenir-NC;
                }
              }
            }
          }
        }
      }

      .slick-list {
        @apply overflow-visible;

        .slick-slide {
          @apply relative pr-12 scale-80 transition-transform transform duration-200 ease-in-out origin-left;

          &.slick-active.slick-current {
            @apply scale-100;
          }

          &:not(.slick-active.slick-current) {
            &:before {
              content: '';
              background-color: rgba(26, 26, 26, 0.4);
              @apply absolute w-full h-full left-0 top-0 z-10;
            }
          }
        }
      }

      .slick-dots {
        @apply absolute -bottom-16 left-0;
        width: 1280px;

        li {
          button {
            &:before {
              content: '';
              @apply w-2.5 h-2.5 bg-white opacity-20 transition-colors duration-200;
            }
          }

          &:hover,
          &.slick-active {
            button {
              &:before {
                @apply opacity-100;
              }
            }
          }
        }
      }
    }
  }

  .collectible-slider-wrapper-v2 {
    @apply w-7/10 pb-20;
    .collectible-slider {
      &__item {
        &:focus {
          @apply outline-none;
        }
      }
      .collectible-slide {
        @apply flex flex-wrap z-10;

        &__media {
          @apply relative w-1/2 shadow-default overflow-hidden;
          .media__image,
          .media__audio,
          .media__video {
            transition: transform 1s;
          }
        }

        &__content {
          @apply flex flex-col px-5 py-7 justify-between w-1/2 shadow-default;

          @screen sm {
            min-height: 380px;
            @apply px-9 py-11;
          }

          &__creator {
            @apply flex items-center justify-between;
            .collectible-card-user {
              @apply text-base antialiased !important;
              &-avatar {
                @apply w-8 h-8;
              }
              @screen md {
                @apply text-lg !important;
                &-avatar {
                  @apply w-10 h-10;
                }
              }
            }
          }

          &__top {
            @apply overflow-hidden;
          }

          &__name {
            @apply font-semibold antialiased mb-1 text-2xl normal-case line-clamp-2;
            @screen sm {
              @apply mb-4 text-3xl;
            }
          }

          &__description {
            @apply mt-4 mb-4 leading-6 text-gray-400;
            &__link {
              @apply relative uppercase text-gray-400;
              &::before {
                @apply absolute -bottom-1 left-0 right-0 bg-white h-px origin-right transition ease-in duration-200;
                content: '';
                transform: scaleX(0);
              }

              &:hover {
                @apply text-white;
                &::before {
                  @apply origin-left;
                  transform: scaleX(1);
                }
              }
            }
          }

          &__top {
            &__link {
              @apply overflow-hidden whitespace-nowrap overflow-ellipsis font-sans leading-4.5;
            }
          }

          &__bottom {
            @apply flex flex-row justify-between items-center;
          }

          &__tag {
            @apply flex mt-4;
            &__item {
              @apply mr-2 capitalize px-2 py-1 text-gray-400 border border-gray-400 text-sm;
            }
          }

          &__owner {
            .collectible-owner-actions {
              @apply p-0 bg-transparent gap-0 justify-center !important;
              &-item {
                &-text {
                  @apply ml-0 font-Avenir-NC text-base !important;
                }
                &-icon {
                  @apply w-auto;
                  margin-right: 6px !important;
                }
              }
              @screen md {
                @apply flex justify-between !important;
              }
            }
          }
        }
      }

      .slick-list {
        @apply overflow-visible;

        .slick-slide {
          @apply relative pr-12 scale-80 transition-transform transform duration-200 ease-in-out origin-left;

          &.slick-active.slick-current {
            @apply scale-100;
          }

          &:not(.slick-active.slick-current) {
            &:before {
              content: '';
              background-color: rgba(26, 26, 26, 0.4);
              @apply absolute w-full h-full left-0 top-0 z-10;
            }
          }
        }
      }

      .slick-dots {
        @apply absolute -bottom-16 left-0;
        width: 1280px;

        li {
          button {
            &:before {
              content: '';
              @apply w-2.5 h-2.5 bg-white opacity-20 transition-colors duration-200;
            }
          }

          &:hover,
          &.slick-active {
            button {
              &:before {
                @apply opacity-100;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1280px) and (min-width: 980px) {
    .collectible-slider-wrapper,
    .collectible-slider-wrapper-v2 {
      @apply w-11/12 pb-14;
      .collectible-slider {
        .slick-dots {
          @apply absolute -bottom-16 left-0;
          width: 108%;
        }
      }
    }
  }
  @media screen and (max-width: 980px) {
    .collectible-slider-wrapper,
    .collectible-slider-wrapper-v2 {
      .collectible-slider {
        .slick-dots {
          width: 102%;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .collectible-slider-wrapper,
    .collectible-slider-wrapper-v2 {
      @apply w-full;
      .collectible-slider {
        .collectible-slide {
          @apply block;

          &__media,
          &__content {
            @apply w-full;
          }
        }
      }
    }
  }

  @media screen and (max-width: 380px) {
    .collectible-slider-wrapper,
    .collectible-slider-wrapper-v2 {
      @apply w-full;
      .collectible-slider {
        .collectible-slide {
          @apply block;

          &__media,
          &__content {
            @apply w-full;
            .official-badge {
              @apply text-sm leading-4;
            }
            &__bottom {
              @apply block justify-between items-center text-center;
              &__button {
                @apply w-full mt-2;
              }
            }
          }
        }
      }
    }
  }
}
