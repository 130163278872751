@layer components {
  .transactions-settings {
    @apply text-primary text-center;
    &-tabs {
      .tab {
        &-buttons {
          @apply flex text-center;
        }
        &-button {
          @apply mr-0 w-full !important;
        }
      }
      .order-history-table-description {
        min-width: 250px;
      }
      .wallet-history-description {
        min-width: 250px;
      }
      .table-responsive {
        @apply text-left mb-6;
      }
    }
    &__loading {
      &-wallet {
        @apply mt-1 mb-5 mx-auto w-28 h-12;
      }
    }
    &-balance {
      &-description {
        @apply pt-1 text-lg;
        @media screen and (max-width: 675px) {
          @apply text-left;
        }
      }
      &__box {
        @apply my-5 border-dashed border-gray-750 p-10 border text-center;
        .btn {
          @apply uppercase;
        }
        @screen md {
          @apply my-9;
        }
        &__current {
          @apply text-primary capitalize text-base;
          @media screen and (max-width: 675px) {
            @apply text-sm;
          }
        }
        &__price {
          @media screen and (max-width: 675px) {
            @apply text-sm;
            font-size: 28px;
            line-height: 100%;
          }
          @apply mt-1 mb-5 text-4xl antialiased font-semibold;
        }
      }
    }
  }
}
