@layer components {
  .collectible-unit {
    @apply text-primary w-full;
    padding: 40px 0px;
    padding-bottom: 80px;
    &__image {
      @apply col-span-7;
      @media screen and (max-width: 968px) {
        @apply col-span-12 px-6;
      }
    }
    &__artists {
      @apply flex items-center;
      &__avatar {
        @apply relative;
        &__hover {
          @apply absolute left-0 top-0 w-full h-full flex items-center justify-center cursor-pointer !important;
          color: white;
          border-radius: 9999px;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
        }
      }
      @media screen and (max-width: 968px) {
        @apply mb-5;
        .avatar--xs-40 {
          height: 45px;
          width: 45px;
        }
      }
      &__detail {
        @apply ml-3;
        &__name {
          @apply font-medium text-xs text-secondary capitalize;
        }
        &__description {
          @apply text-lg font-medium;
          @media screen and (max-width: 968px) {
            @apply text-base;
          }
        }
      }
    }
    &__detail {
      @apply col-span-5;
      .congratulation-share-media {
        @apply text-left text-base text-secondary normal-case font-normal;
      }
      .congratulation-share-icon {
        @apply text-sm;
      }
      &__sticky {
        @apply sticky;
        top: 100px;
      }
      @media screen and (max-width: 968px) {
        @apply col-span-12 px-6 pt-8;
      }
      &__vtms {
        @apply pb-5 lg:pb-0;
        margin-top: 30px;
      }
      &__profile {
        @apply grid grid-cols-2 mt-6;
        margin-bottom: 30px;
        @media screen and (max-width: 968px) {
          @apply block;
        }
      }
      &__top {
        @apply flex;
        &__field {
          @apply flex items-center;
          &__text {
            @apply text-base font-medium;
            @media screen and (max-width: 968px) {
              @apply text-sm;
            }
          }

          &__icon {
            @apply mr-2;
          }
        }
      }
      &__title {
        @apply font-bold mt-5;
        font-size: 40px;
        line-height: 44px;
        @media screen and (max-width: 968px) {
          font-size: 26px;
          line-height: 150%;
        }
      }
      &__serialIndex {
        @apply text-xl font-bold mt-4;
        @media screen and (max-width: 968px) {
          font-size: 16px;
          line-height: 180%;
        }
      }
      &__creator {
        @apply text-lg mt-5;
        &__name {
          @apply underline;
        }
      }
      &__moma {
        @apply flex text-lg items-center gap-2 mt-3;
      }
      &__description {
        @apply text-lg text-secondary mt-7;
      }
      &__mint {
        @apply p-6 mb-5;
        background-color: #f5f5f5;
        &__title {
          @apply font-bold;
          font-size: 28px;
          line-height: 130%;
        }
        &__description {
          @apply text-lg text-secondary mt-4 font-normal;
        }
        &__button {
          &__wrapper {
            @apply flex items-center w-full justify-center;
          }
        }
      }
      &__minted {
        @apply p-4;
        background: #f5f5f5;
        border: 1px solid #1868b7;
        border-radius: 4px;
        &__owner {
          @apply flex items-center;
          &__left {
            &__wallet {
              @apply flex items-center font-bold text-lg;
            }
            @apply mr-auto;
            &__text {
              @apply text-secondary text-sm;
            }
          }
          &__right {
            .btn--blue {
              padding-right: 1.5rem;
              padding-left: 1.5rem;
            }
          }
        }
        &__buyer {
          &__title {
            @apply flex items-center text-xl font-bold;
            &__text {
              margin-left: 10px;
            }
          }
          &__description {
            margin-top: 10px;
            @apply text-base;
            line-height: 170%;
            letter-spacing: -0.02em;
          }
        }
      }
      &__price {
        @apply rounded w-full p-6 text-lg font-bold;
        background-color: #f5f5f5;
        &__edition {
          @apply text-lg font-bold;
        }
        .btn--default {
          @apply uppercase;
        }
        &__detail {
          @apply grid grid-cols-2;
          &__field {
            &__name {
              @apply font-normal text-secondary uppercase;
              font-size: 10px;
              line-height: 14px;
              @media screen and (max-width: 968px) {
                @apply text-xs capitalize;
              }
            }
          }
        }
      }
    }
    &__tab {
      .tab-full > div {
        overflow-x: auto;
        .tab-buttons {
          min-width: 480px;
        }
        &::-webkit-scrollbar {
          @apply hidden;
        }
      }
      .tab-button {
        @apply text-sm;
      }
      display: block;
      overflow: hidden;
      @media screen and (max-width: 968px) {
        @apply col-span-12 px-6 pt-4;
      }
      &__sticky {
        @media screen and (max-width: 968px) {
          @apply hidden;
        }
      }
      &__block {
        @apply hidden;
        @media screen and (max-width: 968px) {
          @apply block;
        }
      }
      &__detail {
        @apply pt-8;
        max-height: 500px;
        overflow-y: scroll;
        &__description {
          &__title {
            @apply text-lg font-bold;
          }
          &__value {
            @apply text-lg text-secondary font-normal mt-5;
          }
        }
        &__table {
          @apply mt-9;
          td {
            @apply pl-0 !important;
          }
          &__left {
            @apply text-lg font-bold;
          }
          &__right {
            @apply text-lg;
          }
        }
      }
      &__transaction {
        @apply pt-8;
        &__wrapper {
          max-height: 500px;
          overflow-x: scroll;
          .unit-transaction {
            @apply rounded p-8 mb-5;
            background-color: #f5f5f5;
            &__body {
              @apply grid grid-cols-12 items-center w-full;
              @media screen and (max-width: 800px) {
                @apply gap-4;
              }
              &__to {
                .collectible-unit__artists__detail__name {
                  @media screen and (max-width: 800px) {
                    @apply uppercase;
                    font-size: 8px;
                  }
                }
                .collectible-unit__artists__detail__description {
                  @media screen and (max-width: 800px) {
                    @apply text-sm;
                  }
                }
                @apply col-span-4;
                @media screen and (max-width: 800px) {
                  @apply col-span-6;
                }
                .collectible-unit__artists {
                  margin-bottom: 0px;
                }
              }
              &__from {
                @apply col-span-4;
                .collectible-unit__artists__detail__name {
                  @media screen and (max-width: 800px) {
                    @apply uppercase;
                    font-size: 8px;
                  }
                }
                .collectible-unit__artists__detail__description {
                  @media screen and (max-width: 800px) {
                    @apply text-sm;
                  }
                }
                @media screen and (max-width: 800px) {
                  @apply col-span-6;
                }
                .collectible-unit__artists {
                  margin-bottom: 0px;
                }
              }
              &__field {
                @apply col-span-2;
                @media screen and (max-width: 800px) {
                  @apply col-span-6;
                }
                &__name {
                  @apply font-medium text-secondary uppercase;
                  font-size: 10px;
                  @media screen and (max-width: 800px) {
                    font-size: 8px;
                  }
                }
                &__value {
                  @apply text-lg font-medium;
                  @media screen and (max-width: 800px) {
                    @apply text-sm;
                  }
                }
              }
              &__amount {
                @apply col-span-2 flex w-full justify-end;
                @media screen and (max-width: 800px) {
                  @apply col-span-6 justify-start;
                }
              }
            }
          }
        }
      }
      &__story {
        @apply pt-8;
        .collectible__tab__artist {
          margin-top: 0px;
        }
        &__wrapper {
          @apply px-16 py-10 rounded;
          background-color: #f5f5f5;
          &__icon {
            @apply flex w-full justify-center;
          }
          &__description {
            @apply text-lg text-center mt-7 text-secondary;
          }
          &__button {
            @apply text-center mt-7;
          }
        }
      }
    }
    ::-webkit-scrollbar {
      display: none;
    }
    &__more {
      @media screen and (max-width: 968px) {
        @apply px-6;
      }
      &__top {
        @apply flex;
        &__text {
          @apply text-4xl font-bold mr-auto;
        }
      }
      &__collectible {
        @apply grid grid-cols-3 gap-4 py-8;
        @media screen and (max-width: 968px) {
          @apply grid grid-cols-1 gap-4 py-8;
        }
      }
    }
  }
}
