@import url('https://use.typekit.net/zwy0spz.css?ver=3');
@import 'tailwindcss/base';

@layer base {
  @font-face {
    font-family: 'Graphik';
    font-weight: 400;
    src: url('@assets/fonts/GraphikRegular.woff') format('woff');
  }

  @font-face {
    font-family: 'Graphik';
    font-weight: 400;
    font-style: italic;
    src: url('@assets/fonts/GraphikRegularItalic.woff') format('woff');
  }

  @font-face {
    font-family: 'Graphik';
    font-weight: 600;
    src: url('@assets/fonts/GraphikSemibold.woff') format('woff');
  }

  @font-face {
    font-family: 'Graphik';
    font-weight: 600;
    font-style: italic;
    src: url('@assets/fonts/GraphikSemiboldItalic.woff') format('woff');
  }

  @font-face {
    font-family: 'Right Grotesk TM';
    font-weight: 500;
    src: url('@assets/fonts/RightGrotesk-TightMedium.woff') format('woff');
  }

  @font-face {
    font-family: 'Right Grotesk TB';
    font-weight: 900;
    src: url('@assets/fonts/RightGrotesk-CompactBlack.woff') format('woff');
  }

  @font-face {
    font-family: 'Avenir';
    font-weight: 400;
    src: url('@assets/fonts/AvenirRegular.otf') format('otf');
  }

  @font-face {
    font-family: 'Avenir Book';
    font-weight: 400;
    src: url('@assets/fonts/AvenirBook.woff2') format('woff2'),
      url('@assets/fonts/AvenirBook.woff') format('woff');
  }

  @font-face {
    font-family: 'Avenir Roman';
    font-weight: 400;
    src: url('@assets/fonts/AvenirRoman.otf') format('otf');
  }

  @font-face {
    font-family: 'Avenir Next Condensed';
    font-weight: 400;
    src: url('@assets/fonts/AvenirNextCondensed.woff2') format('woff2'),
      url('@assets/fonts/AvenirNextCondensed.woff') format('woff');
  }

  @font-face {
    font-family: 'Avenir Next Condensed';
    font-weight: 600;
    src: url('@assets/fonts/AvenirNextCondensedDemiBold.woff2') format('woff2'),
      url('@assets/fonts/AvenirNextCondensedDemiBold.woff') format('woff');
  }

  @font-face {
    font-family: 'Avenir Medium';
    font-weight: 500;
    src: url('@assets/fonts/AvenirMedium.otf') format('otf');
  }

  html {
    scroll-behavior: smooth;
  }

  * {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  h1,
  .use-h1 {
    @apply text-4xl font-semibold leading-none text-white font-Avenir-NC;

    @screen md {
      line-height: 50px;
      @apply text-h1;
    }
  }

  h2,
  .use-h2 {
    @apply font-black leading-none text-white uppercase font-Avenir-NC text-h2;
  }

  h3,
  .use-h3 {
    @apply font-semibold leading-8 text-white uppercase font-Avenir-NC text-h3;
    line-height: 33px;
  }

  h4,
  .use-h4 {
    @apply font-semibold leading-none text-white uppercase font-Avenir-NC text-h4;
  }

  h5,
  .use-h5 {
    @apply font-semibold leading-none text-white text-h5;
  }

  h6,
  .use-h6 {
    @apply text-2xl font-medium leading-8 text-white uppercase font-Avenir-NC;
  }

  .root {
    @apply relative;
  }

  .portal-root {
    @apply z-30 w-full;
    min-height: 100vh;
  }

  .text-gradient {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
}

@import 'tailwindcss/components';

@import '@components/claim-collectible-card/style.css';
@import '@components/collectible-card-user/style.css';
@import '@components/collectible-tier/style.css';
@import '@components/collectible-card-timer/style.css';
@import '@components/collectible-modal/style.css';
@import '@components/collectible-slider/style.css';
@import '@components/collectible-tooltip/style.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '@components/collectible-timer/style.css';
@import '@components/countdown/style.css';
@import '@components/countdays/style.css';
@import '@components/creator-card/style.css';
@import '@components/cropper/style.css';
@import '@components/edition-badge/style.css';
@import '@components/footer/style.css';
@import '@components/image-collage/style.css';
@import '@components/official-badge/style.css';
@import '@components/suspense-loading/style.css';
@import '@components/typography/style.css';
@import '@components/user-card/style.css';

@import '@components/card-user/style.css';
@import '@components/edition-card/style.css';
@import '@components/link/style.css';
@import '@components/layout/style.css';
@import '@components/collectible-grid/style.css';
@import '@components/sale-history-table/style.css';
@import '@components/creator-card-grid/style.css';
@import '@components/team-card/style.css';
@import '@components/team-grid/style.css';

@import '@components/currency/style.css';
@import '@components/related-profile-card/style.css';
@import '@components/related-profile-grid/style.css';
@import '@components/serial-item/style.css';
@import '@components/collectible-skeleton/style.css';
@import '@components/confirmation-modal/style.css';
@import '@components/post/style.css';
@import '@components/profile-video/style.css';
@import '@components/purchase-modal/style.css';
@import '@components/withdrawal-history/style.css';

/* Pages */
@import '@pages/notifications/fansinc/style.css';
@import '@pages/notifications/patrons/style.css';
@import '@pages/related-profile/style.css';
@import '@pages/coming-soon/fansinc/style.css';
@import '@pages/coming-soon/patrons/style.css';
@import '@pages/releases/style.css';
@import '@pages/beta-features/style.css';

@import 'tailwindcss/utilities';
@import '~react-datepicker/dist/react-datepicker.min.css';
@import '~react-toastify/dist/ReactToastify.css';
@import '~@vincere/react-vlag/dist/style.css';
@import 'react-input-range/lib/css/index.css';
@import 'react-quill/dist/quill.snow.css';
@import 'react-image-lightbox/style.css';
