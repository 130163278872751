@layer components {
  .single-page {
    @apply min-h-screen pb-16;

    @screen md {
      @apply pb-24;
    }
    &__header {
      font-weight: 700;
      font-size: 26px;
      line-height: 120%;
      letter-spacing: -0.02em;
      color: #2b2b2b;
      margin-top: 40px;
      margin-bottom: 50px;
      @screen lg {
        font-weight: 900;
        font-size: 70px;
      }
      &__about {
        font-weight: 700;
        font-size: 26px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #2b2b2b;
        margin-top: 40px;
        margin-bottom: 14px;
        @screen lg {
          font-weight: 900;
          font-size: 70px;
        }
      }
      &__faq {
        font-weight: 900;
        font-size: 26px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #2b2b2b;
        margin-top: 40px;
        margin-bottom: 14px;
        @screen lg {
          font-weight: 700;
          font-size: 70px;
        }
      }
      &__hiw {
      font-weight: 900;
      font-size: 26px;
      line-height: 120%;
      letter-spacing: -0.02em;
      color: #2b2b2b;
      margin-top: 40px;
      margin-bottom: 16px;
      @screen lg {
        font-weight: 700;
        font-size: 70px;
        margin-bottom: 30px;
      }
    }
    }

    &__hero {
      @apply capitalize font-Satoshi md:text-5xl text-primary;
      font-weight: 900;
      font-size: 70px;
      line-height: 120%;
    }

    &__content {
      @apply text-secondary;

      ul {
        @apply my-8;
      }

      ul li::before {
        content: url('../../assets/svg/ul-line.svg');
        font-size: 18px;
        padding-right: 10px;
      }

      li {
        @apply text-lg leading-8 text-secondary py-2;
      }

      a {
        @apply underline;

        &:hover {
          @apply no-underline;
        }
      }

      h1 {
        @apply text-5xl text-primary capitalize;

        @screen md {
          font-size: 66px;
        }
      }

      h2 {
        @apply capitalize text-primary mb-12 text-3xl;

        @screen md {
          font-size: 46px;
          line-height: 58px;

          &:not(&:first-child) {
            @apply mt-12;
          }
        }
      }

      h3 {
        @apply text-2xl font-bold capitalize text-primary font-Satoshi mb-12;

        &:not(&:first-child) {
          @apply mt-12;
        }

        @screen md {
          @apply text-4xl;
        }
      }

      h4 {
        @apply text-xl font-bold capitalize text-primary font-Satoshi mb-12;

        &:not(&:first-child) {
          @apply mt-12;
        }

        @screen md {
          @apply text-3xl;
        }
      }

      p {
        @apply text-lg font-normal leading-8 text-secondary mb-8 text-justify font-Satoshi;
      }

      blockquote {
        @apply text-secondary;
        border-left: 5px solid #ff4307;
        font-size: 20px;
        font-weight: 500;
        line-height: 36px;
        padding-left: 30px;
        margin-bottom: 30px;
        font-style: italic;
        quotes: '\201C''\201D''\2018''\2019';
      }

      blockquote p {
        display: inline;
      }
    }
  }

  .faq {
    @apply pb-16 text-primary text-justify;

    @screen md {
      @apply pb-24;
    }
    &-heading {
      @apply font-bold text-left text-base lg:text-xl !important;
    }

    &-item {
      @apply mb-3 p-4 px-0 bg-transparent border-b;
      margin-bottom: 0px !important;

      @screen md {
        @apply mb-5 p-9 px-0;
      }

      &-active {
        @apply bg-transparent transition-all;

        .faq-content {
          @apply pt-4 h-auto overflow-auto;
          margin-top: 30px;
          padding-top: 0px;
        }
      }
    }

    .single-fade {
      @apply absolute h-full;
      content: '';
      z-index: -1;
      width: 500px;
      height: 500px;
      filter: blur(100px);
      background: url('@assets/img/fade.png');
    }

    &-icon {
      @apply absolute top-0 right-0;
    }

    &-heading {
      @apply pr-8 text-lg relative cursor-pointer text-primary;

      @screen md {
        @apply text-2xl;
      }
    }

    &-content {
      @apply h-0 overflow-hidden text-secondary;
      transition: ease 0.5s;
      font-size: 18px;
      line-height: 32px;

      a {
        @apply underline;

        &:hover {
          @apply no-underline;
        }
      }
    }
  }

  .contact-height {
    @apply py-10;
    min-height: 90vh;

    @media screen and (max-width: 320px) {
      min-height: 100vh;
    }
  }

  .contact-image {
    background-image: url('@assets/img/contact.png');
    background-color: #cccccc;
    object-fit: cover;
    height: 100%;
  }
  .contact-modal {
    @apply text-center;
    &__icon {
      @apply w-full flex justify-center;
      &__wrapper {
        @apply w-64 h-64;
      }
    }
    &__title {
      @apply font-bold;
      font-size: 26px;
      line-height: 150%;
    }
    &__information {
      @apply text-lg text-secondary;
      margin-top: 26px;
      margin-bottom: 76px;
      letter-spacing: -0.02em;
      max-width: 445px;
    }
  }

  .about {
    &__jumbotron {
      border-bottom: 1px solid #5C5C5C;
      &__container {
        min-height: 300px;
      }

      &__number {
        font-size: 200px;
        background-clip: text;
        background-image: linear-gradient(180deg, #3c3c3c 0%, #2a2a2a 100%);
        z-index: -1;
      }
    }
  }
  .about-page {
    &-image {
      @apply w-full;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    &-section {
      &-second {
        padding-top: 140px;
        padding-bottom: 50px;
      }
      &-third {
        padding-bottom: 100px;
        &-text {
          max-width: unset;
          @screen lg {
            max-width: 500px;
          }
        }
      }
    }
    &-slider {
      @media screen and (max-width: 1280px) {
        padding-left: 40px !important;
      }
    }
    &-jumboton {
      max-width: unset;
      @screen md {
        max-width: 306px;
        line-height: 170%;
      }
    }
    &-title {
      font-weight: 900;
      font-size: 70px;
      line-height: 120%;
      letter-spacing: -0.02em;
    }
    &-number {
      font-size: 40px;
      line-height: 120%;
      letter-spacing: -0.02em;
    }
  }
}
