@layer components {
  .landing {
    @apply h-full flex items-center text-primary;
    &__content {
      @apply m-auto;
      max-width: 520px;
      &__description {
        @apply font-bold mt-4;
        font-size: 42px;
        line-height: 150%;
      }
      &__extra {
        @apply text-lg;
        line-height: 170%;
        margin-top: 26px;
      }
      &__success {
        @apply font-bold mt-10;
        letter-spacing: -0.02em;
        font-size: 36px;
        line-height: 150%;
      }
      &__signup {
        margin-top: 30px;
        font-size: 18px;
        line-height: 170%;
        letter-spacing: -0.02em;
        &__form {
          margin-top: 30px;
          input:focus {
            box-shadow: none;
          }
          &__password-requirement {
            @apply mt-2 ml-1 text-gray-400 italic text-xs text-left;
          }
          &__flex {
            @apply grid grid-cols-2 gap-6;
          }
          &__interested {
            @apply flex items-center justify-between;
            gap: 10px;
            .interested__active {
              @apply text-primary !important;
              border: 1px solid #2B2B2B !important;
            }
            &__option {
              @apply capitalize w-full flex items-center justify-center cursor-pointer;
              padding: 9.5px 0px;
              border: 1px solid #E0E0E0;
              border-radius: 4px;
              color: #979797;
              font-size: 18px;
              line-height: 170%;
              letter-spacing: -0.02em;
            }
          }
        }
      }
      &__form {
        @apply relative rounded;
        margin-top: 30px;
        background-color: #f5f5f5 !important;
        border: 1px solid #e0e0e0 !important;
        height: 70px !important;
        .field-help--error {
          margin-top: 10px !important; 
        }
        input {
          @apply ring-0;
          margin-top: 10px;
          width: 300px !important;
          border: none !important;
          &:focus {
            box-shadow: none !important;
            border: none !important;
          }
        }
        &__button {
          height: 55px !important;
          margin-top: 7.5px;
          margin-bottom: 7.5px;
          margin-right: 7px;
          @apply absolute;
          right: 0;
          top: 0;
        }
      }
    }
  }
  .landing-section {
    @apply grid grid-cols-2 gap-10;
    height: 100vh;
    @media screen and (max-width: 1080px) {
      @apply grid grid-cols-1 gap-0;
      height: unset;
    }
    &__left {
      @apply px-5;
      @media screen and (max-width: 1080px) {
        @apply grid grid-cols-1 py-10;
        min-height: 475px;
      }
    }
    &__right {
      @apply w-full h-full;
      background: url('@assets/img/coming-soon-image.png') no-repeat;
      background-size: cover;
      @media screen and (max-width: 1080px) {
        aspect-ratio: 3 / 4;
      }
    }
  }
}
