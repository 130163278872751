/* stylelint-disable alpha-value-notation, color-function-notation, string-quotes, declaration-colon-newline-after */
@layer components {
  .landing-gallery {
    &__cta {
      @apply py-12 text-center;
      @screen md {
        @apply pb-24;
      }
      position: relative;

      &__content {
        @apply mx-auto;
        button {
          @apply mt-8;
        }
        @screen sm {
          button {
            @apply mt-9;
          }
        }
      }

      &__fade-left {
        &::before {
          @apply absolute h-full top-0;
          content: '';
          z-index: -1;
          width: 400px;
          left: 0;
          background: linear-gradient(
            90deg,
            rgba(212, 32, 39, 0.3) 0%,
            rgba(212, 32, 39, 0.0828) 72.4%,
            rgba(212, 32, 39, 0) 100%
          );
        }

        &::after {
          @apply absolute h-full top-0;

          content: '';
          z-index: -1;
          width: 100%;
          height: 100%;
          background: rgb(26, 26, 26);
          background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 40%,
            rgba(26, 26, 26, 1) 100%
          );
        }
      }

      &__fade-right {
        &::before {
          @apply absolute h-full top-0 hidden;

          content: '';
          z-index: -1;
          width: 400px;
          right: 0;
          background: linear-gradient(
            270deg,
            rgba(212, 32, 39, 0.3) 0%,
            rgba(212, 32, 39, 0.0828) 72.4%,
            rgba(212, 32, 39, 0) 100%
          );
          @screen md {
            @apply block;
          }
        }

        &::after {
          @apply absolute h-full top-0;

          content: '';
          z-index: -1;
          width: 100%;
          height: 100%;
          background: rgb(26, 26, 26);
          background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 40%,
            rgba(26, 26, 26, 1) 100%
          );
        }
      }
    }
  }
}
