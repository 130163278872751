@layer components {
  .related-profile-card {
    &__media {
      @apply aspect-w-1 aspect-h-1 relative;
      margin-bottom: 0.625rem;
      img {
        @apply relative mx-auto object-contain w-auto h-auto max-w-full max-h-full;
      }
      .avatar {
        &::after {
          @apply absolute top-0 left-0 h-full w-full block;
          z-index: 9;
          content: '';
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
        }
      }
      &__information {
        &-wrapper {
          @apply absolute p-5 bottom-0 left-0 z-20 w-full;
        }

        &__title {
          @apply relative max-w-full inline-block pr-5 font-Avenir-NC font-semibold text-xl truncate;
          @screen sm {
            @apply text-2xl;
          }
          &:before {
            content: '';
            @apply absolute top-2.5 right-0 w-3 h-3;
            background: url('@assets/svg/verified_creator.svg');
          }
        }
        &__link {
          @apply -mt-1 font-sans truncate;
        }
      }
      & > * {
        @apply absolute !important w-full !important h-full !important;
      }
    }
    &__button {
      @apply grid-cols-1 gap-y-1 !important;
    }
  }

  @media screen and (min-width: 900px) {
    .related-profile-card {
      &__button {
        @apply grid-cols-2 gap-x-2 !important;
      }
    }
  }

  @media screen and (max-width: 700px) {
    .related-profile-card {
      &__button {
        @apply grid-cols-2 gap-x-2 !important;
      }
    }
  }
  @media screen and (max-width: 425px) {
    .related-profile-card {
      .avatar {
        @apply w-full h-full !important;
      }
    }
  }
}
