.connect-wallet-button {
  button {
    svg {
      @apply inline text-2xl mr-1 -mt-1;
    }
  }

  &-mint {
    @apply border border-green-solid;

    min-width: 227px;

    .nft-address {
      &-key {
        @apply text-sm font-semibold;
      }
      &-status {
        @apply block text-green-solid normal-case;
      }
      &-icon {
        @apply mr-3 bg-gray-400 pr-0 bg-opacity-5 h-10 w-10 flex items-center justify-center;
      }
    }

    &.nft-address {
      padding: 10px;
    }

    &__user-menu {
      @apply border-none;

      &.nft-address {
        padding: 0;
      }
    }
  }

  &__sync-error {
    @apply my-2;
  }
}
