@layer components {
  .order-history-table {
    &-order {
      @apply underline cursor-pointer;
      &:hover {
        @apply no-underline;
      }
    }
    th {
      @apply font-Satoshi text-lg !important;
    }
    td {
      @apply font-Satoshi text-lg max-w-sm !important;
    }
    th,
    td {
      @apply align-top;
      &:first-child {
        @apply pl-0 !important;
      }
      &:last-child {
        @apply pr-0 !important;
      }
    }
    &-description {
      @apply normal-case whitespace-normal !important;
      max-width: 15rem;
      &-payment {
        @apply text-gray-400 normal-case;
      }
    }
    &-status {
      @apply text-lg font-normal normal-case !important;
    }
  }
  .orderPrint-modal {
    &-button {
      @apply text-right mt-8;
      &-close {
        @apply mr-3;
      }
    }
  }
}
