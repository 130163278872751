@layer components {
  .congratulation {
    min-height: 90vh;
  }
  .congratulation-text-icon {
    @apply inline-flex justify-center items-center;
    &__icon {
      @apply text-primary w-5 h-5 mr-2;
    }
    &__text {
      @apply text-xl capitalize;
    }
    &__description {
      @apply text-secondary mt-4 font-normal leading-5 text-base;
    }
  }
  .congratulation-share-media {
    @apply capitalize text-primary font-bold text-center text-xl;
    @screen md {
      font-size: 26px;
      line-height: 150%;
    }
  }
  .congratulation-share-icon {
    .share-icon-button {
      border-color: #2b2b2b;
    }
  }
  .collectible-accordion {
    @apply text-primary text-justify;
    margin-top: 30px;

    &-label {
      @apply text-lg font-bold;
      line-height: 170%;
    }
    &-heading {
      @apply font-bold text-xl flex items-center !important;
    }

    &-item {
      @apply mb-3 bg-transparent border-b mb-5 p-5 px-0;
      margin-bottom: 0px !important;

      &-active {
        @apply bg-transparent transition-all;

        .collectible-accordion-content {
          @apply mt-4 h-auto overflow-auto;
          &-title {
            @apply text-base font-normal text-secondary;
            letter-spacing: -0.02em;
          }
          &-link {
            @apply text-base font-medium text-primary cursor-pointer underline;
            letter-spacing: -0.02em;
          }
          &-description {
            @apply text-base text-secondary;
            font-weight: 400;
            line-height: 170%;
            letter-spacing: -0.02em;
          }
        }
      }
    }

    .single-fade {
      @apply absolute h-full;
      content: '';
      z-index: -1;
      width: 500px;
      height: 500px;
      filter: blur(100px);
      background: url('@assets/img/fade.png');
    }

    &-heading {
      @apply text-lg relative cursor-pointer text-primary;

      @screen md {
        @apply text-2xl;
      }
    }

    &-content {
      @apply h-0 overflow-hidden text-secondary;
      transition: ease 0.5s;
      font-size: 18px;
      line-height: 32px;

      a {
        @apply underline;

        &:hover {
          @apply no-underline;
        }
      }
    }
  }
}
