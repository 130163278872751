@layer components {
  .accept-offer-modal {
    &__summary {
      @apply pt-4;
      &-title {
        @apply uppercase font-semibold;
      }
      &__wrapper {
        @apply py-4;
      }
      &__user {
        @apply normal-case;
      }
      &__title {
        @apply font-semibold;
      }
      &__content {
        @apply uppercase;
        &__price {
          &__currency {
            @apply pl-1 text-gray-400;
          }
        }
      }
    }

    &__buttons {
      @apply mt-12 flex flex-row-reverse;
    }

    &__helper-text {
      @apply mt-3;
    }
  }
}
