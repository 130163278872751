@layer components {
  .collectible-tooltip {
    &-content {
      @apply rounded-sm bg-gray-800 relative opacity-0;
      padding: 6px 8px;
      transition: 0.3s;
      &-show {
        @apply opacity-100;
      }
    }
    &-arrow {
      @apply absolute border-gray-800;
      border-bottom-width: 6px;
      border-left: 6px solid transparent !important;
      border-right: 6px solid transparent !important;
      content: '';
    }
    &-left {
      .collectible-tooltip-arrow {
        @apply -left-2 top-2/4;
        transform: translateY(-50%) rotate(-90deg);
      }
    }
    &-right {
      .collectible-tooltip-arrow {
        @apply -right-2 top-2/4;
        transform: translateY(-50%) rotate(90deg);
      }
    }
    &-bottom {
      .collectible-tooltip-arrow {
        @apply right-2/4 -top-2;
        transform: translateY(45%) rotate(0deg);
      }
    }
  }
}
