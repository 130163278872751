@layer components {
  .collectible-card-new {
    @apply mb-5 cursor-pointer z-auto relative;

    .media {
      @apply h-auto;
    }

    &__body {
      @apply p-6 w-full bg-white;

      &__title {
        @apply flex justify-between items-center;
      }

      &__creator {
        @apply text-base text-secondary font-medium text-left;
        line-height: 170%;

        &__name {
          @apply underline text-secondary font-medium !important;
        }
      }

      &__separator {
        margin: 10px 0 !important;

        &-bottom {
          margin-top: 14px !important;
        }

        &-top {
          margin-bottom: 14px !important;
        }
      }

      &__price {
        @apply flex justify-between;

        .price,
        .countdown {
          @apply font-Avenir-NC text-base font-semibold;
        }
      }

      &__auction {
        .countdown {
          span {
            @apply mx-0;
          }

          &-separator {
            &::before {
              content: ':';
            }
          }
        }
      }
      &__link {
        .btn {
          @apply text-xs lg:text-sm;
        }
      }
      &__button {
        margin-top: 25px;
        @apply uppercase;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        .btn {
          @apply text-xs lg:text-sm;
        }
      }
    }

    &__collected {
      @apply absolute left-5 top-5 flex;
      z-index: 3;
      &__wrapper {
        @apply border border-gray-700 text-sm text-white leading-4 uppercase bg-primary bg-opacity-80 font-semibold antialiased;
        margin-right: 5px;
        padding: 8px 10px;
      }
      &__tooltip {
        @apply normal-case text-sm whitespace-normal text-left !important;
        max-width: 225px !important;
      }
    }

    &__content {
      @apply overflow-hidden bg-white transition-all ease-in-out duration-500 relative;
      height: 110px;
      z-index: 90;
    }

    &-has-button {
      .collectible-card-new__content {
        &:hover {
          height: 185px;
          margin-top: -75px;

          .collectible-card-new__body {
            @apply h-auto;
          }
        }
      }
    }

    &__title {
      @apply mb-1 font-bold text-primary text-xl line-clamp-1 font-Satoshi text-left;
      line-height: 150%;
    }

    &__separator {
      margin: 10px 0 !important;

      &-bottom {
        margin-top: 14px !important;
      }

      &-top {
        margin-bottom: 14px !important;
      }
    }

    &__tier {
      @apply absolute left-5 top-5 flex;
      z-index: 3;
    }

    &__price {
      @apply flex justify-between;

      .price,
      .countdown {
        @apply font-Avenir-NC text-base font-semibold;
      }
    }

    &__auction {
      .countdown {
        span {
          @apply mx-0;
        }

        &-separator {
          &::before {
            content: ':';
          }
        }
      }
    }
  }

  .collectible-card-vip {
    @apply relative;

    &__content {
      @apply pt-5 px-5 relative;
    }

    .media {
      @apply h-auto;
    }

    &__bg {
      @apply absolute top-0 left-0 w-full h-auto;
    }

    &__title {
      @apply uppercase font-Avenir-NC font-semibold text-center antialiased;
      margin-top: 11%;
      font-size: 28px;
      line-height: 40px;
      background: linear-gradient(199.27deg, #d8b455 25.91%, #ffe9af 55.27%, #d8b455 84.63%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &__badge {
      @apply uppercase;
    }

    svg {
      filter: drop-shadow(5px 5px 5px rgb(0 0 0 / 0.4));
    }
  }
}
