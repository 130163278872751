/* stylelint-disable string-quotes */
@layer components {
  .mint-form {
    &__heading {
      @apply mb-6;
    }

    &__subheading {
      @apply my-6;

      span {
        font-size: 0.875rem;
      }
    }

    &__item {
      @apply mb-4;

      &__group {
        @apply inline-flex flex-col;
      }

      span {
        @apply uppercase;
      }

      input {
        @apply my-2 w-full;

        &[type='number'] {
          @apply w-16;
        }
      }

      &__buttons {
        @apply mt-2;

        button {
          @apply mb-2;

          &:not(:last-child) {
            @apply mr-3;
          }
        }
      }
    }

    &__submit {
      @apply text-left;
    }

    &-status {
      @apply mt-6 p-6 bg-gray-400 bg-opacity-5 text-center;
      @screen md {
        @apply p-8;
      }
      &__button {
        @apply mt-3 mx-auto flex items-center;
        svg {
          @apply mr-2;
        }
      }
      &__title {
        @apply mb-3 text-lg;
      }
      &__description {
        @apply text-gray-400;
      }
      &__icon {
        @apply mb-3 mx-auto w-9 h-9;
      }
      &-error {
        @apply border border-red-300;
      }
      &-success {
        @apply border border-green-solid;
      }
    }
  }
}
