/* stylelint-disable string-quotes */
@layer components {
  .print-price {
    @apply flex items-end mb-3;
    div {
      @apply mr-2;
    }
    &__gst {
      @apply text-base font-normal normal-case text-gray-400;
    }
  }
}
