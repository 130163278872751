@layer components {
  .beta-features {
    h1 {
      margin: 40px auto !important;
    }
  }
}

.vlag-features__item__content__title {
  @apply text-white !important;
}
.vlag-features__item {
  @apply border-gray-700 !important;
}
.vlag-switch__item .vlag-switch__item__box {
  @apply bg-gray-750 !important;
}
.vlag-switch__item-active .vlag-switch__item__box {
  @apply bg-red-700 !important;
}
