@layer components {
  .mini-tab {
    .tab-button {
      @apply px-3 !important;
    }
  }

  .mini-tab-checkout {
    .tab-button {
      @apply px-0 py-1 mr-4 !important;
    }
  }

  .tab {
    &-full {
      .tab-buttons {
        @apply flex;
        .tab-button {
          @apply px-0 w-full !important;
        }
      }
    }
    &-buttons {
      @apply block overflow-x-auto whitespace-nowrap relative antialiased;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        @apply hidden;
      }
      .tab-button {
        @apply bg-transparent outline-none py-4 px-10 cursor-pointer text-secondary transition-all duration-100 uppercase text-base font-medium z-10 relative;
        @screen sm {
          @apply text-lg;
        }
        &.active {
          @apply transition-all duration-100 text-primary;
          &::after {
            @apply block w-full absolute bottom-0 left-0;
            content: '';
            background-color: #2B2B2B;
            height: 1px;
          }
        }
        &::before {
          @apply block w-full absolute bottom-0 left-0;
          z-index: -1;
          content: '';
          background-color: #F0F0F0;
          height: 1px;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .tab-buttons {
      @apply px-0 !important;
      .tab-button {
        @apply px-4;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .mini-tab-checkout {
      .tab-button {
        @apply text-lg;
      }
    }
  }
}
