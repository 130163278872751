@layer components {
  .custom-dropdown {
    border-bottom: 1px solid #dbdbdb;
    @apply w-full py-5;
    &-header {
      @apply flex justify-between text-sm font-bold uppercase;
      &-label {
        color: #a0a0a0;
      }
      &-value {
        @apply text-primary flex items-center gap-2;
      }
    }
    &-list {
      &-item {
        @apply text-sm mb-5;
        &-label {
          @apply text-left uppercase;
        }
      }
    }
  }
}
