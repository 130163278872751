@layer components {
  .sell {
    .border-dashed {
      background-image: linear-gradient(to right, rgba(63, 63, 63, 1) 30%, rgba(26, 26, 26, 1) 0%);
      background-position: bottom;
      background-size: 10px 1px;
      background-repeat: repeat-x;
      height: 1px;
      @apply my-4;
    }

    .loading_container {
      @apply flex w-full justify-center items-center;
      height: calc(100vh - 200px);
    }

    .loading {
      width: 50px;
      height: 50px;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    &-landing {
      @apply text-primary;
      &__top {
        @apply flex justify-center;
        margin-top: 50px;
        margin-bottom: 80px;
        @media screen and (max-width: 675px) {
          margin-bottom: 40px;
        }
        &__title {
          @media screen and (max-width: 675px) {
            font-weight: 700;
            font-size: 26px;
          }
          font-weight: 900;
          font-size: 70px;
          line-height: 120%;
          letter-spacing: -0.02em;
        }
        &__description {
          @media screen and (max-width: 675px) {
            font-weight: 500;
            font-size: 16px;
            line-height: 170%;
            margin-top: 16px;
          }
          @apply text-2xl font-medium text-secondary;
          letter-spacing: -0.02em;
          margin-top: 30px;
        }
      }
      &__wrapper {
        @apply text-center;
        max-width: 850px;
      }
      &__section {
        @apply flex items-center justify-center w-full;
        @media screen and (max-width: 768px) {
          align-items: start;
          flex-direction: column;
          &__middle__left {
            @apply order-last;
          }
          &__bottom {
            flex-direction: column;
            align-items: start !important;
          }
        }
        margin-bottom: 30px;
        gap: 20px;
        &__wrapper {
          width: 1047px;
        }
        &__right {
          &__title {
            @media screen and (max-width: 675px) {
              font-weight: 700;
              font-size: 20px;
              line-height: 150%;
            }
            @apply text-4xl font-bold;
            letter-spacing: -0.02em;
          }
          &__description {
            @media screen and (max-width: 675px) {
              font-weight: 500;
              font-size: 16px;
              line-height: 170%;
              margin-top: 10px;
            }
            @apply text-lg text-secondary;
            margin-top: 30px;
            max-width: 585px;
            letter-spacing: -0.02em;
          }
        }
        &__left {
          margin-right: auto;
        }
        &__middle {
          &__right {
            &__title {
              @apply text-4xl font-bold;
              letter-spacing: -0.02em;
            }
            &__description {
              @apply text-lg text-secondary;
              margin-top: 30px;
              max-width: 585px;
              letter-spacing: -0.02em;
            }
          }
          &__left {
            margin-right: auto;
          }
        }
        img {
          width: 366px;
          height: 366px;
          max-width: unset;
        }
        &__bottom {
          @apply flex items-center;
          @media screen and (max-width: 675px) {
            padding: 16px;
          }
          background-color: #f2f2f2;
          padding: 50px;
          gap: 20px;
          margin-top: 70px;
          margin-bottom: 69px;
          &__left {
            @apply mr-auto;
            max-width: 592px;
            &__title {
              @media screen and (max-width: 675px) {
                font-weight: 700;
                font-size: 20px;
                line-height: 150%;
              }
              @apply text-4xl font-bold;
              letter-spacing: -0.02em;
            }
            &__description {
              @media screen and (max-width: 675px) {
                font-weight: 500;
                font-size: 16px;
                line-height: 170%;
              }
              @apply text-lg mt-4;
              letter-spacing: -0.02em;
              max-width: 484px;
            }
          }
          &__link {
            @media screen and (max-width: 675px) {
              width: 100%;
            }
          }
          &__right {
            @media screen and (max-width: 675px) {
              width: 100%;
              .btn {
                @apply uppercase w-full;
              }
            }
            .btn {
              @apply uppercase;
            }
            /* @media screen and (max-width: 675px) {
              font-weight: 500;
              font-size: 16px;
              line-height: 170%;
            } */
          }
        }
      }
    }
    &-form-left {
      &-title {
        @media screen and (max-width: 675px) {
          font-weight: 700;
          font-size: 26px;
          line-height: 150%;
        }
      }
    }

    &-hero {
      @apply text-center relative;

      &-main {
        @apply pt-6 pb-24;
      }

      &-editable {
        &::before {
          @apply absolute h-full top-0 right-0;

          content: '';
          z-index: -1;
          width: 400px;
          background: linear-gradient(
            270deg,
            rgba(212, 32, 39, 0.3) 0%,
            rgba(212, 32, 39, 0.0828) 72.4%,
            rgba(212, 32, 39, 0) 100%
          );
        }
      }
    }

    &-title {
      @apply mt-7 mb-5;
    }

    &-description {
      @apply mx-auto;
      max-width: 600px;
    }

    &-minted {
      background: rgba(163, 163, 163, 0.05);
      border: 1px solid rgba(163, 163, 163, 0.5);

      @apply p-8 text-center mx-auto;

      svg {
        @apply mx-auto;
        width: 50px;
        height: 50px;
      }

      &-title {
        @apply uppercase mt-3;
        font-family: 'Avenir Next Condensed';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
      }

      &-description {
        @apply mt-3 text-gray-400;
        font-family: 'Avenir';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }

      &-btn {
        @apply mt-4;

        span {
          @apply inline-block;
          vertical-align: middle;
        }

        svg {
          @apply mx-auto inline-block;
          width: 22px;
          height: 22px;
        }
      }

      &-back {
        @apply flex items-center justify-center mt-8 cursor-pointer;
      }
    }

    &__detail {
      @apply bg-gray-100 rounded p-5 mb-6 transition-all;

      &-list {
        &-currency {
          @apply text-primary font-normal font-Satoshi;
        }

        &-item {
          @apply mb-2 flex text-primary text-base md:text-lg font-Satoshi font-bold justify-between;
        }
      }
    }

    &__updated {
      &__button {
        @apply text-center;

        button {
          @apply w-12 h-9 p-0 mx-1;

          svg {
            @apply mx-auto;
          }
        }
      }

      &__close {
        @apply absolute right-4 top-4 cursor-pointer;
      }
    }

    &__links {
      a {
        @apply underline;

        &:hover {
          @apply no-underline;
        }
      }
    }

    &-content {
      @apply py-5 pb-10 bg-gray-850;

      @screen sm {
        @apply py-14;
      }

      &-grid {
        @apply grid-cols-1 !important;

        @screen sm {
          @apply grid-cols-2 !important;
        }
      }

      &-collectible {
        @apply mx-auto;

        @screen sm {
          max-width: 387px;
        }
      }
    }

    &__separator {
      @apply border-t border-b;
      border-top-color: #1c1c1c !important;
      border-bottom-color: #3f3f3f !important;
    }

    &__fields {
      &__offer,
      &__visibility,
      &__autoAcceptOfferAmount {
        @apply my-4;
      }

      &__custom {
        @apply grid grid-cols-2 gap-4;

        &__left {
          @apply col-start-1 col-end-5 relative;

          @screen md {
            max-width: 267px;
          }

          .form-control {
            @apply pr-12;
          }

          &__currency {
            @apply text-gray-400 absolute top-3 right-3;
          }
        }

        &__right {
          @apply col-end-7 col-span-2;
        }

        &__offer {
          @apply grid grid-cols-2 gap-4;

          &__left {
            @apply col-start-1 col-end-5 relative;

            @screen md {
              max-width: 267px;
            }

            .form-control {
              @apply pr-12;
            }

            &__currency {
              @apply text-gray-400 absolute top-3 right-3;
            }
          }

          &__right {
            @media screen and (max-width: 420px) {
              @apply col-end-7 col-span-2 mt-11;
            }

            @apply col-end-7 col-span-2 mt-6;
          }

          &__event {
            color: rgba(185, 28, 28, var(--tw-bg-opacity));
            @apply text-xs ml-3 mt-2;
          }
        }
      }

      &__note {
        @apply my-2 text-gray-400 text-sm;
      }

      &__bottom__note {
        @apply mb-2 text-center text-secondary;
      }
    }
  }
}
