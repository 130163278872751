@layer components {
  .btn {
    @apply capitalize transition duration-300 ease-in-out transform rounded;

    &--default {
      @apply border border-white text-white;
      background-color: #2b2b2b;

      &:hover {
        @apply border bg-white transition duration-300 ease-in-out;
        color: #2b2b2b;
        border: 1px solid #2b2b2b;
      }
      &.isLoading {
        text-indent: -9999px;
  
        &:before {
          content: '';
          background-image: url('@assets/svg/spinner-white.svg') !important;
          @apply absolute top-2/4 left-2/4 -ml-3 -mt-3 block w-6 h-6 bg-no-repeat bg-center animate-spin;
        }
      }
    }

    &--default,
    &--danger,
    &--success,
    &--green,
    &--full-white &--warning {
      &:hover {
        @apply transition duration-300 ease-in-out;
      }
    }

    &--full-white {
      @apply bg-white px-12 py-4;

      &:hover {
        @apply text-white;
        background-color: #2b2b2b;
      }
    }

    &--green {
      @apply text-white uppercase;
      background-color: #12AB31;
      &:hover {
        @apply bg-white;
        color: #FB5758;
        border: 1px solid #FB5758;
      }
    }

    &--danger {
      @apply border border-red-solid bg-red-solid text-white;

      &:hover {
        @apply border-red-800 bg-red-800;
      }

      &--outline {
        @apply border border-red-solid text-red-solid !important;

        &:hover {
          @apply border-red-solid bg-red-solid text-white !important;
        }
      }
    }

    &--success {
      @apply border border-green-solid bg-green-solid text-white;

      &:hover {
        @apply border-green-800 bg-green-800;
      }

      &--outline {
        @apply border border-green-solid text-green-solid !important;

        &:hover {
          @apply border-green-solid bg-green-solid text-white !important;
        }
      }
    }

    &--info {
      @apply border border-blue-solid bg-blue-solid text-white;

      &:hover {
        @apply border-blue-800 bg-blue-800;
      }

      &--outline {
        @apply border border-blue-solid text-blue-solid !important;

        &:hover {
          @apply border-blue-solid bg-blue-solid text-white !important;
        }
      }
    }

    &--warning {
      @apply border border-orange-solid bg-orange-solid text-white;

      &:hover {
        @apply border-orange-800 bg-orange-800;
      }

      &--outline {
        @apply border border-orange-solid text-orange-solid !important;

        &:hover {
          @apply border-orange-solid bg-orange-solid text-white !important;
        }
      }
    }

    &--outline {
      @apply shadow-none border border-primary bg-transparent text-primary;

      &:hover {
        @apply bg-primary text-white transition duration-300 ease-in-out;
      }
    }

    &--white-outline {
      @apply shadow-none border border-white bg-transparent text-white;

      &:hover {
        @apply bg-white text-black transition duration-300 ease-in-out !important;
      }
    }

    &--dark-outline {
      @apply shadow-none border bg-white;
      color: #2b2b2b;
      border: 1px solid #2b2b2b;

      &:hover {
        @apply text-white transition duration-300 ease-in-out !important;
        background: #2b2b2b;
      }
    }

    &--blue {
      @apply text-white;
      background-color: #2081e2;
    }

    &--text {
      @apply shadow-none border-none bg-transparent;

      &:hover {
        @apply transition duration-300 ease-in-out;
      }
    }

    &--link {
      @apply uppercase font-medium transition duration-300 ease-in-out transform;

      &__text {
        @apply relative;

        &::before {
          @apply absolute bottom-0 left-0 right-0 bg-white h-px origin-right transition ease-in duration-200;
          content: '';
          transform: scaleX(0);
        }

        &:hover {
          &::before {
            @apply origin-left;
            transform: scaleX(1);
          }
        }
      }
    }

    &--md {
      @apply px-6 text-base py-3.5 px-14;
      line-height: 21px;
    }

    &--xs {
      @apply px-6 text-sm py-3 px-8;
    }

    @media screen and (max-width: 425px) {
      &--md {
        @apply px-4 text-sm;
      }
    }

    &--sm {
      @apply px-3 py-1 text-sm;
    }

    &--lg {
      @apply px-9 py-3 text-base;
    }

    &--text {
      @apply p-0 text-base;
    }

    @media screen and (max-width: 600px) {
      &--lg {
        @apply py-2 px-6 text-sm;
      }
    }

    &--xl {
      @apply px-11 text-lg;
      padding-top: 13px;
      padding-bottom: 13px;
    }

    @media screen and (max-width: 600px) {
      &--xl {
        @apply py-2 px-6 text-base;
      }
    }

    &--form {
      @apply font-Avenir capitalize font-normal;
      @apply w-full py-4;

      border: 1px solid #4a4a4a;
      font-size: 0.875rem;

      &.btn-selected {
        background-color: #1a1a1a;
        box-shadow: 4px 4px 10px #000;
        /* stylelint-disable-next-line color-function-notation, alpha-value-notation */
        border: 1px solid rgb(0, 0, 0, 0);
      }
    }

    /* Loading state */
    &.isLoading {
      text-indent: -9999px;

      &:before {
        content: '';
        background-image: url('@assets/svg/spinner.svg');
        @apply absolute top-2/4 left-2/4 -ml-3 -mt-3 block w-6 h-6 bg-no-repeat bg-center animate-spin;
      }
    }
  }
}