/* stylelint-disable string-quotes, font-family-no-missing-generic-family-keyword */
@layer components {
  .print-collectible-checkout-modal {
    &__close {
      @apply absolute right-0 z-10 cursor-pointer;
    }

    form {
      .print-collectible-checkout-modal {
        &__grid {
          @apply block !important;
          max-height: calc(100vh - 310px);
          @apply overflow-y-scroll;
          &::-webkit-scrollbar {
            @apply w-1.5;
          }
          @screen md {
            @apply grid !important;
          }

          &::-webkit-scrollbar-track {
            @apply bg-transparent;
          }

          &::-webkit-scrollbar-thumb {
            @apply rounded-lg;
            background: rgba(255, 255, 255, 0.1);
          }
          &__left {
            @apply col-span-12 my-6 mx-0;

            @screen md {
              @apply col-span-6;
            }

            h6 {
              font-size: 0.875rem;
              font-family: 'Avenir Book';

              @apply mb-4;
            }

            &__item {
              &:not(:last-child) {
                @apply mb-4;
              }

              &__shipping {
                @apply p-6 font-normal;
                border: 1px solid rgba(163, 163, 163, 0.5);
                &__top {
                  @apply flex mb-2 font-medium;
                  &__text {
                    @apply ml-2;
                    margin-top: 0.17rem;
                  }
                }
              }

              &__flex {
                @apply flex gap-4 w-full;

                > div {
                  @apply w-full;
                }
              }

              span {
                @apply uppercase;

                font-size: 0.75rem;
              }

              input {
                @apply w-full mt-1;
              }

              select {
                @apply w-full uppercase;
              }
            }

            /* stylelint-disable-next-line selector-class-pattern */
            .checkout_frame {
              label {
                font-size: 0.7125rem;
              }
            }
          }

          &__right {
            @apply col-span-12 my-6 pr-2 mx-0;

            @screen md {
              @apply col-span-6;
            }

            &__preview {
              @apply max-h-96 overflow-y-scroll pr-1 mb-4 mx-auto;
              &::-webkit-scrollbar {
                @apply w-1;
              }

              &::-webkit-scrollbar-track {
                @apply bg-transparent;
              }

              &::-webkit-scrollbar-thumb {
                @apply rounded-lg;
                background: rgba(255, 255, 255, 0.1);
              }
            }
          }

          &__subheading {
            span {
              font-size: 0.875rem;
              font-family: 'Avenir Book';

              @apply uppercase;
            }

            @apply mb-3 uppercase;
          }
        }

        &__submit {
          @apply md:-ml-8 md:pl-8;
          @apply md:-mr-8 md:pr-8;
          @apply border-t border-black;
        }

        @media screen and (max-width: 768px) {
          &__grid {
            max-height: calc(100vh - 240px);
          }
          &__submit {
            @apply absolute bottom-0 left-0 px-5 sm:px-9 pb-5 bg-black w-full;
          }
        }
      }

      hr {
        @apply mb-4;

        border-top: 1px solid black;
      }
    }
  }
}
