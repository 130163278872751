@layer components {
  .link {
    &__underlined {
      @apply relative;

      &::before {
        @apply absolute left-0 right-0 bg-black h-px origin-right transition ease-in duration-200;

        /* stylelint-disable-next-line string-quotes */
        content: '';
        bottom: -2px;
        transform: scaleX(0);
      }

      &__white {
        &::before {
          @apply bg-white;
        }
      }

      &:hover {
        &::before {
          @apply origin-left;

          transform: scaleX(1);
        }
      }
    }
  }

  /* stylelint-disable-next-line block-no-empty */
  .link-primary {
  }

  .tab-link {
    @apply p-4 text-black;
  }

  .tab-link.active {
    @apply border-b-2;
  }
}
