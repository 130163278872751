@layer components {
  .print-collectible-summary {
    margin-bottom: 26px;
    h4 {
      @apply mb-4;
    }
    &__item {
      @apply flex items-center justify-between;
      padding: 10px;
      &__left {
        @apply text-lg font-bold;
      }
      &__right {
        @apply text-lg;
        color: #5c5c5c;
      }
    }

    h6 {
      @apply mb-4;
    }

    &__row {
      @apply flex justify-between uppercase mb-3;
    }
  }
}
