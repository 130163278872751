@layer components {
  .edition-badge {
    @apply relative inline-block text-black text-center;

    svg {
      @apply inline-block;
    }

    &__numbered {
      @apply uppercase relative top-px table-caption;
      font-size: 9px;
      line-height: 11px;
    }

    &--white {
      @apply text-white;
      svg {
        path {
          fill: #fff !important;
        }
      }
    }

    .animate-edition {
      @apply animate-spin;
      animation-duration: 4000ms !important;
    }

    &__edition {
      @apply absolute left-0 right-0 font-Avenir-NC text-xl;
      top: 16px;
      &__do-small {
        font-size: 24px !important;
        top: 14px !important;
      }
    }

    &--digital {
      .edition-badge__edition {
        @apply text-3xl;
        top: 24px;
      }
    }

    &--small {
      svg {
        width: 24px;
        height: 24px;
      }

      .edition-badge {
        &__edition {
          @apply hidden !important;
        }

        &__numbered {
          font-size: 9px;
          line-height: 11px;
        }
      }
    }

    &--base {
      svg {
        height: 40px;
        width: 40px;
      }

      .edition-badge {
        &__edition {
          @apply hidden !important;
        }

        &__numbered {
          @apply text-sm;
        }
      }
    }

    &--medium {
      .edition-badge__numbered {
        @apply text-lg;
      }
    }
  }
}
