@layer components {
  .wallet-history-table {
    th {
      @apply text-lg !important;
    }
    td {
      @apply text-lg !important;
    }
    th,
    td {
      @apply align-top;
      &:first-child {
        @apply pl-0 !important;
      }
      &:last-child {
        @apply pr-0 !important;
      }
    }
    td:nth-child(3) {
      @apply normal-case !important;
    }
    &-status {
      @apply font-normal text-gray-400 normal-case;
    }
    &-order {
      @apply cursor-pointer underline;
      &:hover {
        @apply no-underline;
      }
    }
    &-modal {
      &-button {
        @apply mt-12 text-center;
      }
    }
  }
}
