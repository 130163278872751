/* stylelint-disable alpha-value-notation, color-function-notation, string-quotes, declaration-colon-newline-after */
@layer components {
  .hero {
    @apply flex relative justify-center items-center flex-col;

    padding: 4.875rem 0;

    &__back {
      @apply absolute block w-full z-10 top-6;
    }

    &__container {
      &__heading {
        @apply text-center uppercase;
      }

      &__text {
        text-align: center;

        @apply mx-auto;
      }
    }

    &__fade-left {
      &::before {
        @apply absolute h-full top-0;

        content: '';
        z-index: -1;
        width: 400px;
        left: 0;
        background: linear-gradient(
          90deg,
          rgba(212, 32, 39, 0.3) 0%,
          rgba(212, 32, 39, 0.0828) 72.4%,
          rgba(212, 32, 39, 0) 100%
        );
      }
    }

    &__fade-right {
      &::before {
        @apply absolute h-full top-0;

        content: '';
        z-index: -1;
        width: 400px;
        right: 0;
        background: linear-gradient(
          270deg,
          rgba(212, 32, 39, 0.3) 0%,
          rgba(212, 32, 39, 0.0828) 72.4%,
          rgba(212, 32, 39, 0) 100%
        );
      }
    }

    &__fade-bottom {
      &::after {
        @apply absolute h-full top-0;

        content: '';
        z-index: -1;
        width: 100%;
        height: 100%;
        background: rgb(26, 26, 26);
        background: linear-gradient(
          0deg,
          rgba(26, 26, 26, 1) 0%,
          rgba(255, 255, 255, 0) 40%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }
  }
}
