@layer components {
  .nft-address {
    &-page {
      padding: 20px 14px;
      border: 1px solid #E9E9E9;
      border-radius: 4px;
      &__body {
        @apply flex items-center;
        &__right {
          margin-left: 14px;
        }
      }
    }
    &__status {
      @apply cursor-pointer;
      &__top {
        @apply text-sm text-secondary;
      }
      &__wallet {
        @apply flex items-center;
        &__address {
          @apply ml-2 text-lg font-bold;
          line-height: 170%;
          letter-spacing: -0.02em;
        }
      }
    }
  }
}
