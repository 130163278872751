@layer components {
  .team-grid {
    @apply grid grid-cols-4 gap-8;
  }
  @media screen and (max-width: 768px) {
    .team-grid {
      @apply grid-cols-2;
    }
  }
  @media screen and (max-width: 425px) {
    .team-grid {
      @apply grid-cols-1;
    }
  }
}
