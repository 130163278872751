@layer components {
  .order-summary-patrons {
    &__wrapper {
      &.bm-sm {
        @apply mb-4;
      }

      &.bm-md {
        @apply mb-1;

        @screen md {
          @apply mb-3;
        }
      }
    }

    &__title,
    &__content {
      @apply capitalize;

      &__price {
        @apply text-right;

        &__currency {
          @apply pl-1 text-black;
        }
      }
    }
  }
}