@layer components {
  .accept-offer-modal {
    &__summary {
      @apply pt-4;

      &-title {
        @apply capitalize font-Satoshi font-semibold;
      }

      &__wrapper {
        @apply py-4;
      }

      &__user {
        @apply normal-case;
      }

      &__title {
        @apply font-semibold;
      }

      &__content {
        @apply uppercase;

        &__price {
          &__currency {
            @apply pl-1 text-gray-400;
          }
        }
      }
    }

    &__buttons {
      @apply mt-7 flex flex-row-reverse;
      .btn {
        @apply uppercase;
      }
    }

    &__helper-text {
      @apply mt-3 text-center w-full;
    }
  }
}
