@layer components {
    .avatar {
      @apply relative flex items-center justify-center select-none text-center;
      &__skeleton {
        @apply w-full h-full absolute z-10;
      }
      &__wrapper {
        @apply w-full;
        & > div {
          @apply flex items-center justify-center font-bold text-gray-700;
        }
      }
      &__verified {
        @apply absolute inline-block -top-6 left-0 mb-2 pr-4 text-xs;
        &:before {
          content: '';
          @apply absolute top-0.5 right-0 w-3 h-3;
          background: url('@assets/svg/verified_creator.svg');
        }
      }
      &__initial {
        @apply w-full mx-auto;
        img {
          @apply w-8/12 !important;
        }
      }
  
      &__image {
        & > div {
          @apply w-full;
  
          img {
            @apply w-full rounded-full;
          }
        }
      }
  
      &--full,
      &--full img {
        @apply w-full;
      }
  
      &--xs {
        @apply w-12 h-12 text-xs;
  
        /* stylelint-disable-next-line selector-class-pattern */
        .avatar__initial {
          img {
            @apply w-4 h-4;
          }
        }
      }
  
      &--xs-40 {
        @apply w-14 h-14 text-base;
      }
  
      &--sm-16 {
        @apply w-16 h-16 text-base;
      }
  
      &--sm {
        @apply w-20 h-20 text-base;
      }
  
      &--md {
        @apply w-32 h-32 text-3xl;
      }
  
      @media screen and (max-width: 425px) {
        &--md {
          @apply w-28 h-28 !important;
        }
      }
  
      &--lg {
        @apply w-48 h-48 text-4xl;
      }
  
      &--xl {
        @apply w-64 h-64 text-6xl;
      }
    }
  }
  