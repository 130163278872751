@layer components {
  .profile-video {
    &-wrapper {
      @apply relative cursor-pointer;
    }
    &-icon {
      @apply absolute m-auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &-volume {
      @apply absolute right-2 bottom-2 bg-modalOverlay p-1 text-lg;
    }
    &-modal {
      .modal {
        @apply px-8 pt-12;
        @screen md {
          @apply px-12;
        }
      }
    }
    &-item {
      video {
        @screen sm {
          @apply mx-auto;
          max-width: 360px;
          max-height: 360px;
        }
      }
    }
    &-title {
      @apply mb-2 font-Avenir-NC normal-case text-center text-lg;
      line-height: 24px;

      @screen sm {
        @apply mb-4 text-2xl;
        line-height: 33px;
      }
    }
    &-description {
      @apply mx-auto mb-4 text-center;
      max-width: 323px;
      @screen sm {
        @apply mb-7;
      }
    }
    &-footer {
      @apply w-full pt-5  text-center;
      @screen sm {
        @apply pt-7 pb-5 flex justify-center items-center;
      }
      &-logo {
        @apply mx-auto mb-2 w-24;
        @screen sm {
          @apply mr-2 mx-0 mb-0 w-32;
        }
      }
      &-description {
        @apply text-xs whitespace-nowrap;
      }
    }
  }
}
