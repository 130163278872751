@layer components {
  .button-radio {
    button {
      @apply mb-2;

      &:not(:last-child) {
        @apply mr-3;
      }
    }
  }
}
