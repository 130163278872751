@layer components {
  .text-default {
    @apply font-Avenir-NC text-white text-base uppercase;
  }
  .heading {
    &__skeleton {
      @apply h-full z-10;
      min-height: 40px;
    }
  }
  .text {
    &__skeleton {
      @apply h-full z-10;
      min-height: 20px;
    }
  }
}
