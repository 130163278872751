@layer components {
  .custom_separator {
    width: 99em;
  }
  .custom_vertical_line {
    @apply hidden absolute border-r;
    height: 100vh;
    @screen md {
      @apply block;
    }
  }

  .custom_separator_2 {
    border-top: 1px solid #d9d9d9;
  }

  .checkout {
    @apply bg-gray-50 min-h-screen;

    &__form {
      &__disabled {
        @apply pointer-events-none;

        .checkout__grid {
          @apply opacity-50;
        }
      }
    }

    &__form {
      &__otherPayment {
        @apply flex items-center cursor-pointer !important;
      }

      &__column-2 {
        @apply grid-cols-2 !important;
      }

      label {
        font-size: 0.75rem;
      }
    }

    &__grid {
      @apply grid-cols-1 !important;

      @screen md {
        @apply grid-cols-12 gap-10 !important;
      }
    }

    &__left {
      @apply col-span-1;

      @screen md {
        @apply col-span-7 !important;
      }
    }

    &__right {
      @apply col-span-1 pt-5;
      padding-bottom: 54px;
      min-height: 100vh;
      @screen md {
        border-left: 1px solid #e9e9e9;
        min-height: 0px;
      }

      .tab-buttons .tab-button {
        font-weight: 500;
        font-size: 16px;
        line-height: 180%;
      }
      .tab-buttons .tab-button.active::after {
        height: 0.5px;
      }

      @screen md {
        @apply col-span-5 pt-0 !important;
      }
    }

    &__owner {
      @apply text-primary;

      &-title {
        @apply mb-5 capitalize text-2xl md:text-4xl font-medium;
      }

      &-detail {
        @apply pb-5 flex items-center;

        &__avatar {
          @apply mr-4;
        }

        &__info {
          &-name {
            @apply mb-1 font-semibold;
          }
        }
      }
    }

    &__form {
      &__otherPayment {
        @apply flex items-center cursor-pointer !important;
      }

      &__column-2 {
        @apply grid-cols-2 !important;
      }

      label {
        font-size: 0.75rem;
      }
    }

    &__tabs {
      @apply pt-1;
    }

    &__actions {
    }

    &__offer {
      @apply pt-4;

      &-title {
        @apply mb-4 capitalize text-2xl md:text-4xl font-medium;
      }
    }

    &__bid {
      @apply pt-4;

      &-title {
        @apply mb-4 capitalize font-medium;
      }

      &-subtitle {
        @apply capitalize mb-4 text-xs;

        &__subtle {
          @apply normal-case text-gray-400;
        }
      }
    }

    &__method {
      @apply mb-4 p-0 rounded border border-gray-300 bg-gray-100;

      &-active {
        @apply rounded bg-gray-100;
        border: 3px solid rgba(255, 67, 7, 0.4);
      }
    }

    &__whatsincluded {
      @apply py-4;

      &-title {
        @apply mb-4 capitalize font-semibold text-primary;
      }

      &__content {
        @apply text-primary;

        ul {
          @apply list-disc pl-6 my-4;

          li:not(:first-child) {
            @apply mt-2;
          }

          a {
            @apply text-gray-400 underline;
          }
        }
      }
    }

    &__form {
      &__radio,
      &__checkbox {
        @apply inline-block !important;

        label {
          @apply pl-6 relative flex items-center;

          .checkmark {
            @apply w-4 h-4 bg-white border border-gray-200 left-0 absolute;
            border-radius: 50%;
          }

          &:hover ~ .checkmark,
          input:checked ~ .checkmark {
            @apply bg-white border border-red-500;
          }
        }

        input {
          @apply absolute opacity-0 h-0 w-0;
        }
      }

      &__checkbox {
        label {
          .checkmark {
            border-radius: 0px !important;

            &::after {
              @apply absolute hidden border-red-500;
              content: '';
              left: 6px;
              top: 2px;
              width: 5px;
              height: 10px;
              border-width: 0 1px 1px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }

          input:checked ~ .checkmark::after {
            @apply block bg-red-500 text-red-500;
          }
        }
      }

      &__radio {
        @apply mr-0 !important;

        label {
          .checkmark {
            &::after {
              @apply ml-auto mr-auto right-0 left-0 absolute hidden bg-red-500 rounded w-2 h-2;
              content: '';
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }

        input:checked ~ .checkmark::after {
          @apply block;
        }
      }

      &--group {
        @apply pb-8;

        &__title {
          @apply mb-4 border-b border-black pb-4 text-xl;

          @screen md {
            @apply text-2xl;
          }
        }
      }

      &__column-2 {
        @apply grid-cols-2 !important;
      }
    }

    &__summary {
      @apply pt-4;

      &-title {
        @apply capitalize font-semibold;
        @apply mb-4;
      }
    }
  }

  .checkout__radio-icon {
    @apply flex justify-between items-center;

    > div {
      width: 100%;

      &:nth-child(2) {
        @apply hidden;
      }
    }

    @screen sm {
      > div {
        width: 50%;

        &:nth-child(2) {
          @apply block;
          width: 40%;
        }
      }
    }
  }

  .checkout__radio_label {
    @apply flex flex-row items-center text-primary;

    > svg {
      @apply ml-2 mr-4;
    }
  }
}
