@layer components {
  .sale-history-table {
    &__header {
      &__wrapper {
        @apply flex mb-5;
      }
    }

    &__stats {
      @apply mr-2 py-1 px-3 bg-primary font-RGTM text-2xl text-gray-darker uppercase;

      &__price {
        @apply ml-1;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .sale-history-table {
      &__header {
        &__wrapper {
          @apply block;
        }
      }

      &__stats {
        @apply mr-0 mb-2;
      }
    }
  }
}
