@layer components {
  .coming-soon {
    @apply relative bg-center bg-cover bg-no-repeat bg-fixed py-8 !important;
    background: url('@assets/img/bg-landing.png');
    min-height: 100vh;

    @screen sm {
      @apply pt-10 pb-8 !important;
    }
  }

  .landing {
    &-wrapper {
      @apply mx-auto text-center;
      max-width: 1024px;
    }
    &__title {
      @apply font-Avenir-NC uppercase font-semibold whitespace-pre-line text-3xl !important;
      @screen md {
        font-size: 72px !important;
        line-height: 66px !important;
      }
    }

    &__subtitle {
      @apply normal-case font-light whitespace-pre-line text-2xl !important;
      @screen md {
        font-size: 32px;
        line-height: 42px;
      }
    }

    &__description {
      @apply pt-3;
      @screen sm {
        @apply pt-8 pb-5 text-xl;
        line-height: 20px;
      }
    }

    &__terms {
      @apply mt-3 text-xs;
      line-height: 16px;
      @screen sm {
        line-height: 20px;
      }
    }

    &__form {
      @apply m-auto w-full;
      max-width: 680px;

      @screen sm {
        @apply flex;
      }

      &__field {
        @apply w-full mb-0 text-left;
        span {
          @apply px-0 text-xs text-red-200 !important;
        }
        input[type='email'] {
          @apply shadow-none !important;
          border: 1px solid rgba(255, 255, 255, 0.2) !important;
          background-image: none !important;
          background-color: rgba(255, 255, 255, 0.1) !important;
          color: rgba(255, 255, 255, 0.6) !important;
          font-size: 14px !important;
          &::placeholder {
            color: rgba(255, 255, 255, 0.6) !important;
          }
          @screen sm {
            @apply py-4 px-5 text-base !important;
            line-height: 18px;
            min-height: 50px;
          }
        }
      }

      &__button {
        @apply mt-2 w-full border-transparent bg-red-200 font-sans text-white !important;
        font-size: 14px;
        line-height: 16px;
        min-height: 40px;

        &:hover {
          @apply bg-red-700 !important;
        }
        @screen sm {
          @apply ml-4 mt-0 min-h-0 text-base !important;
          padding-top: 14px;
          padding-bottom: 18px;
          max-height: 50px;
          max-width: 190px;
        }
      }
    }
  }

  @media screen and (max-height: 481px) {
    .coming-soon {
      &__content {
        @apply py-16;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .coming-soon {
      &__single-page {
        @apply py-10 px-0;
      }

      .single-page {
        &__grid__right {
          @apply px-8 pt-0 !important;
        }
        &__content {
          &__menu {
            &-wrapper {
              @apply border-b border-white pb-8 !important;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 320px) {
    .coming-soon {
      &__content {
        @apply py-16;
      }
    }
  }

  @media screen and (max-width: 320px) {
    .coming-soon {
      &__content {
        .landing {
          &__title {
            @apply text-3xl;
            line-height: 30px;
          }
        }
      }
    }
  }

  @media screen and (min-height: 581px) and (min-width: 280px) {
    .coming-soon {
      &__floating-content {
        @apply absolute m-auto w-full;
        top: 50%;
        transform: translateY(-50%);
      }
      footer {
        @apply absolute w-full bottom-8;
      }
    }
  }
}
