@layer components {
  .confirmation {
    height: 80vh;
  }

  .marquee {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
    overflow: hidden;
    padding: 15px 0;
    white-space: nowrap;
    transform: rotateY(0deg) rotate(-10deg);
    .content {
      animation: marquee infinite 50s linear;
      animation-delay: 2s;
      @media (max-width: 540px) {
        animation-duration: 10s;
      }
      span {
        color: #e5e5e5;
        text-transform: uppercase;
        font-weight: 900;
        font-size: 56px;
        letter-spacing: -0.02em;
        margin-right: 20px;
        text-shadow: -1px -1px 0 #d3d3d3, 1px -1px 0 #d3d3d3, -1px 1px 0 #d3d3d3, 1px 1px 0 #d3d3d3;
      }
    }
    .content-reverse {
      animation: marquee infinite 50s linear reverse;
      animation-delay: 2s;
      @media (max-width: 540px) {
        animation-duration: 10s;
      }
      span {
        color: #e5e5e5;
        text-transform: uppercase;
        font-weight: 900;
        font-size: 56px;
        letter-spacing: -0.02em;
        margin-right: 20px;
        text-shadow: -1px -1px 0 #d3d3d3, 1px -1px 0 #d3d3d3, -1px 1px 0 #d3d3d3, 1px 1px 0 #d3d3d3;
      }
    }
  }

  @keyframes marquee {
    from {
      transform: translatex(0);
    }
    to {
      transform: translatex(-100vw);
    }
  }
}
