/* stylelint-disable alpha-value-notation, color-function-notation, string-quotes, declaration-colon-newline-after */
@layer components {
  .landing-gallery {
    &__section {
      @apply py-12;
      &:first-child {
        @media screen and (max-width: 500px) {
          @apply pt-0;
        }
      }
      position: relative;
      .container {
        max-width: 996px;
      }
      &__wrapper {
        @screen md {
          @apply flex justify-between;
        }
        &-reverse {
          @apply flex-row-reverse;
        }
        img {
          @apply mb-6 w-full;
          @screen md {
            @apply mb-0 w-auto;
          }
        }
      }

      &__content {
        @apply flex items-center;
        line-height: 22px;

        &__title {
          @apply mb-2 text-2xl;

          @screen md {
            @apply mb-5 text-3xl;
            line-height: 44px;
          }
        }

        @screen md {
          @apply pl-8;
          &-reverse {
            @apply pl-0 pr-8;
          }
          max-width: 558px;
        }
      }

      &__fade-left {
        &::before {
          @apply absolute h-full top-0;

          content: '';
          z-index: -1;
          width: 200px;
          left: 0;
          background: linear-gradient(
            90deg,
            rgba(212, 32, 39, 0.3) 0%,
            rgba(212, 32, 39, 0.0828) 72.4%,
            rgba(212, 32, 39, 0) 100%
          );
        }

        &::after {
          @apply absolute h-full top-0;

          content: '';
          z-index: -1;
          width: 100%;
          height: 100%;
          background: rgb(26, 26, 26);
          background: linear-gradient(
            0deg,
            rgba(26, 26, 26, 1) 0%,
            rgba(255, 255, 255, 0) 35%,
            rgba(255, 255, 255, 0) 65%,
            rgba(26, 26, 26, 1) 100%
          );
        }
      }

      &__fade-right {
        &::before {
          @apply absolute h-full top-0;

          content: '';
          z-index: -1;
          width: 200px;
          right: 0;
          background: linear-gradient(
            270deg,
            rgba(212, 32, 39, 0.3) 0%,
            rgba(212, 32, 39, 0.0828) 72.4%,
            rgba(212, 32, 39, 0) 100%
          );
        }

        &::after {
          @apply absolute h-full top-0;

          content: '';
          z-index: -1;
          width: 100%;
          height: 100%;
          background: rgb(26, 26, 26);
          background: linear-gradient(
            0deg,
            rgba(26, 26, 26, 1) 0%,
            rgba(255, 255, 255, 0) 35%,
            rgba(255, 255, 255, 0) 65%,
            rgba(26, 26, 26, 1) 100%
          );
        }
      }

      &__image {
        @apply mb-6;

        @screen sm {
          @apply mb-0;
        }
      }
    }
  }
}
