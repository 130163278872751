@layer components {
  .serial-item {
    width: 50px;
    @apply justify-center;
    .edition-badge--base .edition-badge__edition {
      @apply block !important;
      font-size: 1rem !important;
      line-height: 1rem !important;
      top: 13px;
    }
  }
}
