@layer components {
  .social-login {
    .btn {
      @apply flex items-center justify-center mb-2.5;
      svg {
        @apply mr-2;
      }
      &:last-child {
        @apply mb-0;
      }
    }
  }
}
