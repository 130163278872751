@layer components {
    .media > div {
      @apply h-full;
    }
    .media {
      @apply h-full;
      .react-player__preview {
        @apply z-50;
      }
  
      &-hide {
        display: none !important;
      }
      &-photograph {
        @apply box-border;
        &-thinner {
          @apply border-4 !important;
        }
      }
      &__image {
        @apply relative bg-center;
      }
      .video {
        &-player {
          @apply absolute top-0;
          background: #000;
          &-overlay {
            @apply absolute top-0 w-full;
            height: calc(100% - 66px);
          }
        }
      }
  
      &__audio,
      &__video {
        @apply relative aspect-w-1 aspect-h-1 bg-cover bg-center;
        img {
          @apply object-cover;
        }
        &-player {
          @apply absolute w-full bottom-2 p-4 flex items-center z-20;
          &__button {
            @apply mr-2;
            svg {
              width: 24px;
              height: 24px;
            }
            &--right {
              @apply ml-2 mr-0;
            }
          }
  
          .player__volume {
            @apply absolute px-4 pb-2 pt-3;
            background: rgba(26, 26, 26, 0.8);
            transform: translate(-25%, -100%);
            div:first-child {
              background: rgba(255, 255, 255, 0.5);
              div:first-child {
                background: rgba(255, 255, 255, 1);
              }
            }
          }
  
          &__indicator {
            @apply w-full flex items-center justify-end;
            .player__controls {
              @apply w-full mx-3 !important;
              height: 2px;
              &__seek {
                @apply w-full !important block;
                height: 2px;
                background: rgba(255, 255, 255, 0.5);
                div:first-child {
                  @apply bg-white;
                  z-index: 2;
                }
                div:nth-child(2) {
                  div {
                    @apply w-8 h-8 bg-transparent;
                    transition: 0.3s;
                    &:hover {
                      @apply w-14 h-14;
                    }
                  }
                }
              }
              &__loaded {
                @apply relative;
                border-radius: 5px;
                background-color: #a3a3a3;
                height: 2px;
                bottom: 100%;
                z-index: 1;
              }
            }
            .player__controls__duration {
              &-no {
                @apply rounded-sm px-1;
                background: rgba(255, 255, 255, 0.2);
              }
            }
          }
        }
      }
  
      &__audio {
        &-overlay {
          @apply bg-black bg-opacity-70 w-full h-full absolute top-0 opacity-0 flex items-center justify-self-center transition-all duration-300;
          &:hover {
            @apply opacity-100;
          }
          &__wrapper {
            @apply w-full;
          }
          &__title {
            @apply mb-5 text-center font-Avenir-NC text-white text-base uppercase antialiased font-semibold;
            @screen sm {
              @apply text-lg;
            }
          }
          &__button {
            @apply mt-3 mx-auto rounded-3xl border border-white bg-white flex justify-between items-center text-center text-black font-semibold antialiased uppercase font-Avenir-NC transition-all duration-200;
            width: 90%;
            max-width: 191px;
            padding: 6px 8px;
            &:hover {
              @apply bg-opacity-0 text-white;
            }
            @screen sm {
              @apply mt-5 text-base;
            }
          }
        }
      }
  
      &__vinyl {
        @apply relative aspect-w-1 aspect-h-1 bg-cover bg-center bg-gray-950;
        &__curved {
          @apply flex h-full w-full items-center justify-center uppercase;
          font-size: 12px !important;
          &__edition {
            transform: rotate(-45deg);
          }
          &__owner {
            transform: rotate(-45deg);
          }
        }
        &__box {
          @apply h-full w-full flex items-center justify-center;
          &-spin {
            animation: spin 12s linear infinite;
            &-paused {
              animation-play-state: paused;
            }
          }
        }
        &__play {
          @apply absolute top-0 bottom-0 left-0 right-0 m-auto z-50 w-auto;
        }
        &__stick {
          @apply inline-block h-auto z-10 absolute;
          @apply left-auto bottom-auto top-2 !important;
          transition: transform 0.5s;
          width: 32% !important;
          right: 2% !important;
          @screen md {
            @apply top-1 !important;
          }
          &-play {
            /* transform: rotate(19deg) translateX(-2%) translateY(-5px); */
            transform: rotate(19deg) translateX(-13%) translateY(-1.8%);
          }
        }
        &__cassette {
          @apply relative;
          border-radius: 50%;
          max-width: 527px;
          width: 80%;
          /* height: 80%; */
          filter: drop-shadow(3.86441px 3.86441px 9.66102px #000000);
          &__box {
            @apply mx-auto;
            width: 100%;
          }
          &__img {
            @apply absolute mx-auto right-0 left-0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
            width: 72%;
            &-overlay {
              content: '';
              border-radius: 50%;
              @apply opacity-30 bg-primary aspect-w-1 aspect-h-1 absolute;
              top: 50%;
              transform: translateY(-50%);
            }
            &-in {
              animation: fadeinout 2s linear;
              &-paused {
                animation-play-state: paused;
              }
            }
            &-out {
              animation: fadeoutin 2s linear;
              &-paused {
                animation-play-state: paused;
              }
            }
          }
        }
      }
  
      &__vip {
        @apply aspect-w-1 aspect-h-1;
        &__bg {
          @apply absolute top-0 left-0 w-full h-auto;
          svg {
            @apply w-full h-full;
            filter: drop-shadow(5px 5px 5px rgb(0 0 0 / 0.4));
          }
        }
        &__content {
          @apply mx-auto;
          padding-top: 3.5%;
          width: 70%;
          &-desc {
            @apply text-center;
          }
          &__media {
            @apply relative;
          }
          &__title {
            @apply mt-9 uppercase font-Avenir-NC font-semibold text-center antialiased;
            margin-top: 9%;
            background: linear-gradient(199.27deg, #d8b455 25.91%, #ffe9af 55.27%, #d8b455 84.63%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
  
            line-height: 150%;
            font-size: 5.5vw;
            @screen sm {
              font-size: 5vw;
            }
            @screen md {
              font-size: 2.5vw;
            }
            @screen lg {
              font-size: 3.5vw;
            }
            @screen xl {
              font-size: 45px;
            }
          }
          &__qr {
            @apply aspect-w-1 aspect-h-1;
            & > div {
              @apply border border-dashed border-gray-darker;
              padding: 15%;
            }
            img {
              @apply w-full rounded-lg;
            }
          }
          &__overlay {
            @apply absolute top-0 bg-black bg-opacity-50 w-full h-full opacity-0 flex items-center justify-center duration-200;
            &:hover {
              @apply opacity-100;
            }
            svg {
              @apply mx-auto;
            }
            &__text {
              @apply text-white text-center uppercase;
            }
          }
        }
      }
  
      &:not(.media--full) {
        .media__image {
          @apply aspect-w-1 aspect-h-1 bg-cover;
        }
      }
  
      &--full {
        width: 100%;
        height: auto;
      }
    }
  }
  
  .media__vinyl__control {
    @apply h-auto top-auto -bottom-2 !important;
    .player__controls__seek {
      @apply w-full bg-transparent !important;
      height: 3px;
      div:first-child {
        @apply bg-white;
        z-index: 2;
      }
      div:nth-child(2) {
        div {
          @apply hidden;
        }
      }
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes fadeinout {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeoutin {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  /* @keyframes fadeinout {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  @keyframes fadeoutin {
    0%,
    100% {
      opacity: 1;
    }
    30%,
    60% {
      opacity: 0;
    }
  } */
  