@layer components {
  .profile-museum {
    &__filter {
      @apply flex items-center;
      @media screen and (max-width: 675px) {
        @apply block;
      }
      &__input {
        @apply uppercase text-lg flex items-center gap-2 mr-auto;
        color: #a0a0a0;
        input {
          @apply !important uppercase font-medium pl-0 text-primary;
          border: none !important;
          background-color: #fbfbfb !important;
        }
        input:focus {
          box-shadow: none;
        }
      }
    }
    @apply text-primary;
    &__top {
      @apply mb-10 relative;
      &__grid {
        @apply grid grid-cols-6;
        @media screen and (max-width: 970px) {
          @apply grid grid-cols-1 items-center text-center;
          &__left {
            &__title {
              max-width: none !important ;
            }
            &__description {
              &__wrapper {
                max-width: none !important;
              }
            }
          }
          &__right {
            @apply mt-10;
            margin-right: 0px !important;
            img {
              width: 100% !important;
              height: 100% !important;
            }
          }
        }
        &__left {
          @media screen and (max-width: 675px) {
            @apply text-left;
          }
          @apply col-span-4 pr-10;
          margin-top: 110px;
          @media screen and (max-width: 675px) {
            margin-top: 50px;
          }
          &__title {
            @media screen and (max-width: 675px) {
              font-size: 26px;
              line-height: 150%;
            }
            font-weight: 900;
            font-size: 70px;
            line-height: 120%;
            max-width: 720px;
          }
          &__description {
            @apply flex relative;
            @media screen and (max-width: 675px) {
              @apply mt-5;
            }
            margin-top: 50px;
            &__wrapper {
              @apply ml-auto;
              @media screen and (max-width: 1200px) {
                @apply ml-0;
              }
              max-width: 456px;
              &__text {
                @media screen and (max-width: 675px) {
                  @apply text-base;
                }
                @apply text-2xl text-secondary;
                line-height: 170%;
              }
            }
          }
        }
        &__right {
          @apply col-span-2;
          margin-right: -75px;
          img {
            width: 515px;
            height: 643px;
          }
        }
      }
      &__detail {
        @apply flex gap-10 py-10;
        margin-right: -75px;
        @media screen and (max-width: 1200px) {
          margin-right: 0px !important;
        }
        padding-left: 420px;
        background: #fbfbfb;
        border-width: 1px 0px;
        border-style: solid;
        border-color: #e9e9e9;
        @media screen and (max-width: 1200px) {
          @apply justify-between px-10;
        }
        @media screen and (max-width: 675px) {
          @apply gap-0 mt-4 px-0 py-4;
        }
        &__field {
          @apply flex items-center;
          @media screen and (max-width: 675px) {
            @apply block;
          }
          &__value {
            @media screen and (max-width: 675px) {
              @apply text-xl;
            }
            @apply font-bold mr-4;
            font-size: 36px;
            line-height: 150%;
          }
          &__name {
            @media screen and (max-width: 675px) {
              @apply text-base font-normal;
            }
            @apply text-xl font-bold;
          }
        }
      }
      &__image {
        @media screen and (max-width: 1200px) {
          @apply hidden;
        }
        @apply absolute bottom-0;
        left: -75px;
        img {
          width: 332px;
          height: 445px;
          object-fit: cover;
        }
      }
    }
    &__featured {
      margin-top: 80px;
      @media screen and (max-width: 675px) {
        margin-top: 50px;
      }
      &__title {
        @apply font-bold;
        @media screen and (max-width: 675px) {
          font-size: 26px;
          line-height: 150%;
        }
        font-size: 40px;
        line-height: 150%;
      }
    }
    &__collectible {
      padding: 80px 0px;
      .marketplace-page__search-sorting {
        margin-left: 0px;
      }
      &__grid {
        @apply mt-12 text-center;
        &__wrapper {
          @apply gap-5;
          @media screen and (max-width: 1024px) {
            @apply grid grid-cols-2;
          }
          @media screen and (max-width: 600px) {
            @apply grid grid-cols-1;
          }
        }
      }
    }
    .collectible-profile {
      &__wrapper {
        @apply relative;
        .media__image {
          position: relative;
          &::before {
            content: '';
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
          }
        }
        &__description {
          @apply absolute bottom-0 pl-7 pr-12 transition-all ease-in-out duration-500 overflow-hidden;
          height: 50px;
          &__title {
            @apply text-white text-2xl font-bold overflow-hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          &__value {
            @apply text-white text-lg font-medium mt-5 overflow-hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
        &__description:hover {
          @apply transition-all ease-in-out duration-500;
          height: 160px;
        }
      }
    }
  }
}
