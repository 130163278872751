@layer components {
  .share-icon {
    @apply inline-block;
    &:not(:last-child) {
      @apply mr-3;
    }
    svg {
      @apply m-auto h-4;
    }
    button {
      @apply w-12 h-9 px-0;
    }
  }
}
