@layer components {
  .withdrawal-modal {
    .btn {
      @apply uppercase;
    }
    label[for='forms-validationInputCode_error']:after {
      content: '*';
      @apply text-red-300 pl-1;
    }
    .form-field {
      label {
        @apply text-xs;
      }
    }
    .input-radio:not(:last-child) {
      @apply mr-0;
    }
    &__calculate {
      @apply p-6 mb-6 mt-1 py-9 uppercase transition-all text-primary text-lg;
      background-color: #f5f5f5;
      &-list {
        &-item {
          @apply flex justify-between;
          &:not(:last-child) {
            @apply mb-2;
          }
        }
      }
    }
    &-radio {
      label {
        @apply w-full relative flex items-center;
        .icon-bitpay {
          img {
            width: 206px;
          }
        }
        .checkmark {
          @apply w-5 h-5 right-0 absolute;
          background-color: #f5f5f5 !important;
          border: 1px solid #d9d9d9;
          border-radius: 50%;
          content: '';
          &--left {
            @apply relative mr-2;
          }
          &::after {
            @apply ml-auto mr-auto right-0 left-0 absolute hidden;
            border-radius: 28px;
            width: 10.77px;
            height: 10.77px;
            background-color: #ff4307 !important;
            content: '';
            top: 50%;
            transform: translateY(-50%);
          }
        }
        &:hover ~ .checkmark,
        input:checked ~ .checkmark {
          @apply bg-white;
        }
        input:checked ~ .checkmark::after {
          @apply block;
          border: 1px solid #d9d9d9;
        }
      }
      &-icon {
        @apply w-full;
        svg {
          @apply h-5 mr-1;
        }
      }
      &-label {
        @apply w-full relative flex items-center normal-case;
      }
      input {
        @apply absolute opacity-0 h-0 w-0;
      }
    }
    &-button {
      @apply mt-9 flex w-full gap-3;
    }
    &-method {
      @apply mb-3 py-5 rounded;
      border: 1px solid #d9d9d9;
      background-color: #f5f5f5;
      .form-field {
        @apply mb-0 px-5 !important;
      }
      .separator {
        @apply mt-4 border-none;
        background: #d9d9d9;
        height: 1px;
      }
      &-active {
        background-color: #f5f5f5 !important;
        border: 3px solid rgba(255, 67, 7, 0.4);
        .withdrawal-modal-radio label .checkmark {
          border: 1px solid #ff4307;
        }
      }
    }
    &-description {
      a {
        @apply underline;
        &:hover {
          @apply no-underline;
        }
      }
    }
  }
  .amount-modal {
    &__input {
      input {
        @apply px-6 text-lg;
      }
      .label-right {
        @apply text-lg !important font-normal;
        margin-right: 14px;
      }
    }
    &__balance {
      @apply w-full rounded p-5 flex justify-center text-center mb-6;
      border: 1px dashed #e0e0e0;
      &__wrapper {
        &__field {
          @apply text-base;
        }
        &__value {
          @apply text-3xl font-bold;
        }
      }
    }
  }
  .withdraw-verify-modal {
    .btn {
      @apply uppercase;
    }
    @apply text-lg;
    .form-field {
      @apply mb-0 !important;
      label {
        @apply text-lg;
      }
    }
  }
  .withdraw-success-modal {
    @apply text-center;
    &__icon {
      @apply w-full flex justify-center;
      &__wrapper {
        @apply w-64 h-64;
      }
    }
    &__title {
      @apply font-bold;
      font-size: 28px;
      @media screen and (max-width: 675px) {
        font-size: 20px;
      }
    }
    &__information {
      @apply text-lg text-secondary mt-4 lg:mt-7 mb-24;
      @media screen and (max-width: 675px) {
        @apply text-base;
      }
    }
  }
}
