@layer components {
  .exchange-history-table {
    &__header {
      &__wrapper {
        @apply flex mb-5;
      }
    }

    &__stats {
      @apply mr-2 px-6 bg-primary font-semibold text-gray-darker capitalize;
      font-size: 16px;
      padding-block: 6px;

      &__price {
        @apply ml-1;
      }
    }

    .table td {
      @apply text-lg font-medium font-Satoshi py-7;
    }

    ,
    .table th {
      font-size: 18px;
      font-weight: 500;
      padding-block: 15px;
    }

    .user-link__name {
      @apply flex flex-col justify-start items-start;

      .nft-address {
        @apply flex p-0 items-center;
      }
    }

    .action-countdays {
      @apply font-medium;
    }

    .countdays span {
      margin: 0;
    }
  }

  .empty-exchanges-table {
    @apply flex justify-center items-center text-center;
    @apply my-4 p-2;
  }

  .exchange-history-table {
    &-badge {
      @apply py-1 px-2 text-xs normal-case !important;

      &-danger {
        @apply border border-red-200 inline-block bg-red-200 bg-opacity-10 text-red-200;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .exchange-history-table {
      &__header {
        &__wrapper {
          @apply block;
        }
      }

      &__stats {
        @apply mr-0 mb-2;
      }
    }
  }
}