@layer components {
  .collectible-card {
    &-tooltip {
      @apply absolute -top-2 left-8 cursor-default;

      &-option {
        @apply absolute -top-2 right-7 cursor-default;
      }
    }

    .react-card-back {
      @apply bg-primary;
      z-index: 20;
    }

    @apply mb-9 static;

    &__flipper {
      @apply grid justify-items-end cursor-pointer;

      .flipButton {
        @apply py-4;
      }

      &__wrapper {
        @apply flex items-center justify-between w-full;
      }
    }

    &__info {
      @apply mt-3 bg-primary shadow-card px-3 py-3;

      &__title {
        @apply font-Avenir-NC text-2xl font-semibold uppercase truncate;
      }

      &__url {
        @apply truncate;
      }
    }

    &__button {
      @apply flex flex-row justify-end items-center py-4;

      .menu-options {
        @apply cursor-pointer;
      }
    }

    &__inner {
      @apply aspect-w-1 aspect-h-1;

      &-not-flip {
        .react-card-back {
          @apply invisible;
        }
      }
    }

    &__side {
      @apply relative rounded-md w-full h-full;

      &__button {
        @apply mt-2 flex flex-row justify-between items-center;
      }
    }

    &__front {
      @apply flex flex-col;

      &__container {
        @apply w-full h-full object-center;
      }

      &__cover {
        @apply relative w-full shadow-card overflow-hidden top-0 left-0 aspect-w-1 aspect-h-1;

        &__content {
          @apply absolute w-full h-full top-0 left-0;

          &-invisible {
            transition: opacity 150ms;
            opacity: 0;
          }

          img {
            @apply w-full;
          }

          .media__image,
          .media__audio,
          .media__video {
            transition: transform 1s;
          }

          &__edition {
            @apply absolute top-2 right-2 !important;
          }
        }
      }

      &:hover .media__image,
      &:hover .media__audio,
      &:hover .media__video {
        @apply transform scale-105;
      }
    }

    &__back {
      @apply flex flex-col justify-between shadow-card p-6 overflow-hidden;

      &__info {
        &__edition {
          @apply mb-6 border-t border-b border-white py-2 text-white text-center font-Avenir-NC text-xl tracking-wide uppercase;
        }

        &__description {
          @apply mb-6 text-gray-400 whitespace-pre-wrap overflow-hidden;

          &__link {
            @apply relative uppercase text-gray-400 !important;

            &::before {
              @apply absolute -bottom-1 left-0 right-0 bg-white origin-right transition ease-in duration-200;
              content: '';
              height: 3px;
              transform: scaleX(0);
            }

            &:hover {
              @apply text-white !important;

              &::before {
                @apply origin-left;
                transform: scaleX(1);
              }
            }
          }
        }
      }

      &__preview {
        &__wrapper {
          @apply grid grid-cols-10 gap-4 items-center;
        }

        &__thumbnail {
          @apply col-span-4;

          .media {
            @apply shadow-secondary;
          }
        }

        &__users {
          @apply col-span-6;

          &__item {
            @apply overflow-hidden;

            &__title {
              @apply text-gray-500 text-sm uppercase pb-1 !important;
            }

            &__user {
              @apply grid grid-cols-10 gap-6 items-center;

              &__avatar {
                @apply col-span-2;
                width: 40px;
                height: 40px;
              }

              &__detail {
                @apply col-span-8;

                &__name {
                  @apply mb-1 text-base leading-5 font-semibold truncate;
                }

                &__url {
                  @apply text-sm truncate leading-none !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .card__avatar {
    width: 15px;
    height: 15px;
  }

  .options-menu {
    @apply relative;

    &__dropdown-toggle {
      @apply flex items-center cursor-pointer;

      @media screen and (max-width: 600px) {
        &__name {
          @apply hidden;
        }
      }
    }

    &__dropdown {
      @apply absolute top-full mt-6 w-40 bg-primary shadow-secondary text-sm right-0 z-20;

      li {
        @apply pb-4 mb-4 block border-gray-700 border-b;

        &.user-menu {
          &__dropdown__greeting {
            @apply block py-2 px-4 text-white cursor-pointer;

            &__name {
              @apply text-xl;
            }
          }
        }

        >a,
        button {
          @apply text-white transition px-4 py-3 border-gray-800 border-b flex items-center text-left;

          svg {
            @apply mr-4;
          }

          &:hover {
            @apply opacity-60;
          }
        }

        &:last-child {
          @apply border-none pb-0 mb-0;
        }
      }
    }
  }

  @media screen and (max-width: 1280px) and (min-width: 1024px) {
    .collectible {
      &-card {
        &__back {
          &__info {
            &__edition {
              @apply text-base;
            }

            &__description {
              -webkit-line-clamp: 2;
              line-clamp: 2;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 920px) and (min-width: 681px) {
    .collectible {
      &-card {
        &__back {
          &__info {
            &__edition {
              @apply text-base;
            }

            &__description {
              -webkit-line-clamp: 3;
              line-clamp: 3;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 680px) and (min-width: 640px) {
    .collectible {
      &-card {
        &__back {
          &__info {
            &__edition {
              @apply text-sm;
            }

            &__description {
              -webkit-line-clamp: 2;
              line-clamp: 2;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 460px) {
    .collectible {
      &-card {
        &__info {
          &__title {
            @apply text-xl;
          }
        }

        &__back {
          &__info {
            &__edition {
              @apply text-sm;
            }

            &__description {
              -webkit-line-clamp: 2;
              line-clamp: 2;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 450px) {
    .collectible {
      &-card {
        &__back {
          &__info {
            &__edition {
              font-size: 16px;
              line-height: 1rem;
            }

            &__description {
              -webkit-line-clamp: 4;
              line-clamp: 4;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 390px) {
    .collectible {
      &-card {
        &__back {
          &__info {
            &__edition {
              @apply text-sm leading-4;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 370px) {
    .collectible {
      &-card {
        &__back {
          &__preview {
            @apply hidden;
          }
        }
      }
    }
  }
}