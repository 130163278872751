@layer components {
  main {
    @apply overflow-hidden;
    @screen md {
      min-height: calc(100vh - 126px) !important;
    }
    padding-top: 63px;
  }
  .container {
    @apply px-6;
    @screen md {
      @apply px-5;
    }
    @screen xl {
      @apply px-0;
    }
    &--xl {
      @apply max-w-screen-xl;
    }
    @media screen and (max-width: 1280px) {
      &--xl {
        @apply px-6;
      }
    }
  }

  @media screen and (min-width: 521px) {
    main {
      min-height: calc(100vh - 200px);
    }
  }
  @media screen and (max-width: 520px) {
    main {
      min-height: calc(100vh - 220px);
    }
  }
  @media screen and (max-width: 390px) {
    main {
      min-height: calc(100vh - 248px);
    }
  }
  @media screen and (max-width: 325px) {
    main {
      min-height: calc(100vh - 268px);
    }
  }
}
