@layer components {
  .page-confirmation {
    background: url('@assets/svg/bg-pattern.svg') no-repeat, rgba(26, 26, 26, 1);
    background-size: cover;
    .modal-md {
      max-width: max-content;
      padding: 0;
    }
  }
  .confirmation {
    &--error {
      @apply p-0 !important;
    }
    @apply py-8 px-3;
    @screen sm {
      @apply py-16 px-0;
    }
    &__modal {
      &--error {
        .confirmation__header {
          @apply my-3;
        }
      }
      .modal-md {
        background: url('@assets/svg/bg-pattern.svg') no-repeat, rgba(26, 26, 26, 1);
        background-size: cover;
        max-width: max-content;
        padding: 0;
      }
    }
    &__container {
      @apply py-8 px-8 relative overflow-hidden;
      filter: drop-shadow(4px 4px 10px #000000);
      @screen sm {
        @apply py-10 px-16;
        max-width: 522px;
      }
      &::before {
        @apply absolute w-full h-full right-0 top-0;
        z-index: -1;
        content: '';
        background: #353535;
        filter: blur(100px);
      }
    }
    &__header {
      @apply pt-5 text-center;
      @screen md {
        @apply mb-5 pt-5;
      }
      &__title {
        @apply pb-2 text-xl;
        @screen md {
          @apply pb-5 text-3xl;
        }
      }
      &__description {
        @apply pb-4;
        @screen md {
          @apply pb-5;
        }
        .box.price {
          @apply inline;
        }
      }
    }
    &__content {
      @screen md {
        @apply mb-12;
      }
    }
    &__footer {
      @apply -mt-4 border-t border-borderWhite py-5;
      @screen sm {
        @apply flex items-center justify-center;
      }
      &__title {
        @apply mb-2 text-center text-base;
        @screen sm {
          @apply mr-9 mb-0;
        }
      }
      &__share {
        @apply text-center;
        &__button {
          @apply inline-block;
          @apply mr-2;

          &__dropdown {
            @apply relative;
            ul {
              @apply absolute top-full mt-2 w-52 bg-primary shadow-secondary z-10 text-sm;
              li {
                a {
                  @apply px-4 py-3 w-full flex items-center;

                  svg {
                    @apply mr-2;
                  }

                  &:hover {
                    @apply bg-gray-500;
                  }
                }
              }
            }
          }
        }
        @media screen and (max-width: 320px) {
          & > .box {
            @apply mr-1 mb-1;
          }
        }
      }
    }
    &__utility {
      @apply border-t border-borderWhite py-5;
      ul {
        @apply flex justify-between items-center;
      }
      li {
        @apply flex items-center cursor-pointer;
        svg {
          @apply ml-1 w-5 h-5;
        }
      }
      @media screen and (max-width: 480px) {
        ul {
          @apply inline-block text-center w-full;
        }
        li {
          @apply justify-between mb-2;
        }
      }
    }
  }
}
