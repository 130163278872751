@layer components {
  .collectible-modal {
    @apply bg-primary fixed w-full top-0 left-0 hidden overflow-hidden text-center;
    z-index: 100;
    transition: 0.5s;
    height: 100vh;

    &-show {
      @apply flex items-center justify-center;
    }

    &__wrapper {
      @apply inline-flex items-center justify-center relative;
      height: 100vh;

      &__content {
        @apply contents;
      }
    }

    &__image {
      @apply inline-block;
      max-height: 90%;
      max-width: 95%;
      /* max-width: 90%;
       */
    }
  }
}
