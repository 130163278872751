@layer components {
  .user-menu {
    @apply relative;
    &__dropdown {
      @apply absolute top-full bg-white z-10 text-sm right-0 text-primary;
      margin-top: 1.35rem;
      right: -24px;
      @screen sm {
        @apply w-96 right-0;
      }
      li {
        @apply py-1 px-3;
        &.user-menu__dropdown__greeting {
          @apply block py-2 px-4 cursor-pointer;
          &__name {
            @apply text-xl;
          }
        }
        a {
          @apply p-4 transition flex items-center;
          svg {
            @apply mr-3;
          }
        }
      }
      &__user {
        padding: 20px 30px !important;
        &__avatar {
          @apply relative;
          &__hover {
            @apply absolute left-0 top-0 w-full h-full flex items-center justify-center cursor-pointer !important;
            color: white;
            border-radius: 9999px;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
          }
        }
      }
      &__wallet {
        &__title {
          @apply text-secondary text-sm;
        }
        &__arrow {
          @apply absolute right-2 text-primary;
        }
        .price {
          @apply font-semibold text-lg;
        }
      }
      &__nft {
        @apply p-4;
        .nft-address {
          &-key {
            @apply text-sm font-semibold;
          }
          &-status {
            @apply block text-green-solid normal-case;
          }
          &-icon {
            @apply mr-3 bg-gray-400 pr-0 bg-opacity-5 h-10 w-10 flex items-center justify-center;
          }
        }
      }

      &__submenu {
        @apply font-bold text-lg;
      }

      &__connect-wallet {
        @apply px-4 py-4;
      }
    }
  }
}
