@layer components {
  .edition-card {
    width: 148px;
    min-height: 218px;
    .edition-badge__edition {
      @apply font-semibold antialiased;
    }
    @apply shadow-default mb-8 pt-5 relative text-center cursor-pointer;
    &__owner {
      @apply -mb-4 pt-4 pb-1;
      &__avatar {
        @apply shadow-none mx-auto overflow-hidden !important;
        border-radius: 50%;
      }
      &__name {
        @apply mt-1 text-black;
        font-size: 10px;
        line-height: 13px;
      }
    }

    &__content {
      @apply pt-3;

      &__numbered,
      &__secondary {
        &__title {
          @apply mb-2 text-white font-sans text-sm px-7 font-semibold !important;
        }
        &__available {
          @apply px-3 text-white font-sans text-xs leading-6 !important;
          @apply uppercase;
          line-height: 16px !important;
          &__of {
            @apply lowercase !important;
          }
        }
        &__information {
          @apply mb-9;
        }
        &__desc {
          @apply uppercase antialiased;
          font-size: 10px;
          line-height: 13px;
        }
        &__value {
          @apply text-white text-lg font-Avenir-NC font-semibold normal-case antialiased !important;
          .countdown {
            @apply text-base !important;
          }
        }
      }

      &__secondary {
        &__title {
          @apply px-0 !important;
        }
      }

      &__original {
        &__title {
          font-size: 10px;
          line-height: 13px;

          @apply text-black uppercase !important;
          &-default {
            padding-top: 0.8rem;
          }
          &-extra {
            padding-top: 0.8rem;
          }
          &-soon {
            @apply pt-12 !important;
          }
        }

        &__value {
          @apply text-black text-lg font-Avenir-NC font-semibold normal-case antialiased !important;
          .countdown {
            @apply text-base !important;
          }
        }
        &-out {
          .edition-card__price {
            animation: auctionOut 1s linear;
            visibility: hidden;
          }
          .edition-card__auction {
            animation: auctionIn 1s linear;
          }
        }
        &-in {
          .edition-card__price {
            animation: auctionIn 1s linear;
          }
          .edition-card__auction {
            animation: auctionOut 1s linear;
            visibility: hidden;
          }
        }
      }
    }

    &__button {
      @apply block absolute left-0 right-0 m-auto -bottom-4;
      .btn--outline {
        @apply bg-primary;
      }
      button {
        @apply text-sm;
      }
    }
    &__digital-original,
    &__numbered-edition {
      border: 1px solid rgba(163, 163, 163, 0.5);
    }
    &__numbered-edition,
    &__secondary {
      @apply px-2 pb-7;
      .edition-card__content {
        @apply flex flex-col justify-between absolute left-0 top-0 pt-6 w-full h-full;
      }
      &.wide {
        width: 296px;
      }
    }

    &__secondary {
      @apply bg-gray-850;
      border: 1px dashed rgba(255, 255, 255, 0.3);
    }

    &-active {
      @apply border-white !important;
    }

    &-loading {
      @apply flex items-center justify-center p-0;
    }
  }

  @media screen and (max-width: 360px) {
    .edition-card {
      &:not(:last-child) {
        @apply mb-10;
      }
    }
  }
}

@keyframes auctionOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes auctionIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
