@layer components {
  .profile-settings {
    @apply text-primary;
    &__title {
      @apply font-medium text-4xl;
    }
    &__cover {
      @apply mt-5;
      &__title {
        @apply font-medium text-lg;
      }
      &__image {
        @apply relative w-full h-64 bg-gray-400;
        &__button {
          @apply absolute w-full h-full flex;
          &__wrapper {
            @apply m-auto;
            .btn {
              @apply mx-1;
            }
          }
        }
        &-preview {
          @apply h-20 bg-center;
        }
      }
    }
    &__grid {
      @apply grid grid-cols-12;
      &__information {
        .btn {
          @apply uppercase;
          @media screen and (max-width: 675px) {
            @apply w-full;
          }
        }
        @apply col-span-12 order-2;
        @screen sm {
          @apply col-span-8 order-1;
        }
        select {
          height: 48px;
        }
        &__section {
          @apply font-medium text-4xl my-5;
        }
      }
      &__profile {
        @apply col-span-12 order-1 pl-0 mb-2;
        @screen sm {
          @apply col-span-4 pl-5 order-2;
        }
        &__text {
          @apply hidden;
          @media screen and (max-width: 675px) {
            @apply block;
            @apply text-base font-medium;
            margin-bottom: 6px;
          }
        }
        .isLoading {
          &:before {
            content: '';
            background-image: url('@assets/svg/spinner.svg');
            @apply block w-6 h-6 bg-no-repeat bg-center animate-spin;
          }
        }
        &__avatar {
          img {
            @apply rounded !important;
          }
          @apply flex items-center;
          &__right {
            @apply ml-4;
            &__title {
              @apply font-medium text-lg;
            }
            &__options {
              @apply mt-2 font-medium text-base flex gap-3;
              &__delete {
                @apply text-secondary cursor-pointer;
                color: #ff4307;
              }
              &__update {
                @apply cursor-pointer text-secondary;
              }
            }
          }
        }
        &__information {
          @apply flex py-6 px-4 mt-5;
          border: 1px dashed #cdcdcd;
          &__wrapper {
            @apply m-auto text-center justify-center font-medium text-xs;
            color: #979797;
            &__icon {
              @apply flex justify-center mb-5;
            }
          }
        }
      }
    }
  }
  .page-settings {
    &__wrapper {
      @apply grid-cols-1 gap-x-0 !important;
      @screen lg {
        @apply grid-cols-8 !important;
        &__left {
          min-height: calc(100vh - 210px);
          border-right: 1px solid #e9e9e9;
        }
      }
    }
  }
  .settings-sidebar {
    @apply p-9 pl-0 pt-0 hidden;
    @screen lg {
      @apply block;
    }
    &__mobile {
      @apply relative;
      @screen lg {
        @apply hidden;
      }
      &__button {
        @apply mb-3;
      }
      &__menu {
        @apply py-5 absolute top-full w-64 bg-white shadow-secondary z-20 text-sm pl-5;
        a {
          @apply block mb-7 text-primary text-xl font-medium antialiased;
          &:hover,
          &.active {
            @apply text-opacity-100;
            color: #ff4307;
          }
        }
      }
    }
    &__flex {
      gap: 100px;
      @screen lg {
        @apply hidden;
      }
      &__separator {
        @screen lg {
          @apply hidden;
        }
        height: 1px;
        width: 2000px;
        margin-left: -200px;
        overflow: hidden;
        background-color: #e9e9e9;
      }
      &__button {
        @apply mb-3;
      }
      &__menu {
        @apply pt-7 w-full text-sm flex items-center justify-between overflow-x-auto;
        gap: 26px;
        a {
          @apply block mb-7 text-primary text-xl font-medium antialiased;
          &:hover,
          &.active {
            @apply text-opacity-100;
            color: #ff4307;
          }
        }
      }
      ::-webkit-scrollbar {
        height: 0px;
      }
    }
    a {
      @apply block mb-7 text-primary text-xl font-medium antialiased;
      &:hover,
      &.active {
        @apply text-opacity-100;
        color: #ff4307;
      }
      &:last-child {
        @apply mb-0;
      }
    }
  }
  .settings-content {
    @apply p-0 pt-0;
    @screen lg {
      @apply p-9 pt-0;
    }

    .table td {
      text-transform: capitalize;
    }
  }
  .settings {
    .grid {
      @apply mb-3;
      @screen lg {
        @apply mb-5;
      }
    }
    &__grid-column-2 {
      @apply grid-cols-1 !important;
      @screen lg {
        @apply grid-cols-2 !important;
      }
      &-address {
        @screen lg {
          @apply col-span-2 !important;
        }
      }
    }
    .field-description {
      @apply text-gray-400;
    }
    .social-field {
      @apply flex flex-wrap items-start mb-4;
      &__icon {
        @apply flex items-center justify-center mr-4 w-9 h-9 rounded-full bg-white text-gray-800 text-2xl;
        &__svg {
          @apply w-3/4;
        }
      }
      &__input {
        @apply flex-1;
      }
      .form-control {
        @apply w-full;
      }
    }
    .cover-field {
      &__image {
        @apply relative w-full h-64 bg-gray-400;
        &__button {
          @apply absolute right-3 bottom-3;
          .btn {
            @apply mx-1;
          }
        }
        &-preview {
          @apply h-64 bg-center;
        }
      }
    }
    .avatar-field {
      @apply flex flex-wrap items-center pb-4;
      .form-field {
        @apply mb-0;
      }
      .btn {
        @apply mr-4;
      }
      .avatar {
        @apply w-24 h-24 mr-4 mb-2;
      }
    }
  }
  .modal-avatar,
  .modal-cover {
    &__title {
      @apply text-2xl;
      @screen sm {
        @apply text-3xl;
      }
    }
    &__upload {
      @apply border border-dashed border-gray-700 py-10 px-6 text-center;
      @screen sm {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        justify-content: center;
      }

      &__button {
        @apply mt-4;
      }
    }
    &__cropper {
      @apply mt-2;
    }
  }
  .account-settings,
  .balance-settings,
  .offers-bids-settings,
  .notifications-settings {
    &__heading {
      @apply pb-7 text-3xl capitalize text-primary font-Satoshi font-medium;
      @media screen and (max-width: 675px) {
        @apply font-bold pb-5;
        font-size: 26px;
        line-height: 150%;
      }
    }
    .separator {
      @apply mb-4;
    }
  }
  .notifications-settings {
    &__group {
      @apply mb-4;
      &:last-child {
        @apply mb-0;
      }
      .table {
        thead {
          th {
            @apply py-3 pl-0 pr-2 text-center font-Avenir-NC text-primary text-lg capitalize font-Satoshi font-bold antialiased;
            @screen sm {
              @apply text-lg px-0;
            }
            &:first-child {
              @apply text-left w-2/3;
            }
          }
        }
        tbody {
          td {
            @apply py-3 pl-0 pr-2 text-center font-sans tracking-normal text-primary font-normal text-lg font-Satoshi;
            @screen sm {
              @apply px-0;
            }
            &:first-child {
              @apply text-left;
            }
            svg.notification-toggle {
              @apply inline-block text-gray-600 text-xl cursor-pointer;
              @screen sm {
                @apply text-3xl;
              }
              &:hover,
              &.active {
                @apply text-black;
              }
            }
          }
        }
      }
    }
  }
  .account-setting {
    @apply text-primary;
    .btn {
      @apply uppercase;
      @media screen and (max-width: 675px) {
        @apply w-full;
      }
    }
    &__account {
      &__heading {
        @apply font-medium text-4xl;
        @media screen and (max-width: 675px) {
          @apply font-bold;
          font-size: 26px;
          line-height: 150%;
        }
      }
      &__username {
        @apply mt-5;
        &__error {
          @apply text-base py-2 px-4 mt-3;
          background-color: #fceeea;
          color: #fb5758;
        }
      }
    }
    &__password {
      &__heading {
        @apply font-medium text-4xl mt-3;
        @media screen and (max-width: 675px) {
          @apply font-bold;
          font-size: 26px;
          line-height: 150%;
        }
      }
      .field-help--error {
        @apply text-base py-2 px-4 mt-3 w-full;
        background-color: #fceeea;
        color: #fb5758;
      }
    }
  }
  .balance-settings {
    &__box {
      @apply mt-4 mb-4 border-dashed border-gray-750 p-10 border text-center;
      &__current {
        @apply text-gray-400 uppercase text-xs;
      }
      &__price {
        @apply mt-1 mb-5 font-Avenir-NC text-4xl;
      }
    }
    &__history {
      @apply border-t border-gray-750 pt-4 pb-4 text-gray-400 uppercase text-xs;
    }
  }
}
