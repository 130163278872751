@layer components {
  .patrons-flex {
    @apply text-primary;
    &__top {
      @apply grid grid-cols-2;
      @media screen and (max-width: 820px) {
        @apply grid grid-cols-1;
        margin-bottom: 50px;
        &__left {
          @apply order-last;
          margin-top: 50px;
        }
      }
      &__right {
        @apply bg-gray-200 relative overflow-hidden;
        height: 80vh;
        &__content {
          @apply h-full flex items-center justify-center;
          &__template {
            @apply z-50 relative mx-4;
            &__wrapper {
              @apply absolute w-full h-full left-0 top-0 p-3;
            }
            &__image {
              @apply w-full h-full;
              border-radius: 40px;
              img {
                height: 100%;
                /* border-radius: 40px; */
                width: 100%;
                object-fit: fill;
              }
            }
          }
        }
      }
      &__left {
        @apply flex items-center justify-center;
        padding: 0px 20px;
        &__content {
          max-width: 520px;
            &__select {
              @apply cursor-pointer;
              &__wrapper {
                @apply relative ;
                .select-multiple__dropdown {
                  @apply w-full mt-14;
                }
              }
              @apply flex items-center text-lg;
              padding: 9.5px 20px;
              background: #F5F5F5;
              border: 1px solid #E0E0E0;
              border-radius: 4px; 
              &__text {
                @apply mr-auto;
              }
          }
          &__title {
            @apply text-4xl font-bold;
            margin-bottom: 30px;
          }
          &__form {
            &__label {
              @apply text-lg font-medium;
              margin-bottom: 6px;
            }
            .select-wrapper {
              .text-gray-400 {
                @apply text-primary !important;
              }
            }
          }
          &__description {
            @apply text-lg;
            margin-top: 30px;
          }
          &__button {
            margin-top: 30px;
          }
        }
      }
    }
    &__gallery {
      @apply flex;
      padding: 100px 0px;
      background-color: rgba(242, 242, 242, 1);
      &__inner {
        @apply flex;
        gap: 24px;
        &__image-box {
          height: 350px;
          width: 350px;
          img {
            @apply h-full w-full object-cover;
          }
        }
      }
    }
    &__info {
      margin-top: 100px;
      margin-bottom: 50px;
      &__title {
        @apply text-4xl font-bold text-center;
      }
      &__body {
        @apply grid grid-cols-3;
        margin-top: 50px;
        @media screen and (max-width: 820px) {
          &__unit {
            &__body {
              padding: 0px 20px;
            }
          }
        }
        @media screen and (max-width: 420px) {
          @apply grid grid-cols-1;
          &__unit {
            margin-bottom: 30px;
          }
        }
        &__unit {
          &__body {
            @apply flex items-center justify-center;
            margin-top: 30px;
            &__title {
              @apply font-bold;
              font-size: 26px;
              line-height: 150%;
              letter-spacing: -0.02em;
            }
            &__description {
              @apply mt-5 text-lg;
            }
          }
          &__wrapper {
            max-width: 345px;
          }
        }
      }
    }
    &__bottom {
      background-color: #F2F2F2;
      padding: 50px;
      margin-bottom: 100px;
      &__body {
        @apply flex items-center;
        @media screen and (max-width: 820px) {
          @apply block;
          &__right {
            @apply mt-4;
          }
        }
        &__left {
          @apply mr-auto;
          &__title {
            @apply text-4xl font-bold;
          }
          &__description {
            @apply text-lg mt-4;
          }
        }
      }
    }
  }
}
