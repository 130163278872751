.patrons-icon {
  color: #2b2b2b !important;
}
.notification-button {
  .btn--md {
    padding-left: 18.5px;
    padding-right: 18.5px;
  }
  @media screen and (max-width: 425px) {
    .btn--md {
      padding-left: 18.5px !important;
    padding-right: 18.5px !important;
      padding-top: 0.875rem;
      padding-bottom: 0.875rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
  }
  }
  @screen sm {
    .btn--md {
      padding-left: 3.5rem;
      padding-right: 3.5rem;
    }
  }
}