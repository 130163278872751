/* stylelint-disable string-quotes */
@layer components {
  .print-collectible-patrons {
    @apply text-primary;

    &__show {
      &__visibility {
        position: absolute;
        z-index: -1;
        opacity: 0;
      }
      &__wrapper {
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &__body {
      @apply flex grid grid-cols-12;
      margin-top: 45px;
      @media screen and (max-width: 968px) {
        @apply grid grid-cols-1;
      }
      &__left {
        @apply col-span-7 pr-5;
        @media screen and (max-width: 968px) {
          @apply pr-0;
          margin-bottom: 50px;
        }
        &__wrapper {
          @apply cursor-pointer flex items-center justify-center;
          width: 100%;
          max-height: 500px;
          border-radius: 4px;
          img {
            @apply w-auto h-full object-contain !important;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          }
          &__barcode {
            background-color: white;
            width: 100%;
            max-width: 547px;
            @apply flex items-center justify-between p-6;
            &__title {
              font-weight: 700;
              font-size: 28px;
              line-height: 150%;
            }
            &__description {
              font-weight: 400;
              font-size: 16px;
              line-height: 170%;
            }
            &__year {
              font-weight: 400;
              font-size: 16px;
              line-height: 170%;
            }
            &__right {
              width: 100%;
              max-width: 96px;
            }
            &__left {
              width: 100%;
              max-width: 400px;
            }
            &__download {
              background-color: white;
              width: 1200px;
              @apply flex items-center;
              z-index: -1;
              padding: 50px;
              &__wrapper {
                @apply items-center text-center;
              }
              img {
                height: 400px;
                width: 400px;
              }
              &__title {
                font-weight: 700;
                font-size: 64px;
                line-height: 150%;
                margin-bottom: 20px;
              }
              &__description {
                font-weight: 400;
                text-align: center;
                font-size: 32px;
                line-height: 170%;
                &__medium {
                  font-weight: 700;
                }
              }
              &__year {
                font-weight: 400;
                font-size: 32px;
                line-height: 200%;
              }
              &__right {
                width: 1100px;
                display: flex;
                justify-content: center;
              }
              &__left {
                width: 100%;
              }
            }
          }
        }
        &__center {
          @apply w-full flex justify-center;
          &__barcode {
            @apply w-full flex justify-center;
            margin-top: 50px;
            &__wrapper {
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              width: 100%;
              max-width: 547px;
            }
            &__download {
              @apply absolute;
              z-index: -1;
              opacity: 0;
            }
          }
        }
      }
      &__right {
        @apply col-span-5;
        &__title {
          @apply font-bold text-4xl;
        }
        &__creator {
          @apply text-lg text-secondary;
          margin-top: 10px;
          &__name {
            @apply text-primary font-bold;
          }
        }
        &__size {
          @apply mt-5 text-lg text-secondary;
          &__button {
            @apply flex;
            gap: 10px;
            margin: 6px 0px;
            .btn {
              padding-left: 0px !important;
              padding-right: 0px !important;
            }
          }
        }
        &__quantity {
          @apply text-lg text-secondary mt-5;
          &__tools {
            padding: 10px;
            width: fit-content;
            @apply flex items-center justify-between;
            gap: 40px;
            background: #f5f5f5;
            border: 1px solid #e0e0e0;
            border-radius: 4px;
            margin-top: 6px;
            &__function {
              width: 30px;
              height: 30px;
              @apply flex items-center justify-center text-white text-xl cursor-pointer;
              background: #2a2a2a;
              border-radius: 4px;
            }
          }
        }
        &__description {
          @apply p-6 mt-5;
          background: #f5f5f5;
          border-radius: 4px;
          &__price {
            @apply font-bold flex items-end;
            font-size: 46px;
            line-height: 62px;
            &__vat {
              @apply text-sm font-medium text-secondary;
            }
          }
          &__shipping {
            @apply text-primary font-medium mt-4;
            &__list {
              margin-top: 6px;
              width: 100%;
            }
          }
          &__country {
            @apply relative text-secondary;
            &__wrapper {
              @apply flex items-center cursor-pointer font-normal capitalize tracking-wide;
              svg {
                @apply ml-0.5;
                width: 12px;
                height: 12px;
              }
            }
            &__dropdown {
              @apply absolute top-4 right-0 mt-6 p-5 w-56 lowercase bg-white shadow-secondary z-10 overflow-auto max-h-64;
              li {
                span {
                  @apply block cursor-pointer;
                  &:hover {
                    @apply cursor-pointer opacity-60;
                  }
                }
              }
              ::first-line {
                @apply capitalize;
              }
              li:not(:last-child) {
                @apply pb-4;
              }
              &::-webkit-scrollbar {
                @apply w-2 h-2;
              }
              &::-webkit-scrollbar-thumb {
                @apply bg-gray-700 rounded;

                &:hover {
                  @apply bg-gray-600;
                }
              }
            }
            &__dropdown.profile {
              @apply right-auto;
            }
          }
        }
      }
    }
    &__bottom {
      background: #f2f2f2;
      margin-top: 80px;
      @media screen and (max-width: 968px) {
        @apply px-5;
      }
      &__body {
        padding-top: 80px;
        padding-bottom: 80px;
        &__head {
          @apply flex items-center justify-between;
          &__title {
            @apply text-4xl font-bold;
            @media screen and (max-width: 600px) {
              @apply text-2xl;
            }
          }
        }
        &__collectible {
          @apply grid grid-cols-3;
          padding-top: 50px;
          gap: 30px;

          @media screen and (max-width: 968px) {
            @apply grid grid-cols-1 gap-4 py-8;
          }
        }
      }
    }
    &__zoom {
      @apply fixed left-0 top-0 flex items-center justify-center;
      background: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(25px);
      width: 100%;
      height: 100%;
      z-index: 99999;
      &__image {
        @apply relative flex items-center justify-center;
        &__close {
          @apply absolute right-0 top-0 cursor-pointer;
          margin: 42.5px;
        }
        width: 100%;
        height: 100%;
        &__media {
          @apply flex items-center justify-center;
          .media__image {
            @apply flex items-center justify-center;
          }
          .media--full {
            width: 100%;
            height: 100%;
          }
          width: 90vw;
          height: 90vh;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}
