@layer components {
  .marketplace-page {
    @apply text-primary text-center;
    background-color: #f3f3f3;
    &__body {
      padding: 40px 0px;
      padding-bottom: 80px;
      @media screen and (max-width: 1380px) {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
      }
      &__title {
        @apply text-2xl md:text-4xl font-bold text-left;
      }
      &__filter {
        @apply flex items-center;
        @media screen and (max-width: 1070px) {
          @apply block;
        }
        .marketplace-page_search {
          @media screen and (max-width: 675px) {
            @apply hidden;
          }
        }
        margin-top: 42px;
        @screen md {
          margin-top: 60px;
        }
        &__input {
          @apply uppercase text-lg flex items-center gap-2 mr-auto;
          color: #a0a0a0;
          &__wrapper {
            @apply w-full;
            input {
              @apply w-full;
            }
          }
          input {
            @apply !important uppercase font-medium text-primary pl-0;
            background-color: #f3f3f3;
            border: none !important;
          }
          input:focus {
            box-shadow: none;
          }
        }
        &__right {
          @apply flex;
          &__field {
            &__sort {
              @apply text-xl font-bold mr-2.5 ml-5;
              color: #a0a0a0;
              @media screen and (max-width: 1070px) {
                @apply ml-0;
                .select-multiple__dropdown {
                  @apply left-0 !important;
                  margin-top: 30px !important;
                }
              }
            }
            @apply text-xl font-bold mr-2.5;
            color: #a0a0a0;
          }
          &__option {
            @apply text-xl font-bold;
          }
        }
      }

      &__result {
        @apply flex items-center mt-4;
        margin-bottom: 26px;
        @screen md {
          @apply my-9;
        }
        &__count {
          @apply mr-auto text-base md:text-lg text-secondary;
        }
        &__show {
          @apply flex gap-2;
          .grid {
            height: 20px;
          }
          .point-filter {
            @apply rounded-full;
            @media screen and (max-width: 675px) {
              @apply hidden;
            }
            width: 9px;
            height: 9px;
            margin: 0px 0.5px;
            background: #b6b6b6;
          }
          .point-filter-active {
            @apply rounded-full;
            @media screen and (max-width: 675px) {
              @apply hidden;
            }
            width: 9px;
            height: 9px;
            margin: 0px 0.5px;
            background: #000000;
          }
          .responsive-filter {
            @apply hidden;
            @media screen and (max-width: 675px) {
              @apply block;
            }
          }
        }
      }
      &__cards {
        @apply mt-6 gap-6 mb-8;
        @media screen and (max-width: 675px) {
          @apply grid grid-cols-1 !important;
        }
      }
    }
    &__search {
      @apply flex w-full mb-4 md:mb-9 items-center flex-col-reverse md:flex-row justify-between;
      &-button {
        @apply flex w-full;

        @screen md {
          width: 255px;
        }
        button {
          @apply w-full text-white;
          line-height: 20px !important;
          @screen md {
            @apply mr-3 w-auto;
          }
          &:not(:last-child) {
            @apply mr-3;
            @screen md {
              @apply mr-3;
            }
          }
          svg {
            @apply mr-2;
          }
        }
      }
      &-sorting {
        @apply md:block ml-4 relative;
        width: 100%;
        @media screen and (max-width: 1070px) {
          @apply ml-0;
          .select-multiple__dropdown {
            @apply left-0 !important;
            margin-top: 30px !important;
          }
        }
        &-header {
          @apply mr-1 flex items-center uppercase text-gray-darker text-base font-semibold antialiased;
          @screen sm {
            @apply mr-4 text-lg;
          }
          &__type {
            @apply text-lg font-bold mr-2.5;
            color: #a0a0a0;
          }
          &__item {
            @apply flex items-center text-lg text-primary font-bold cursor-pointer;
            svg {
              @apply ml-2 w-4;
            }
          }
        }
      }
      &-input {
        @apply w-full;
        &-field {
          @apply mb-4;
          @screen md {
            @apply mb-0;
            width: calc(100% - 490px);
          }
        }
        input {
          @apply text-sm !important;
          height: 34px !important;
          padding-top: 9px !important;
          padding-bottom: 9px !important;
          &::placeholder {
            @apply text-gray-400 !important;
          }
        }
      }
    }
    .marketplace-page-separator.separator {
      border-top-color: #dbdbdb !important;
      border-bottom-color: #dbdbdb !important;
    }
  }
}
