@layer components {
  .collectible {
    &__zoom {
      @apply fixed left-0 top-0 flex items-center justify-center;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(25px);
      width: 100%;
      height: 100%;
      z-index: 99999;
      &__image {
        @apply relative flex items-center justify-center;
        &__close {
          @apply absolute right-0 top-0 cursor-pointer;
          margin: 42.5px;
        }
        width: 100%;
        height: 100%;
        &__media {
          @apply flex items-center justify-center;
          .media__image {
            @apply flex items-center justify-center;
          }
          .media--full {
            width: 100%;
            height: 100%;
          }
          width: 90vw;
          height: 90vh;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
    &__share {
      &__button {
        @apply inline-flex items-center cursor-pointer;
      }

      &__text {
        @apply ml-4 text-base capitalize;
      }
    }

    @apply text-primary w-full;
    padding: 40px 0px;
    padding-bottom: 80px;

    &__left {
      @apply col-span-7;
      @media screen and (max-width: 968px) {
        @apply col-span-12 px-6;
      }
    }

    &__image {
      @apply flex justify-center;

      &__wrapper {
        @apply relative;
        width: fit-content;
        img {
          max-height: 650px;
        }
        .media--full {
          width: auto;
          height: 100%;
        }
      }
      &__zoom {
        @apply absolute top-0 right-0 rounded cursor-pointer;
        padding: 11px;
        background: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(10px);
        margin: 20px;
      }
    }

    &__detail {
      @apply col-span-5;
      &__sticky {
        @apply sticky;
        top: 100px;
      }
      .extraClass {
        pointer-events: auto !important;
        &:hover {
          visibility: visible !important;
          opacity: 1 !important;
        }
      }

      @media screen and (max-width: 968px) {
        @apply col-span-12 px-6 pt-8;
      }

      &__free {
        @apply mt-5 py-6;
        border-width: 1px 0px;
        border-style: solid;
        border-color: #e9e9e9;
        border-color: #e9e9e9;
        filter: drop-shadow(8px 14px 38px rgba(0, 0, 0, 0.02));
        &__description {
          @apply text-sm text-secondary;
        }
        &__link {
          @apply text-base flex items-center font-medium uppercase cursor-pointer;
          margin-top: 6px;
          svg {
            @apply ml-2;
            color: #2b2b2b;
            height: 15px;
            width: 15px;
          }
        }
      }

      &__printable {
        @apply p-4 flex items-center cursor-pointer;
        margin-top: 30px;
        background: linear-gradient(
          316.96deg,
          #2b2b2b 2.99%,
          #8a8a8a 45.43%,
          #676767 66.15%,
          #2b2b2b 100%
        );
        border-radius: 4px;
        &__text {
          @apply text-base text-white font-bold ml-3;
        }
        &__arrow {
          @apply ml-auto;
        }
      }

      &__top {
        @apply flex;

        &__field {
          @apply flex items-center;
          &__text {
            @apply text-base font-medium;
            @media screen and (max-width: 968px) {
              @apply text-sm;
            }
          }

          &__icon {
            @apply mr-2;
          }
        }
      }

      &__title {
        @apply font-bold mt-5;
        @media screen and (max-width: 968px) {
          font-size: 26px;
          line-height: 150%;
        }
        font-size: 36px;
        line-height: 150%;
      }

      &__creator {
        @apply text-lg mt-4 text-secondary;
        @media screen and (max-width: 968px) {
          @apply text-base;
        }

        &__name {
          @apply text-primary font-bold cursor-pointer;
        }
      }

      &__logo {
        @apply flex text-lg items-center gap-2 mt-3;
        img {
          height: 60px;
        }
        &__text {
          @apply font-bold text-xl text-primary;
        }
      }

      &__link {
        @apply text-base font-medium mt-4;
        padding: 6px 20px;
        width: max-content;
        border: 1px solid #2b2b2b;
        border-radius: 100px;
        &__content {
          @apply flex items-center justify-center;
        }
      }

      &__description {
        @apply text-lg text-secondary mt-4;
        margin-bottom: 30px;
        @media screen and (max-width: 968px) {
          @apply text-base;
        }
        &__more {
          @apply text-primary font-medium cursor-pointer;
        }
      }

      &__vtms {
        @apply pb-5 lg:pb-0;
        margin-top: 30px;
      }

      &__menu {
        @apply flex items-center justify-between relative;
        margin: 28px 0px;
        background: #f5f5f5;
        border: 1px solid #e9e9e9;
        border-radius: 4px;
        padding: 15px 20px;
        &__middle {
          @apply flex items-center justify-center text-base font-bold cursor-pointer;
          border-right: 1px solid #e0e0e0;
          border-left: 1px solid #e0e0e0;
          width: 46%;
        }
        &__left {
          @apply flex items-center justify-start font-bold text-base cursor-pointer;
          width: 32%;
          &__dropdown {
            @apply absolute left-0 top-0 w-full z-30 text-sm cursor-pointer;
            margin-top: 60px;
            padding: 30px;
            background: #fbfbfb;
            box-shadow: 8px 14px 38px rgba(0, 0, 0, 0.1);
            height: fit-content;
            width: 342px;
            &__content {
              @apply text-base;
              &-title {
                @apply text-secondary font-normal;
              }
              &-link {
                @apply text-primary font-medium;
              }
            }
          }
        }
        &__right {
          @apply flex items-center justify-end text-base font-bold cursor-pointer;
          width: 32%;
        }
      }

      &__price {
        @apply rounded w-full p-6 text-lg font-bold;
        .btn {
          @apply uppercase;
        }
        margin-top: 28px;
        border: 1px solid #e9e9e9;
        background: linear-gradient(316.96deg, #d2d2d2 2.99%, #fff8f8 46.95%, #ebeaea 100%);

        &__edition {
          @apply text-lg font-bold;
          @media screen and (max-width: 968px) {
            @apply text-base;
          }
        }

        &__detail {
          @apply grid grid-cols-3;
          @media screen and (max-width: 968px) {
            @apply flex items-center justify-between;
          }

          &__field {
            &__name {
              @apply font-medium text-xs mt-3 text-secondary uppercase;
              @media screen and (max-width: 968px) {
                @apply capitalize;
              }
              margin-bottom: 6px;
            }
            &__value {
              font-size: 20px;
              line-height: 150%;
              letter-spacing: -0.02em;
              @media screen and (max-width: 968px) {
                @apply text-base;
              }
            }
          }
        }
      }
    }

    &__tab {
      .tab-full > div {
        overflow-x: auto;
        .tab-buttons {
          min-width: 480px;
        }
        &::-webkit-scrollbar {
          @apply hidden;
        }
      }
      .tab-button {
        @apply text-sm;
      }
      @apply col-span-12;
      &__sticky {
        @media screen and (max-width: 968px) {
          @apply hidden;
        }
      }
      &__block {
        @apply hidden;
        @media screen and (max-width: 968px) {
          @apply block;
        }
      }

      @media screen and (max-width: 968px) {
        @apply col-span-12 px-6 pt-4;
      }

      &__marketplace::-webkit-scrollbar {
        @apply w-1.5;
      }

      &__marketplace::-webkit-scrollbar-track {
        @apply bg-transparent;
      }

      &__marketplace::-webkit-scrollbar-thumb {
        @apply bg-secondary max-h-5 rounded;
      }

      &__marketplace {
        @apply pt-8;
        max-height: 500px;
        overflow-y: scroll;
        .collectible__tab__marketplace__table__artists {
          padding-top: 4px;
          padding-bottom: 4px;
        }
        &__responsive {
          @apply flex items-center p-4 justify-between;
          background: #f2f2f2;
          border-radius: 4px;
          margin-bottom: 10px;
          &__number {
            @apply text-sm font-medium;
            margin-right: 10px;
          }
          &__avatar {
            @apply flex items-center;
            &__image {
              @apply relative;
              margin-right: 10px;
              .avatar--xs {
                height: 35px !important;
                width: 35px !important;
              }
              &__opensea {
                @apply absolute right-0 bottom-0 -mr-1;
              }
            }
            &__text {
              &__time {
                @apply font-medium text-secondary uppercase;
                font-size: 8px;
                line-height: 100%;
              }
              &__name {
                @apply text-sm font-medium mt-1;
              }
            }
          }
          &__price {
            &__label {
              @apply uppercase text-secondary;
              font-size: 8px;
              line-height: 100%;
            }
            &__value {
              @apply text-sm font-medium mt-1;
            }
          }
          &__icon {
            @apply ml-4;
          }
        }

        &__empty {
          @apply flex items-center justify-center;
          margin-top: 80px;
          margin-bottom: 120px;
          &__wrapper {
            @apply text-center;
            &__icon {
              @apply flex items-center justify-center;
              img {
                height: 100px;
                width: 100px;
              }
            }
            &__title {
              @apply text-xl md:text-4xl font-bold mt-4;
            }
            &__description {
              @apply text-base md:text-lg mt-4 text-secondary;
              max-width: 450px;
            }
          }
        }
        &__empty-search {
          @apply flex items-center justify-center;
          margin-top: 80px;
          margin-bottom: 120px;
          &__wrapper {
            @apply text-center;
            &__icon {
              @apply flex items-center justify-center;
            }
            &__title {
              @apply text-4xl font-bold mt-4;
            }
            &__description {
              @apply text-lg mt-4 text-secondary;
            }
          }
        }

        &__top {
          @apply flex;

          &__search {
            @apply flex items-center mr-auto;

            input {
              @apply ml-2 bg-gray-50 !important;
              border: none !important;
              width: 100%;
            }
          }

          &__filter {
            @apply flex;

            @media screen and (max-width: 960px) {
            }

            &__field {
              @apply text-xl font-bold mr-2.5;
              color: #a0a0a0;
            }

            &__option {
              @apply text-xl font-bold;
            }

            &__left {
              @apply flex mr-5;
            }

            &__right {
              @apply flex;
            }
          }
        }

        &__table {
          &__data {
            @apply table;
            @media screen and (max-width: 968px) {
              @apply hidden;
            }
          }
          &__responsive {
            @apply hidden;
            @media screen and (max-width: 968px) {
              @apply block;
            }
          }
          &__artists {
            @apply p-6 pl-0 flex items-center;

            &__detail {
              @apply ml-3 pt-1;

              &__name {
                @apply text-left capitalize;
                font-size: 20px;
              }
            }
          }
        }
      }

      &__detail::-webkit-scrollbar {
        @apply w-1.5;
      }

      &__detail::-webkit-scrollbar-track {
        @apply bg-transparent;
      }

      &__detail::-webkit-scrollbar-thumb {
        @apply bg-secondary max-h-5 rounded;
      }

      &__detail {
        @apply pt-8;
        max-height: 500px;
        overflow-y: scroll;

        &__description {
          &__title {
            @apply text-lg font-bold;
            @media screen and (max-width: 960px) {
              @apply text-base;
            }
          }

          &__value {
            @apply text-lg text-secondary font-normal mt-5;
            @media screen and (max-width: 960px) {
              @apply text-base;
            }
          }
        }

        &__table {
          @apply mt-9;

          td {
            @apply pl-0 !important;
          }

          &__left {
            @apply text-lg font-bold;
            @media screen and (max-width: 960px) {
              @apply text-base;
            }
          }

          &__right {
            @apply text-lg font-normal;
            @media screen and (max-width: 960px) {
              @apply text-base;
            }
          }
        }
      }

      &__artist::-webkit-scrollbar {
        @apply w-1.5;
      }

      &__artist::-webkit-scrollbar-track {
        @apply bg-transparent;
      }

      &__artist::-webkit-scrollbar-thumb {
        @apply bg-secondary max-h-5 rounded;
      }

      &__artist {
        @apply mt-8 px-6 py-10;
        background-color: #f5f5f5;
        max-height: 500px;
        overflow-y: scroll;

        &__image {
          @apply mt-6;
          img {
            border-radius: 0px !important;
          }
        }
        &__bottom {
          @apply flex items-center text-base font-bold uppercase mt-6;
          gap: 8px;
          svg {
            height: 16px;
            width: 16px;
          }
        }

        &__name {
          @apply text-3xl font-bold;
          @media screen and (max-width: 960px) {
            @apply text-base;
          }
        }

        &__description {
          @apply text-lg text-secondary mt-6;
          @media screen and (max-width: 960px) {
            @apply text-base;
          }
        }
        &__button {
          .black {
            @apply hidden;
          }
          &:hover {
            .black {
              @apply block;
            }
            .white {
              @apply hidden;
            }
          }
        }
      }

      &__commentary::-webkit-scrollbar {
        @apply w-1.5;
      }

      &__commentary::-webkit-scrollbar-track {
        @apply bg-transparent;
      }

      &__commentary::-webkit-scrollbar-thumb {
        @apply bg-secondary max-h-5 rounded;
      }

      &__commentary {
        @apply pt-10;
        max-height: 1200px;
        overflow-y: scroll;
        .field-help--error {
          color: #b91c1c;
        }
        &__empty {
          @apply flex items-center justify-center;
          margin-top: 45px;
          margin-bottom: 85px;
          &__wrapper {
            @apply text-center;
            &__icon {
              @apply flex items-center justify-center;
            }
            &__title {
              @apply text-xl md:text-4xl font-bold mt-4;
            }
            &__description {
              @apply text-base md:text-lg mt-4 text-secondary;
            }
          }
        }

        &__count {
          background-color: #f5f5f5;
          @media screen and (max-width: 960px) {
            @apply text-xl;
          }
          @apply px-6 pt-8 text-3xl font-bold;
        }

        .separator {
          border-top-width: 1px;
          border-bottom-width: 1px;
          border-top-color: #d2d2d2 !important;
          border-bottom-color: #d2d2d2 !important;
        }

        &__comment::-webkit-scrollbar {
          @apply w-1.5;
        }

        &__comment::-webkit-scrollbar-track {
          @apply bg-transparent;
        }

        &__comment::-webkit-scrollbar-thumb {
          @apply bg-secondary max-h-5 rounded;
        }

        &__comment {
          @apply pb-10 mb-6;
          background-color: #f5f5f5;
          max-height: 500px;
          overflow-x: scroll;
          .collectible__tab__marketplace__table__artists__detail__name {
            @media screen and (max-width: 960px) {
              @apply text-base font-bold;
            }
          }

          .collectible-comment {
            .collectible__tab__commentary__input__value {
              @apply pl-0;
            }
            .field-help--error {
              color: #b91c1c;
            }
            @apply px-6;

            &__top {
              @apply flex items-center w-full;

              &__icon {
                @apply ml-auto;
              }
            }

            &__value {
              @apply text-lg text-secondary;
              @media screen and (max-width: 960px) {
                @apply text-base;
              }
            }

            &__bottom {
              @apply flex items-center text-sm font-bold mt-4 uppercase cursor-pointer;
            }

            &__childComment {
              @apply pl-12;
            }
          }
        }

        &__input {
          .btn {
            @apply uppercase;
          }
          @media screen and (max-width: 960px) {
            .btn {
              @apply w-full;
            }
          }

          &__label {
            @apply text-3xl p-8 font-bold;
            @media screen and (max-width: 960px) {
              @apply text-xl;
            }
          }
          &__value {
            padding: 0px 1px;
          }
        }
      }
    }

    &__more {
      @media screen and (max-width: 968px) {
        @apply px-6;
      }

      &__bottom {
        &__button {
          @apply hidden;
          @media screen and (max-width: 968px) {
            @apply block uppercase;
          }
        }
      }

      &__top {
        @apply flex;
        &__button {
          @media screen and (max-width: 968px) {
            @apply hidden;
          }
        }

        &__text {
          @apply text-4xl font-bold mr-auto;
          @media screen and (max-width: 968px) {
            font-size: 26px;
            line-height: 150%;
          }
        }
      }

      &__collectible {
        @apply grid grid-cols-3 gap-4 pt-8;

        @media screen and (max-width: 968px) {
          @apply grid grid-cols-1 gap-4 py-8;
        }
      }
    }
  }
}
