@layer components {
    .artist-card {
        @apply bg-white;
        &__profile {
            @apply flex items-center p-5;
            &__name {
                @apply mr-auto text-lg font-bold flex items-center;
            }
            &__arrow {
                svg {
                    height: 14px;
                }
            }
        }
        &__detail {
            @apply grid grid-cols-3 p-5 text-left;
            &__field {
                &__name {
                    @apply text-secondary text-sm;
                }
                &__value {
                    @apply font-medium text-base;
                    line-height: 170%;
                }
            }
        }
        &__collectible {
            @apply grid grid-cols-3 p-5 gap-6;
            img {
                max-height: 110px;
                width: 100%;
            }
            &__empty {
                @apply flex items-center justify-center;
                margin-top: 3vh;
                margin-bottom: 3vh;
                @media screen and (max-width: 1124px) {
                    margin-bottom: 3vh;
                    margin-top: 3vh;
                  }
                  @media screen and (max-width: 800px) {
                    margin-top: 6vh;
                    margin-bottom: 6vh;
                  }
                &__title {
                    @apply text-xl font-bold;
                }
                &__description {
                    @apply text-base text-secondary;
                    margin: 0 3vw;
                }
            }
        }
        &__bio {
            @apply m-5 text-left text-secondary text-sm overflow-hidden;
            min-height: 40px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
  }