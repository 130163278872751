/* stylelint-disable string-quotes */
@layer components {
  .print-collectible-page {
    &__collectible-print {
      @apply cursor-pointer;
      @apply mx-auto;
    }
    &__preview {
      @apply p-4 pt-24;
      @screen sm {
        @apply pt-20;
      }
      @screen md {
        @apply pt-16;
      }
      &__text {
        @apply absolute top-6 w-9/12;
        @screen md {
          @apply w-full text-center;
        }
      }
      &__image {
        @apply object-contain w-full;
        max-height: calc(100vh - 160px);
        @screen md {
          max-height: calc(100vh - 80px);
        }
      }
    }
  }
}
