@layer components {
  .form-field {
    @apply mb-5;
    label {
      @apply block mb-1 capitalize font-medium text-left text-base;
      color: #2a2a2a;
      &.label-right {
        @apply absolute right-3 top-0 flex h-full items-center;
        @apply text-gray-400 text-sm;
      }
    }
    .form-control {
      @apply w-full text-primary;
      input {
        &:focus {
          @apply border border-red-500 !important;
        }
      }
    }
    .form-label {
      @apply text-base capitalize;
    }
    .field-help {
      @apply inline-block text-sm text-gray-400;
      &--error {
        @apply text-red-700 py-2 px-3;
      }
      a {
        &:hover {
          @apply underline;
        }
      }
    }
  }
  .form-checkbox {
    label {
      @apply normal-case;
      input {
        @apply mr-2 border border-white border-solid;
      }
    }
  }

  .select-multiple {
    @apply relative;
    .select {
      @apply text-gray-400 border border-gray-775 cursor-pointer;
      background: linear-gradient(
        180deg,
        rgba(43, 43, 43, 0) 0%,
        rgba(43, 43, 43, 0.52) 26.04%,
        #2b2b2b 100%
      ) !important;
      padding: 6px 13px;
    }
    &__dropdown-icon {
      @apply z-10 absolute top-3 right-3 cursor-pointer;
      &-open {
        transform: rotate(180deg);
      }
    }
    &.select-wrapper::after {
      top: 20px !important;
    }
    &__dropdown {
      @apply absolute right-0 top-0 w-full z-30 text-sm cursor-pointer;
      margin-top: 50px;
      width: 315px;
      ul {
        @apply overflow-y-scroll;
        background-color: #ffffff;
        max-height: 244px;
        width: 100%;
        color: #a0a0a0;
        padding: 0px !important;
        &::-webkit-scrollbar {
          @apply w-2 h-2 hidden;
        }
      }
      &::-webkit-scrollbar-thumb {
        @apply rounded;
        background-color: red;

        &:hover {
          @apply bg-gray-600;
        }
      }
      li {
        @apply py-5 px-7 cursor-pointer text-left text-lg;
        &:hover,
        &.active {
          @apply text-primary;
        }
      }
    }
    &__selected {
      @apply inline-flex flex-wrap;
      &-item {
        @apply h-8 border border-gray-775 flex items-center;
        margin: 0 10px 10px 0;
        padding: 5px 10px;
        svg {
          @apply ml-2 cursor-pointer;
        }
      }
    }
  }

  .input-radio {
    @apply flex;
    input {
      @apply mr-2;
    }
  }

  input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      @apply m-0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  select,
  .select {
    background-color: #f5f5f5 !important;
    border: 1px solid #e0e0e0 !important;
    @apply text-primary rounded;
  }
  .select-wrapper {
    @apply relative;
  }
  input,
  textarea,
  .fans__control,
  .fans__menu,
  .fans-select-autocomplete > .css-1s2u09g-control {
    background-color: #f5f5f5 !important;
    border: 1px solid #e0e0e0 !important;
    @apply text-primary rounded !important;
    &:focus {
      --tw-ring-color: rgba(255, 67, 7, 0.4) !important;
    }
  }
  input:focus {
    border: 1px solid #e0e0e0 !important;
  }

  input:disabled {
    @apply cursor-not-allowed;
  }

  .css-26l3qy-menu {
    @apply bg-black border-none !important;
  }

  .css-qc6sy-singleValue {
    @apply text-gray-500 !important;
  }

  .fans__input-container {
    @apply text-white !important;
  }

  .fans__option {
    background-color: transparent !important;
    @apply bg-black-gradient border-none;

    &:hover,
    &:focus {
      @apply bg-black border-none;
    }
  }

  .fans__multi-value__remove {
    @apply text-black;
  }

  .input {
    &-xs {
      @apply h-8 text-xs !important;
    }
    &-sm {
      @apply h-10 text-sm !important;
    }
    &-md {
      @apply h-12 text-base !important;
    }
    &-lg {
      @apply h-14 text-lg !important;
    }
    &:disabled {
      @apply cursor-not-allowed !important;
    }
  }
  .select {
    &-sm {
      @apply h-8 text-xs !important;
    }
    &-md {
      @apply h-10 text-base !important;
    }
    &-lg {
      @apply h-12 text-lg !important;
    }
    &:disabled {
      @apply cursor-not-allowed !important;
    }
    &-outlined {
      @apply rounded-md !important;
    }
  }
  .input-radio:not(:last-child) {
    @apply mr-4;
  }
  .textarea {
    &-xs {
      @apply h-12 text-xs !important;
    }
    &-sm {
      @apply h-16 text-sm !important;
    }
    &-md {
      @apply h-24 text-base !important;
    }
    &-lg {
      @apply h-32 text-lg !important;
    }
    &:disabled {
      @apply cursor-not-allowed bg-gray-100 !important;
    }
  }
  .custom-radio,
  .custom-checkbox {
    width: 1px !important;
  }

  .form-checkbox,
  .checkbox-category {
    label {
      @apply relative;
      @apply pl-6;
    }
    input {
      @apply absolute opacity-0 h-0 w-0;
    }

    .checkmark {
      @apply w-4 h-4 left-0 absolute rounded;
      background-color: #f5f5f5;
      border: 1px solid #e0e0e0;
      top: 1px;
    }

    .checkmark::after {
      @apply absolute text-white hidden;
      content: '';
      left: 6px;
      top: 2px;
      width: 5px;
      height: 10px;
      /* background-color: #FF4307; */
      border-width: 0 1px 1px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &:hover input ~ .checkmark,
    input:checked ~ .checkmark {
      /* @apply bg-white; */
      background-color: #ff4307;
      border: none;
    }

    input:checked ~ .checkmark::after {
      @apply block;
      /* border: none; */
    }
  }

  .switch {
    @apply transition-colors flex items-center bg-primary rounded-full p-1 cursor-pointer;
    width: 47px;
    height: 25px;
    &__item {
      @apply transition-all rounded-full shadow-md;
      height: 19px;
      width: 19px;
    }
  }
}
