@layer components {
  .gallery-card {
    @apply bg-white;
    &__image {
      @apply relative;
      img {
        height: 250px;
        object-fit: cover;
      }
      &__logo {
        @apply absolute bottom-0 m-5;
        img {
          height: 80px;
        }
      }
    }
    &__profile {
      @apply p-5 pb-0;
      &__name {
        @apply pb-0 mr-auto text-left font-bold;
        &__small {
          @apply text-base;
          white-space: nowrap;
          max-width: 220px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    &__detail {
      @apply grid grid-cols-3 p-5 pt-1 text-left;
      &__field {
        &__name {
          @apply text-secondary text-sm;
          @media screen and (max-width: 675px) {
            @apply text-xs;
          }
        }
        &__value {
          @apply font-medium text-base;
          @media screen and (max-width: 675px) {
            @apply text-sm;
          }
        }
      }
    }
    &__collectible {
      @apply grid grid-cols-3 gap-6;
      &__empty {
        @apply flex items-center justify-center;
        height: 110px;
        &__title {
          @apply text-xl font-bold;
        }
        &__description {
          @apply text-base text-secondary;
          margin: 0 6vw;
        }
      }
      img {
        max-height: 110px;
        width: 100%;
      }
    }
    .gallery-card-slider {
      &__slider {
        z-index: 2;
      }
      &:hover {
        .gallery-card-slider__left {
          visibility: visible;
          opacity: 1;
        }
        .gallery-card-slider__right {
          visibility: visible;
          opacity: 1;
        }
        .slide-arrow-prev {
          @apply mr-auto;
          &-button {
            visibility: visible;
            opacity: 1;
          }
        }
        .slide-arrow-next {
          &-button {
            visibility: visible;
            opacity: 1;
          }
        }
      }
      @apply relative;
      &__slider {
        overflow: hidden;
      }
      .slider-arrow {
        pointer-events: none;
        display: flex;
        position: absolute;
        top: 30%;
        z-index: 9999;
        height: 100%;
        width: 100%;
      }

      .slide-arrow-prev {
        @apply mr-auto;
        &-button {
          @apply rounded cursor-pointer;
          pointer-events: fill;
          visibility: hidden;
          opacity: 0;
          transition: visibility 0.3s, opacity 0.3s linear;
          backdrop-filter: blur(5px);
          svg {
            height: 50px;
            width: 50px;
          }
        }
      }
      .slide-arrow-next {
        &-button {
          @apply rounded cursor-pointer;
          pointer-events: fill;
          visibility: hidden;
          margin-right: 0px;
          opacity: 0;
          transition: visibility 0.3s, opacity 0.3s linear;
          backdrop-filter: blur(5px);
          svg {
            height: 50px;
            width: 50px;
          }
        }
      }
      &__left {
        pointer-events: none;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0.3s, opacity 0.3s linear;
        position: absolute;
        width: 100px;
        height: 110px;
        top: 0;
        left: 0;
        background: linear-gradient(270deg, #f2f2f2 0%, rgba(242, 242, 242, 0) 92%);
        transform: matrix(-1, 0, 0, 1, 0, 0);
      }
      &__right {
        pointer-events: none;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0.3s, opacity 0.3s linear;
        position: absolute;
        width: 100px;
        margin-right: 0px;
        height: 110px;
        right: 0;
        top: 0;
        background: linear-gradient(270deg, #f2f2f2 0%, rgba(242, 242, 242, 0) 92%);
      }
      .next {
        float: right;
      }
      .slick-list {
        overflow: visible !important;
        .slick-track {
          margin-left: 0px !important;
          width: 6000px !important;
        }
      }
    }
    .gallery-card-collectible-card {
      width: 152px;
      &__image {
        @apply relative;
        width: 132.5px;
        height: 110px;
      }
    }
  }
}
