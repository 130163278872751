@layer components {
  .print-collectible-hero {
    &__buttons {
      @apply flex justify-center flex-col;
      @screen md {
        @apply flex-row;
      }

      button {
        @apply flex items-center justify-center;
        @apply mx-4 mb-2;
        @screen md {
          @apply justify-items-start;
        }
        svg {
          @apply mr-2 h-6;
        }
      }
    }
    &__modal {
      @apply pt-4;
    }
  }
}
