@layer components {
  .cropper {
    &__inner {
      @apply relative h-auto;
      &__image {
        @apply relative;
        height: 200px;
      }
      &__control {
        @apply pt-2;
        .css-yvszuv-Slider {
          @apply w-full;
        }
        &__button {
          @apply mt-4 text-center;
        }
      }
    }
  }
}
