/* stylelint-disable string-quotes */
@layer components {
  .print-collectible-content-right {
    &__price {
      div {
        @apply mr-2;
      }
    }

    &__vat {
      @apply mb-2;
    }

    &__shipping {
      @apply mb-8 flex text-gray-400;
      &__header {
        @apply flex justify-between items-center mb-2;

        &__country {
          @apply relative;
          &__wrapper {
            @apply flex items-center cursor-pointer font-normal capitalize tracking-wide;
            svg {
              @apply ml-0.5 text-white;
              width: 18px;
              height: 18px;
            }
          }

          &__dropdown {
            @apply absolute top-4 right-0 mt-6 p-5 w-56 lowercase bg-gray-800 shadow-secondary z-10 overflow-auto max-h-64;
            li {
              span {
                @apply block cursor-pointer;
                &:hover {
                  @apply cursor-pointer opacity-60;
                }
              }
            }
            ::first-line {
              @apply capitalize;
            }
            li:not(:last-child) {
              @apply pb-4;
            }
            &::-webkit-scrollbar {
              @apply w-2 h-2;
            }
            &::-webkit-scrollbar-thumb {
              @apply bg-gray-700 rounded;

              &:hover {
                @apply bg-gray-600;
              }
            }
          }
          &__dropdown.profile {
            @apply right-auto;
          }
        }
      }
    }

    &__subheading {
      @apply mb-8;

      span {
        font-size: 0.875rem;
      }
    }

    &__item {
      &__group {
        @apply inline-flex flex-col;
      }

      span {
        @apply uppercase;
      }

      input {
        @apply w-full;

        &[type='number'] {
          @apply w-16;
        }
      }

      &__buttons {
        @apply mt-2;

        button {
          @apply mb-2;

          &:not(:last-child) {
            @apply mr-3;
          }
        }
      }
    }

    &__submit {
      @apply text-left;
    }
  }
}
