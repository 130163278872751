@layer components {
  .card-slider {
    @media screen and (max-width: 450px) {
      padding-top: 26px;
    }
    @apply pt-11 relative;
    z-index: 2;
    &__slider {
      width: 1260px;
      @media screen and (max-width: 1300px) {
        width: 900px;
      }
      @media screen and (max-width: 800px) {
        width: 430px;
        @media screen and (max-width: 450px) {
          width: 100%;
        }
      }
      .collectible-slider-test {
        @apply relative;
      }
      .slick-custom-pagging {
        @apply absolute w-full;
        max-width: 410px;
        left: 50%;
        @media screen and (max-width: 450px) {
          max-width: 250px;
          left: 40%;
        }
        transform: translateX(-50%);
        bottom: -5px;
        /* display: flex !important; */
        display: none !important;
        flex-direction: row;
        justify-content: center;
        li {
          width: 100%;
          height: 5px;
          background-color: #5c5c5c;
        }
        li:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
        li:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
        li.slick-active {
          background-color: #ffffff;
          border-radius: 5px;
        }
      }
    }
    .slider-arrow {
      pointer-events: none;
      display: flex;
      position: absolute;
      top: 45%;
      z-index: 9999;
      height: 100%;
      width: 100%;
      @media screen and (max-width: 450px) {
        svg {
          height: 40px;
          width: 40px;
        }
      }
    }

    .slide-arrow-prev {
      @apply mr-auto;
      &-button {
        @apply rounded cursor-pointer;
        pointer-events: fill;
        backdrop-filter: blur(5px);
      }
    }
    .slide-arrow-next {
      &-button {
        @apply rounded cursor-pointer;
        pointer-events: fill;
        backdrop-filter: blur(5px);
      }
    }
    .next {
      float: right;
    }
    .slick-list {
      overflow: visible !important;
      @media screen and (max-width: 450px) {
        overflow: hidden !important;
      }
      .slick-track {
        margin-left: 0px;
      }
    }
    &__wrapper {
      width: 400px !important;
      @media screen and (max-width: 450px) {
        width: 100% !important;
      }
    }
  }
}
