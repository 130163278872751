@layer components {
  .footer {
    @apply py-6 bg-white;
    &__menu {
      @media screen and (max-width: 450px) {
        li {
          @apply block;
          &:first-child {
            @apply mb-2;
          }
        }
      }
    }
  }
  .footer-cookies {
    @apply fixed w-full bottom-8 left-0;
    color: #181818;
    z-index: 92;
    .container {
      @apply p-7 flex flex-col items-center justify-between bg-white;
      @screen md {
        @apply flex-row;
      }
    }
    .cookies-info {
      @apply flex mb-2;
      @screen md {
        @apply mb-0;
      }
    }
    .privacy-btn {
      @apply font-Avenir capitalize font-normal;
      font-size: 0.875rem;
      color: #181818;
    }
    .allow-btn {
      @apply py-1.5 px-8 font-semibold font-Avenir-NC;
      font-size: 16px;
    }
  }
  .footer-sandbox {
    @apply py-4 fixed bottom-0 left-0 w-full bg-red-300 text-white;
    z-index: 91;
    .container {
      @apply flex items-center justify-between;
    }
    svg {
      @apply cursor-pointer w-5;
    }
  }
  .footer-landing {
    @apply bg-cover bg-no-repeat bg-top py-16;
    background-image: url('@assets/img/footer.png');
    @screen sm {
      @apply pt-60 pb-14;
    }
    @screen lg {
      padding-top: 458px;
      padding-bottom: 84px;
    }
    &__wrapper {
      @apply mx-auto text-center antialiased;
      max-width: 393px;
      @screen sm {
        @apply flex items-center justify-between flex-row-reverse;
      }
    }
    &__logo {
      @apply mx-auto mb-8 w-44;
      @screen md {
        @apply mb-14 w-52;
      }
    }
    &__copyright {
      @apply pt-2;
      @screen md {
        @apply pt-0;
        min-width: 189px;
      }
    }
    &__social {
      @apply mx-auto;
      li {
        &:not(:first-child) {
          @apply ml-4;
        }
      }
      a {
        @apply my-3 border border-white py-3 text-center leading-12 transition-all;
        border-radius: 50%;
        width: 40px;
        svg {
          @apply mx-auto;
        }
        &::before {
          @apply hidden;
        }
        &:hover {
          @apply bg-white;
          svg {
            @apply text-black;
          }
        }
        @screen sm {
          @apply my-0 py-4;
          width: 50px;
        }
      }
      .social-media__menu {
        @apply justify-center;
      }
    }
    &__menu {
      li {
        @apply inline-block;
        &:not(:last-child) {
          @apply pr-9;
        }
      }
      a {
        @apply relative;
        &::before {
          @apply absolute left-0 right-0 bg-white h-px origin-right transition ease-in duration-200;
          content: '';
          bottom: -2px;
          transform: scaleX(0);
        }
        &:hover {
          &::before {
            @apply origin-left;
            transform: scaleX(1);
          }
        }
      }
    }
  }
  .footer-coming-soon {
    @screen md {
      font-size: 16px !important;
      line-height: 17.6px;
    }
    &__link {
      @apply text-white !important;
    }
    &__menu {
      li {
        a {
          &::before {
            @apply bg-white !important;
          }
        }
      }
    }
  }
  .footer,
  .footer-coming-soon {
    &__logo {
      @apply inline-block mb-3 text-black;
      .logo {
        @apply inline-block h-10;
      }
    }
    &__left {
      @media screen and (max-width: 768px) {
        @apply text-center !important;
      }
    }
    &__right {
      @apply text-right !important;

      &__social {
        @apply inline-block mb-7;
      }

      @media screen and (max-width: 768px) {
        @apply text-center !important;

        &__social {
          @apply mt-4 mb-3;
        }
      }
    }

    &__wrapper {
      @apply flex justify-between items-end;
      @media screen and (max-width: 768px) {
        @apply block;
      }
    }
    &__link {
      @apply text-black;
      &-bold {
        @apply font-semibold !important;
      }
    }

    &__menu {
      @apply block;
      &__copyright {
        @apply text-black !important;
      }
      li {
        @apply pl-6 inline-block;

        a {
          @apply relative;
          &::before {
            @apply absolute left-0 right-0 bg-black h-px origin-right transition ease-in duration-200;
            content: '';
            bottom: -2px;
            transform: scaleX(0);
          }
          &:hover {
            &::before {
              @apply origin-left;
              transform: scaleX(1);
            }
          }
        }
      }
    }
  }
  .footer-collab {
    @apply py-7 w-full text-center;
    @screen md {
      @apply py-9 flex items-center justify-center;
      padding: 74px 0;
    }
    &__with {
      display: block;
      padding: 12px 0;
      font-size: 20px;
      @screen md {
        padding: 0 18px;
        font-size: 44px;
      }
    }
    &__logo {
      img {
        @apply mx-auto;
      }
    }
    &__fans {
      @apply mx-auto;
      width: 100px;
      @screen md {
        @apply mx-0;
        width: 241px;
        height: 38px;
      }
    }
  }
}
