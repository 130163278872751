@layer components {
  .print-collectible-preview {
    @apply mx-auto;

    background-color: white;

    &.orientation-custom-portrait {
      aspect-ratio: 1 / 1.414;
      max-height: 900px;
      width: 100%;

      iframe {
        clip-path: inset(10px 10px 25px 10px);
      }
    }

    &.orientation-custom-landscape {
      aspect-ratio: 1.414 / 1;
      max-height: 900px;
      width: 100%;

      iframe {
        clip-path: inset(10px 10px 20px 10px);
      }
    }

    iframe {
      @apply w-full h-full pointer-events-none;
    }

    &.frame {
      border-style: solid;

      &-none {
        /* stylelint-disable-next-line color-function-notation, alpha-value-notation */
        border-color: rgba(0, 0, 0, 0);
      }

      &-black {
        border-color: black;
        box-shadow: inset 0 0 10px #000;
      }

      &-white {
        border-color: #eaeaea;
        box-shadow: inset 0 0 10px #000;
      }
    }

    &.frame-width {
      &-sm {
        border-width: 10px;
      }

      &-md {
        border-width: 20px;
      }

      &-lg {
        border-width: 30px;
      }
    }
  }
}
