@layer components {
  .split {
    background-color: #2a2a2a;

    hr {
      @apply mb-7;

      border-top: 1px solid #4a4a4a;
    }

    &__grid {
      @apply block !important;
      @screen md {
        @apply grid !important;
      }
      &__right {
        @apply pb-12;
      }
      &__left,
      &__right {
        @apply pt-14;

        @screen md {
          @apply pt-0 col-span-6;
          margin: 3.5rem 2.375rem;
        }
      }
    }
  }
}
