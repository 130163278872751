@layer components {
  .follow-modal {
    @apply p-0 pt-4;
    &-container {
      @apply mx-auto items-center w-auto;
      @screen md {
        @apply w-full;
        max-width: 430px;
      }
      &-close {
        @apply grid justify-items-end cursor-pointer pr-3;
      }
    }
    &-box {
      @apply px-0 pb-0;
      @screen md {
        @apply pb-10 pt-3 px-14;
      }
      h3 {
        @apply mb-8 text-left text-2xl;

        @screen sm {
          @apply text-3xl !important;
        }
      }
      .description {
        @apply mb-8;
      }
    }
  }
  .isFollowLoading {
    text-indent: -9999px;

    &:before {
      content: 'haha';
      background-image: url('@assets/svg/spinner.svg');
      @apply absolute top-2/4 left-2/4 -ml-3 -mt-3 block w-6 h-6 bg-no-repeat bg-center animate-spin;
    }
  }
  .follow-button-green {
    height: 55px; 
    .follow-cross {
      @apply hidden;
    }
    &:hover {
      .follow-double-check {
        @apply hidden;
      }
      .follow-cross {
        @apply block;
      }
    }
  }
  .follow-button.btn {
    @apply uppercase;
    @media screen and (max-width: 675px) {
      padding-right: 26px;
      padding-left: 26px;
    }
  }
}
