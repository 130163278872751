@layer components {
  .header {
    @apply w-full flex bg-gray-50 duration-700 absolute;
    min-height: 84px;
    padding-top: 18px;
    padding-bottom: 18px;
    z-index: 91;
    border-bottom: 1px solid #e9e9e9;

    &--fixed {
      @apply fixed bottom-full;
      transform: translateY(100%);
    }
    &--scroll {
      transform: translateY(0);
    }
    .logo {
      @apply block pr-4;
      svg {
        max-width: 150px;
        @screen md {
          @apply max-w-full;
        }
      }
    }
    &__logo {
      @apply flex items-center;
      &__gallery {
        @media screen and (max-width: 720px) {
          @apply hidden;
        }
        &__text {
          @apply text-primary font-bold text-2xl;
        }
        @apply pl-4;
        border-left: 1px solid #d0d0d0;
      }
    }
    &__container {
      @apply justify-between items-center flex w-full;
      &__left {
        width: 100%;
      }
      &__right {
        @apply flex w-full items-center justify-end;
        &__activity-item {
          @apply mr-16;

          @media screen and (max-width: 900px) {
            @apply hidden;
          }
        }
        &__login {
          @apply mr-4;

          @media screen and (max-width: 950px) {
            white-space: nowrap !important;
          }

          @media screen and (max-width: 400px) {
            white-space: nowrap !important;
            @apply mr-0 !important;
          }
        }
        &__notification {
          @apply mr-2;
        }
      }
      ,
      &__middle {
        @media screen and (max-width: 1300px) {
          display: block;
        }
        display: flex;
        @apply items-center w-full;
        gap: 60px;
        color: #2b2b2b;
        &__menu {
          @apply w-full text-center text-lg relative cursor-pointer;
          @media screen and (max-width: 1300px) {
            @apply hidden;
          }
          &__dropdown {
            @apply absolute top-full bg-white mt-10 z-10;
            padding: 30px;
            width: 790px;
            margin-left: -320px;
            &__list {
              .artist-profile {
                padding-bottom: 30px;
              }
              @apply grid grid-cols-2;
              &__artists {
                @apply p-6 flex;
                &__detail {
                  @apply ml-3 pt-1;
                  &__name {
                    @apply font-bold text-left;
                    font-size: 20px;
                  }
                  &__title {
                    @apply pt-1.5 text-left;
                    color: #5c5c5c;
                    font-size: 16px;
                  }
                }
              }
            }
            &__viewall {
              @apply flex text-left items-center uppercase;
              font-weight: 700;
              font-size: 18px;
            }
          }
        }
      }
      &__search {
        @apply flex items-center mr-4 cursor-pointer;
        &__input {
          @apply mr-2 w-80 text-xs;
        }
      }
      &__search-input {
        @apply mr-4 !important;
      }
    }

    &-landing {
      @apply py-8 lg:py-12 z-10;
      @media screen and (min-width: 1440px) {
        @apply py-24;
      }
      &-logo {
        @apply w-44;
        @screen sm {
          @apply w-52;
        }
      }
      &-row {
        @apply flex justify-between items-center;
      }
      &-col {
        li {
          @apply inline-block uppercase font-Satoshi;
          &:not(:last-child) {
            @apply pr-10;
          }
          a {
            @apply relative;
            &::before {
              @apply absolute left-0 right-0 bg-white h-px origin-right transition ease-in duration-200;
              content: '';
              bottom: -2px;
              transform: scaleX(0);
            }
            &:hover {
              &::before {
                @apply origin-left;
                transform: scaleX(1);
              }
            }
          }
        }
      }
      &-menu {
        @apply hidden;
        @screen lg {
          @apply block;
        }
      }
    }
  }

  .mega-menu {
    @apply fixed right-0 top-0 bg-white transition-all ease-in-out duration-500;
    height: 100vh;
    width: 470px;
    right: -100%;
    visibility: hidden;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .mega-menu-wrapper {
    @apply fixed right-0 top-0 bg-white transition-all ease-in-out duration-500;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    z-index: 1;
  }

  .mega-menu-active {
    @apply fixed right-0 top-0 bg-white transition-all ease-in-out duration-500 text-primary;
    padding-left: 55px;
    @media screen and (max-width: 600px) {
      padding-left: 15px;
    }
    padding-bottom: 48px;
    z-index: 2;
    height: 100vh;
    width: 470px;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
    margin-right: 0px;
    &__top {
      @apply flex;
    }
    &__middle {
      @apply flex;
      @media screen and (max-width: 600px) {
        padding-right: 15px;
      }
      padding-right: 55px;
      height: 90%;
      flex-flow: column;
      &__mobile {
        @apply my-auto;
        &__link {
          @apply text-secondary pb-7 cursor-pointer;
          font-weight: 700;
          font-size: 36px;
          line-height: 150%;
          &:hover {
            @apply text-black;
          }
        }
      }
      &__wrapper {
        @apply my-auto;
        &__mobile {
          @apply mb-7 hidden;
          @media screen and (max-width: 1300px) {
            @apply block;
          }
          border-bottom: 1px solid #D9D9D9;
          &__link {
            @apply text-secondary cursor-pointer;
            font-weight: 700;
            font-size: 30px;
            line-height: 150%;
            margin-bottom: 26px;
            &:hover {
              @apply text-black;
            }
          }
        }
        &__link {
          @apply text-secondary pb-7 cursor-pointer;
          @media screen and (max-width: 1300px) {
            font-size: 20px;
          }
          font-weight: 700;
          font-size: 36px;
          line-height: 150%;
          &:hover {
            @apply text-black;
          }
        }
      }
    }
    &__bottom {
      @apply flex;
      &__link {
        @apply text-secondary text-lg cursor-pointer;
        &:hover {
          @apply text-black;
        }
      }
    }
  }

  .dot {
    @apply absolute mt-3;
    margin-left: -2.5px;
    height: 5px;
    width: 5px;
    background-color: rgba(43, 43, 43, 1);
    border-radius: 50%;
    left: 50%;
    display: inline-block;
  }

  @media screen and (max-width: 768px) {
    .header-coming-soon {
      img {
        @apply m-auto;
        width: 150px;
      }
    }
  }
  @media screen and (max-width: 360px) {
    .header {
      .logo {
        svg {
          max-width: 120px;
        }
      }
    }
  }
}
