@layer components {
  .profile-header {
    &__top {
      @apply relative;
      height: 300px;
    }
    &__banner {
      @apply relative;
      &__image {
        @apply absolute left-0 top-0 w-full;
        height: 300px;
      }
      &:before {
        content: '';
        @apply absolute left-0 top-0 w-full h-full z-10 bg-opacity-5 hidden;
      }
      &--fernGreen {
        &:before {
          @apply bg-fans-fernGreen;
        }
      }

      &--sapphireBlue {
        &:before {
          @apply bg-fans-sapphireBlue;
        }
      }

      &--blue {
        &:before {
          @apply bg-fans-blue;
        }
      }

      &--berryRed {
        &:before {
          @apply bg-fans-berryRed;
        }
      }

      &--bloodRed {
        &:before {
          @apply bg-fans-bloodRed;
        }
      }

      &--pineGreen {
        &:before {
          @apply bg-fans-pineGreen;
        }
      }

      &--purple {
        &:before {
          @apply bg-fans-purple;
        }
      }

      &--violet {
        &:before {
          @apply bg-fans-violet;
        }
      }

      &--brown {
        &:before {
          @apply bg-fans-brown;
        }
      }

      &--sandBrown {
        &:before {
          @apply bg-fans-sandBrown;
        }
      }

      &--gold {
        &:before {
          @apply bg-fans-brown;
        }
      }

      &--gray {
        &:before {
          @apply bg-fans-gray;
        }
      }
    }
    &__avatar {
      @apply absolute w-full flex justify-center;
      bottom: 0;
      .avatar {
        width: 200px;
        height: 200px;
        img {
          margin-top: 120px;
        }
      }
      &__wrapper {
        @apply relative;
      }
      &__hover {
        @apply absolute left-0 top-0 w-full h-full flex items-center justify-center cursor-pointer !important;
        color: white;
        margin-top: 60px;
        border-radius: 9999px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
      }
    }
  }
  /* .profile-video {
    @apply fixed top-0 left-0 flex items-center justify-center w-full h-full bg-primary bg-opacity-60;
    z-index: 2000;
  } */
}
