@layer components {
  .collectible-grid {
    &__genre,
    &__year,
    &__category {
      @apply relative flex items-center justify-between;
      ul {
        max-height: 250px;
      }
    }
    &__artist {
      @screen sm {
        min-width: 300px;
      }
    }
    &-slider {
      @apply py-4;
      .slick-slide {
        @apply px-4;
      }
      .slick-list {
        @apply -ml-4 -mr-4;
      }
      .slick-disabled {
        &::after {
          opacity: 0.7;
        }
      }
      .slick-track {
        @apply ml-0;
      }
      .slick-prev,
      .slick-next {
        @apply w-11 h-11 z-10;
        top: 45%;
        &::before,
        &::after {
          @apply w-11 h-11 block absolute top-0;
          content: '';
          transition: 0.2s;
        }
        &::before {
          @apply border border-gray-500;
          background: #1a1a1a;
        }
      }
      .slick-next {
        @apply -right-4;
        &::after {
          background: url('@assets/svg/arrow-right.svg') no-repeat center;
        }
      }
      .slick-prev {
        @apply -left-4;
        &::after {
          background: url('@assets/svg/arrow-left.svg') no-repeat center;
        }
      }
      @media screen and (max-width: 1028px) {
        .slick-prev {
          @apply -left-3;
        }
        .slick-next {
          @apply -right-3;
        }
      }
    }
    &__header {
      @apply flex justify-between items-center mb-2;
      &__owner {
        a {
          @apply relative;
          &::before {
            @apply absolute bottom-0 left-0 right-0 bg-white h-px origin-right transition ease-in duration-200;
            content: '';
            transform: scaleX(0);
          }

          &:hover {
            &::before {
              @apply origin-left;
              transform: scaleX(1);
            }
          }
        }
      }
      &__profile {
        @apply block w-full;
        @screen sm {
          @apply flex;
        }
        &__button {
          @apply hidden;
          @screen sm {
            @apply block;
          }
          button {
            @apply w-full;
            @screen sm {
              @apply w-auto mt-0;
            }
          }
        }
        &__filter {
          @apply block relative w-full justify-between;
          @screen sm {
            @apply flex items-center;
          }
          &__item {
            @apply w-full flex items-center;
            @screen sm {
              @apply w-11/12;
            }
          }
          @media screen and (max-width: 1250px) {
            &__item {
              @apply block;
            }
          }
          &__category {
            @apply flex items-center font-Avenir-NC uppercase text-base mr-2 cursor-pointer;
            @screen sm {
              @apply mr-4;
            }
            svg {
              @apply ml-2;
            }
          }

          &__sort {
            @apply cursor-pointer;
            &__text {
              @apply flex items-center text-base text-gray-400;
            }
          }
        }
      }
      &__filter {
        @apply relative;
        &__loading {
          @apply w-44 h-8;
        }
        &__multiple {
          &__loading {
            @apply w-11/12 h-8;
          }
        }
        &-by {
          @apply mr-1 flex items-center font-Avenir-NC uppercase text-gray-darker text-base font-semibold antialiased;
          @screen sm {
            @apply mr-4 text-lg;
          }
          &__type {
            @apply mr-1;
            @screen sm {
              @apply mr-2;
            }
          }
          &__item {
            @apply flex items-center text-white cursor-pointer;
            svg {
              @apply ml-2 w-4;
            }
            &-lowercase {
              @apply lowercase;
            }
          }
        }
        &__button {
          &__wrapper {
            @apply flex items-center font-Avenir-NC text-lg uppercase font-semibold tracking-wide cursor-pointer;
            svg {
              @apply ml-2;
              width: 18px;
              height: 18px;
            }
          }
        }

        &__dropdown {
          @apply absolute top-4 left-0 mt-8 py-5 w-48 bg-gray-850 shadow-secondary z-10 text-sm overflow-y-scroll;
          &::-webkit-scrollbar {
            @apply h-2;
          }
          &::-webkit-scrollbar-thumb {
            @apply bg-gray-700 rounded;

            &:hover {
              @apply bg-gray-600;
            }
          }
          li {
            @apply pl-4;
            label {
              @apply opacity-60;
            }
            label:hover {
              @apply cursor-pointer opacity-100;
            }
          }
          li:not(:last-child) {
            @apply pb-4;
          }
        }
        &__dropdown__multiple__sort {
          @apply absolute top-4 left-0 mt-8 py-5 w-56 bg-gray-800 shadow-secondary z-10 text-sm overflow-y-scroll;
          &::-webkit-scrollbar {
            @apply w-2 h-2;
          }
          &::-webkit-scrollbar-thumb {
            @apply bg-gray-700 rounded;

            &:hover {
              @apply bg-gray-600;
            }
          }
          li {
            @apply pl-4;
            label {
              @apply opacity-60;
            }
            label:hover {
              @apply cursor-pointer opacity-100;
            }
          }
          li:not(:last-child) {
            @apply pb-4;
          }
        }
        &__dropdown__multiple {
          @apply absolute top-4 left-0 mt-5 py-5 w-56 bg-gray-850 shadow-secondary z-10 text-sm overflow-y-scroll;
          &::-webkit-scrollbar {
            @apply w-2 h-2;
          }
          &::-webkit-scrollbar-thumb {
            @apply bg-gray-700 rounded;

            &:hover {
              @apply bg-gray-600;
            }
          }
          max-height: 304px;
          li {
            @apply pl-4;
            label {
              @apply opacity-60;
            }
            label:hover {
              @apply cursor-pointer opacity-100;
            }
          }
          li:not(:last-child) {
            @apply pb-4;
          }
          &-new {
            @apply p-0;
            &::-webkit-scrollbar {
              @apply w-0 bg-opacity-0;
            }
            &-year {
              @apply w-32;
            }

            li {
              @apply py-2 px-4;
              &:not(:last-child) {
                @apply pb-2;
              }
              .checkmark {
                @apply hidden;
              }
              label {
                @apply pl-0 block;
              }
              &:hover,
              &.active {
                @apply bg-primary;
                label {
                  @apply opacity-100;
                }
              }
            }
          }
        }
        @media screen and (max-width: 1250px) {
          &__dropdown__multiple {
            @apply mt-4;
          }
          &__dropdown__multiple__sort {
            @apply mt-4;
          }
        }
      }

      &__sort-only {
        .collectible-grid__header__sort__dropdown {
          @apply left-0;
        }
      }

      &__sort {
        @apply relative text-right;
        &__wrapper {
          @apply flex items-center cursor-pointer font-semibold text-lg font-Avenir-NC uppercase tracking-wide text-gray-darker;
          svg {
            @apply ml-2 text-white;
            width: 18px;
            height: 18px;
          }
        }

        &__dropdown {
          @apply absolute top-4 right-0 mt-6 p-5 w-56 bg-gray-850 shadow-secondary z-10 text-sm;
          li {
            span {
              @apply block cursor-pointer;
              &:hover {
                @apply cursor-pointer opacity-60;
              }
            }
          }
          li:not(:last-child) {
            @apply pb-4;
          }
        }
        &__dropdown.profile {
          @apply right-auto;
        }
      }
    }
    &__content {
      @apply pt-5 grid gap-x-5 grid-cols-1 2xl:grid-cols-3 lg:grid-cols-3 sm:grid-cols-2;
    }

    &__empty {
      @apply py-24 text-center;
      &__title {
        @apply mt-5 mb-2 text-xl;
        @screen sm {
          @apply text-3xl;
        }
      }
      &__description {
        @apply m-auto text-gray-500 px-3;
        max-width: 324px;
      }
      svg {
        @apply m-auto;
      }
    }

    &__all-item-loaded {
      @apply py-8 text-center;
      &__title {
        @apply mt-5 mb-2;
      }
      &__description {
        @apply m-auto text-gray-500 px-3;
        max-width: 324px;
      }
      svg {
        @apply m-auto;
      }
    }
  }
}
