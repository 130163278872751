@layer components {
  .responsive-modal {
    height: 100%;
    width: 100%;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    &-body {
      width: 100%;
      height: 100%;
      position: relative;
      &-overlay {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 11;
      }
      &-content {
        background: #ffffff;
        box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 12;
        transition-duration: 500ms;
        font-size: 12px;
        font-weight: 700;
        width: 100%;
        padding: 30px 15px;
        .responsive-media {
          width: 100%;
          display: flex;
          height: 40px;
          color: #444444;
          border: 1px solid rgba(137, 137, 137, 0.1);
          border-radius: 6px;
          margin-top: 10px;
          &-icon {
            margin: auto;
            display: flex;
          }
        }
      }
      &-content-hide {
        background: #ffffff;
        box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
        position: absolute;
        left: 0;
        bottom: -100%;
        z-index: 12;
        transition-duration: 500ms;
        font-size: 12px;
        font-weight: 700;
        width: 100%;
        padding: 30px 20px;
        .responsive-media {
          width: 100%;
          display: flex;
          height: 40px;
          color: #444444;
          border: 1px solid rgba(137, 137, 137, 0.1);
          border-radius: 6px;
          margin-top: 10px;
          &-icon {
            margin: auto;
            display: flex;
          }
        }
      }
    }
  }
}
