@layer components {
  .notification-menu-patrons__box {
    @apply absolute right-0 z-10 py-5 mt-4 overflow-auto top-full bg-white max-h-96 overscroll-auto;
    width: 420px;

    @media screen and (max-width: 600px) {
      width: 100vw;
    }
  }

  .notification-menu-patrons {
    .unread-dropdown {
      margin-top: 18px;
      height: 8px;
    }
    .unread-page {
      margin-top: 24px;
      height: 8px;
    }
  }

  .notification-menu-patrons__box::-webkit-scrollbar {
    @apply w-1.5;
  }

  .notification-menu-patrons__box::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  .notification-menu-patrons__box::-webkit-scrollbar-thumb {
    @apply bg-secondary max-h-5;
  }
}