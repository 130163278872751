@layer components {
  .artists-page {
    @apply text-primary text-center;
    background-color: #f3f3f3;
    .filter-displayname {
      &__top {
        @apply cursor-pointer capitalize;
        margin-top: 10px;
        font-size: 26px;
        line-height: 150%;
        @media screen and (max-width: 675px) {
          font-size: 16px;
        }
      }
    }
    .artists-card-filter {
      &__profile {
        @apply flex items-center mt-5;
        &__name {
          @media screen and (max-width: 675px) {
            font-size: 14px;
            line-height: 120%;
          }
          @apply ml-4 mr-auto text-lg text-primary font-bold capitalize;
        }
      }
    }
    &__body {
      padding: 40px 0px;
      padding-bottom: 80px;
      .btn {
        @apply uppercase;
      }
      @media screen and (max-width: 1024px) {
        .artists-pointer {
          @apply hidden;
        }
      }
      @media screen and (max-width: 675px) {
        padding-bottom: 50px;
      }
      &__title {
        @apply text-2xl md:text-4xl font-bold text-left;
      }
      &__filter {
        @apply flex items-center;
        margin-top: 40px;
        @media screen and (max-width: 960px) {
          @apply block;
        }
        .marketplace-page_search {
          @media screen and (max-width: 675px) {
            @apply hidden;
          }
        }
        &__input {
          @media screen and (max-width: 1085px) {
            @apply w-6/12;
          }
          @media screen and (max-width: 800px) {
            @apply w-5/12;
          }
          @media screen and (max-width: 700px) {
            @apply w-4/12;
          }
          @media screen and (max-width: 675px) {
            @apply w-full;
          }
          @apply relative uppercase text-lg flex items-center gap-2 mr-auto w-8/12;
          flex: 1;
          color: #a0a0a0;
          input {
            @apply !important uppercase font-medium pl-0 text-primary;
            border: none !important;
            background-color: #f3f3f3;
          }
          input:focus {
            box-shadow: none;
          }
          &__dropdown {
            @media screen and (max-width: 920px) {
              margin-top: 5rem;
            }
            @apply absolute top-0 w-full text-left p-10 bg-white mt-20 z-10;
            &__artists {
              @apply text-lg font-bold text-primary;
              margin-top: 30px;
            }
          }
        }
        &__right {
          @apply flex;
          &__field {
            &__sort {
              @apply text-xl font-bold mr-2.5 ml-5;
              color: #a0a0a0;
              @media screen and (max-width: 700px) {
                @apply ml-0;
              }
            }
            @apply text-xl font-bold mr-2.5;
            color: #a0a0a0;
          }
          &__option {
            @apply text-xl font-bold;
          }
        }
      }
      &__cards {
        @apply mt-6 mb-8;
        gap: 30px;
        @media screen and (max-width: 1024px) {
          @apply grid grid-cols-2;
        }
        @media screen and (max-width: 675px) {
          @apply grid grid-cols-1;
        }
      }
    }
    .artist-page-separator.separator {
      border-top-color: #dbdbdb !important;
      border-bottom-color: #dbdbdb !important;
    }
  }
}
