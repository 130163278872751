@layer components {
  .purchase-card {
    /* height: 130px; */
    @apply w-full shadow-default border border-gray-800 p-6 bg-white-gradient;

    &__title {
      @apply uppercase;
      font-size: 10px;
      line-height: 14px;
    }

    &__tooltip {
      @apply absolute cursor-default;
      max-width: 18rem !important;
      bottom: -5rem;
      left: -27%;
      @media screen and (max-width: 800px) {
        left: 0;
      }
      a {
        @apply relative;
        &::before {
          @apply absolute bottom-0 left-0 right-0 bg-white h-px origin-right transition ease-in duration-200;
          content: '';
          transform: scaleX(0);
        }

        &:hover {
          &::before {
            @apply origin-left;
            transform: scaleX(1);
          }
        }
      }
      &__multiple {
        @apply absolute cursor-default;
        max-width: 18rem !important;
        bottom: -5rem;
        left: -10%;
        @media screen and (max-width: 480px) {
          bottom: -5rem;
          left: 0;
        }
        a {
          @apply relative;
          &::before {
            @apply absolute bottom-0 left-0 right-0 bg-white h-px origin-right transition ease-in duration-200;
            content: '';
            transform: scaleX(0);
          }

          &:hover {
            &::before {
              @apply origin-left;
              transform: scaleX(1);
            }
          }
        }
      }
    }

    &__price {
      @apply relative  antialiased;
      @screen lg {
        @apply flex justify-between items-center;
      }
      .price {
        @apply font-semibold font-Avenir-NC !important;
      }
      &__address {
        @apply flex items-center;
      }

      &__item {
        @apply text-xl flex items-center;

        @screen sm {
          @apply text-2xl !important;
        }
        & > .box {
          margin-right: 30px;
        }

        span {
          @apply ml-1;
        }
      }

      &__button {
        @apply pt-3;
        @screen lg {
          @apply pt-0;
        }
        .btn--outline {
          @apply bg-primary;
        }
        &__multiple {
          @apply flex flex-row;

          button:first-child {
            @apply mr-2;
          }
        }
        &__minted {
          display: none;
        }
      }
    }
  }
  /* .tooltip-purchase-card {

  } */
}
