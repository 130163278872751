@layer components {
  .team-card {
    @apply relative block flex-auto;

    &--item {
      @apply mb-5;
    }

    &__image {
      @apply mb-6 w-full;
    }

    &__title,
    &__position {
      @apply w-full overflow-hidden whitespace-pre block text-center;
    }

    &__title {
      @apply mb-2 font-RGTM font-normal !important;
    }

    @media screen and (max-width: 768px) {
      &__image {
        @apply mb-3;
      }
      &__title {
        @apply mb-1 text-3xl !important;
      }
    }
  }
}
