@layer components {
    .auth {
      .alert {
        max-width: 520px;
        margin-bottom: 30px;
      }
      .field-help--error {
        @apply text-left w-full pl-0;
      }
      min-width: 520px;
      @media screen and (max-width: 600px) {
        @apply w-full;
        min-width: 0px;
      }
      &__bottom {
        &__signup {
          @apply text-lg text-secondary;
          margin-top: 30px;
          &__link {
            @apply underline text-secondary;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
      .login-page-error {
        max-width: 510px;
      }
      &__error__whitelisted {
        max-width: 520px;
      }
      &__title {
        @apply text-2xl text-center;
        @screen sm {
          @apply text-3xl;
        }
      }
      &__forgot {
        &__title {
          @apply text-4xl font-bold;
        }
        &__description {
          @apply text-lg text-secondary;
          margin-top: 6px;
          max-width: 520px;
          margin-bottom: 30px;
        }
        &__backLogin {
          @apply text-lg text-secondary font-normal;
          margin-top: 30px;
          &__link {
            @apply text-secondary;
          }
        }
      }
      &__reset {
        &__title {
          @apply text-4xl font-bold;
        }
        &__description {
          @apply text-lg text-secondary;
          margin-top: 6px;
          max-width: 520px;
          margin-bottom: 30px;
        }
      }
      &-image {
        @media screen and (max-width: 1200px) {
          @apply hidden;
        }
        position: fixed;
        @apply w-full top-0 bottom-0 right-0;
        width: 50%;
        background: url('@assets/img/coming-soon-image.png') no-repeat;
        background-size: cover;
      }
      &__description {
        @apply mb-6 mt-4 text-lg;
        width: 510px;
        @media screen and (max-width: 600px) {
          @apply w-full;
        }
        color: #5C5C5C
      }
      &__recaptcha {
        @apply flex mb-4;
        &__body {
          @apply m-auto;
        }
      }
      &-or {
        @apply my-4 md:my-6 relative text-center;
        &-text {
          @apply z-10 px-2 bg-gray-50 w-max mx-auto lowercase font-medium text-lg;
        }
        &-line {
          @apply absolute left-0 w-full mt-4;
          background-color: #E0E0E0;
          height: 1px;
          z-index: -1;
        }
      }
      &-section {
        @apply grid grid-cols-2;
        @media screen and (max-width: 1200px) {
          @apply grid grid-cols-1;
          min-height: calc(100vh - 121px)
        }
      }
      &-container {
        @apply m-auto w-auto flex items-center justify-center;
        min-height: 100vh;
        @media screen and (min-width: 1500px) {
          @apply ml-auto mr-10;
        }
      }
      &-box {
        @apply p-6 text-primary text-center w-full;
        @screen md {
          @apply p-11;
        }
        h3 {
          @apply mb-6 text-center;
        }
      }
      &__instruction {
        @apply mb-4;
      }
      &-footer {
        @apply mt-5 px-16 text-center;
        a {
          @apply underline;
          &:hover {
            @apply no-underline;
          }
        }
      }
      &__register {
        a {
          @apply underline;
          &:hover {
            @apply no-underline;
          }
        }
        &__policy {
          @apply my-2 p-5 text-primary font-medium text-xs text-center text-lg;
          color: #5C5C5C;
          &__bold {
            @apply font-semibold;
          }
          a {
            @apply underline;
            color: #5C5C5C;
            &:hover {
              @apply no-underline;
            }
          }
        }
      }
      &__form {
        &__password-requirement {
          @apply mt-2 ml-1 text-gray-400 italic text-xs text-left;
        }
        &__flex {
          @apply grid grid-cols-2 gap-6;
        }
      }
      &__verification {
        @apply font-Satoshi text-primary !important;
        height: 100vh;
      }
      &__checkbox-remember {
        .form-checkbox {
          @apply mb-2 flex items-center;
          .checkmark {
            height: 18px;
            width: 18px;
            top: 5px;
          }
        }
      }
      .form-field {
        @apply mb-4;
      }
    }
  
    @media screen and (min-width: 390px) {
      .auth {
        &__checkbox-remember {
          @apply flex items-center justify-between;
          .form-checkbox {
            @apply mb-0;
          }
        }
      }
    }
  }
  