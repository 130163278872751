@layer components {
  .official-badge {
    &-image {
      img {
        @apply w-full;
      }
    }
    &-default {
      @apply mb-4 border-t border-b border-white py-2 inline-block text-white text-center font-Avenir-NC font-normal text-4xl uppercase;
    }
  }

  @media screen and (max-width: 400px) {
    .official-badge {
      @apply font-normal !important;
    }
  }
}
