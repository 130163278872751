@layer components {
  .creator-card {
    @apply relative block flex-auto;

    &--item {
      @apply mb-5;
    }

    &__image {
      @apply mb-3 shadow-secondary;
    }

    &__title,
    &__link {
      @apply w-full overflow-hidden whitespace-pre block;
    }

    &__title {
      @apply mb-1 font-Avenir-NC font-semibold text-lg text-white;
      @screen sm {
        @apply text-2xl;
      }
    }

    &__link {
      @apply text-xs leading-none;

      &:hover {
        @apply underline;
      }
    }
  }
}
