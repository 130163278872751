@layer components {
  .user-card {
    @apply block w-full text-center;
    @screen lg {
      @apply flex flex-wrap items-center mx-auto w-10/12 max-w-6xl text-left;
    }
    &__avatar {
      @apply relative right-0 z-10 inline-block;
      @screen lg {
        @apply -right-5 block;
      }
      .avatar {
        @apply mx-auto;
      }
      &__category {
        @apply mt-3 inline-block px-2;
        background: rgba(255, 255, 255, 0.5);
        padding-top: 2px;
        padding-bottom: 2px;
        font-size: 0.65rem;
        @screen md {
          font-size: 0.75rem;
        }
      }
    }
    &__separator {
      @apply my-3;
    }
    &__content {
      @apply relative flex-1 -top-6 left-0 pt-12 pb-10 px-10 bg-gray-850 shadow-default;
      @screen lg {
        @apply -left-5 pr-10 -top-0 pl-20 py-9;
      }
      &__heading {
        @apply block text-left;
        @screen lg {
          @apply flex items-center justify-between;
        }
        &__verified {
          @apply relative inline-block mt-3 pr-4 text-xs;

          &:before {
            content: '';
            @apply absolute top-0.5 right-0 w-3 h-3;
            background: url('@assets/svg/verified_creator.svg');
          }
        }
        &__name {
          @apply text-2xl font-Avenir-NC font-semibold normal-case break-all whitespace-pre-wrap !important;

          @screen sm {
            font-size: 46px !important;
            line-height: 50px !important;
          }
        }
        &__meta {
          @apply mt-2 text-left;
          @screen lg {
            @apply text-right mt-0;
          }
          &__join-date {
            @apply mb-2 text-gray-400;
          }
        }
      }
      &__bio {
        @apply mt-3 text-left;
      }
    }
    &.has-bio,
    &.has-social {
      .user-card__content {
        &__heading {
        }
      }
    }
  }
}
