@layer components {
  .collectible-card-user {
    @apply flex items-center;
    .avatar.has-picture {
      @apply bg-transparent !important;
    }
    &-avatar {
      @apply mr-2 overflow-hidden shadow-none !important;
      border-radius: 50%;
      height: 30px !important;
      width: 30px !important;
      &-fan {
        height: 15px !important;
        width: 15px !important;
      }
    }
    &-verified {
      @apply ml-1 block;
    }
  }
}
