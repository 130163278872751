/* stylelint-disable string-quotes */
@layer components {
  .print-collectible-print-preview {
    @apply w-full;

    &__buttons {
      @apply absolute -top-14 right-0;
      @apply flex;

      button {
        @apply flex;
        @apply mx-4 mb-2;

        svg {
          @apply mr-2 h-6;
        }
      }
    }

    &__tagline {
      @apply text-center -mt-1 mb-4;
    }
  }
}
