@layer components {
  .user-link {
    @apply flex items-center relative justify-center;

    &__name {
      @apply ml-2.5;
    }

    .avatar {
      @apply mr-2;
    }

    &__tooltip {
      @apply absolute left-8 cursor-default font-sans text-sm;

      .collectible-tooltip-content {
        @apply bg-black hidden text-white;

        &-show {
          @apply block z-10;
        }
      }

      .collectible-tooltip-arrow {
        @apply border-black;
      }
    }
  }
}
