/* stylelint-disable string-quotes */
@layer components {
  .patrons-mint {
    &-status {
      @apply mt-6 text-center;
      padding: 26px;
      background: #f2f2f2;
      border-radius: 4px;
      @screen md {
        @apply p-8;
      }
      &__button {
        @apply mt-3 mx-auto flex items-center;
        svg {
          @apply mr-2;
        }
      }
      &__title {
        @apply mb-4 text-xl font-bold capitalize text-primary;
      }
      &__description {
        @apply text-primary text-lg;
        @media screen and (max-width: 675px) {
          @apply text-base !important;
        }
      }
      &__icon {
        @apply mx-auto mb-4;
      }
      &-error {
        background: rgba(251, 87, 88, 0.1);
        border: 2px solid #fb5758;
        border-radius: 4px;
      }
      &-success {
        background: rgba(18, 171, 49, 0.1);
        border: 2px solid #12ab31;
      }
    }
    &__top {
      @apply grid grid-cols-2 text-primary;
      @media screen and (max-width: 820px) {
        @apply grid grid-cols-1;
        margin-bottom: 50px;
        &__left {
          @apply order-last;
          margin-top: 0px;
        }
      }
      &__right {
        @media screen and (max-width: 820px) {
          background: unset !important;
          height: fit-content !important;
          padding: 40px 20px 20px 20px;
        }
        &__marquee {
          @media screen and (max-width: 820px) {
            @apply hidden;
          }
        }
        @apply bg-gray-200 relative overflow-hidden;
        height: 80vh;
        &__content {
          @apply h-full flex items-center justify-center;
          &__wrapper {
            @media screen and (max-width: 820px) {
              width: 100%;
            }
            z-index: 50;
            width: 80%;
            max-width: 500px;
            canvas {
              @media screen and (max-width: 820px) {
                @apply w-full h-auto !important;
              }
            }
          }
        }
      }
      &__left {
        @apply flex items-center justify-center;
        padding: 0px 20px;
        &__content {
          max-width: 520px;
          &__select {
            @apply cursor-pointer;
            &__wrapper {
              @apply relative;
              .select-multiple__dropdown {
                @apply w-full mt-14;
              }
            }
            @apply flex items-center text-lg;
            padding: 9.5px 20px;
            background: #f5f5f5;
            border: 1px solid #e0e0e0;
            border-radius: 4px;
            &__text {
              @apply mr-auto;
            }
          }
          &__title {
            @apply text-4xl font-bold;
            margin-bottom: 30px;
            @media screen and (max-width: 675px) {
              font-weight: 700;
              font-size: 26px;
              line-height: 150%;
              margin-bottom: 16px;
            }
          }
          &__form {
            &__label {
              @apply text-lg font-medium;
              margin-bottom: 6px;
            }
            .select-wrapper {
              .text-gray-400 {
                @apply text-primary !important;
              }
            }
          }
          &__description {
            @apply text-lg;
            margin-top: 30px;
            line-height: 170%;
            letter-spacing: -0.02em;
            color: #5c5c5c;
            @media screen and (max-width: 675px) {
              font-weight: 400;
              font-size: 16px;
              line-height: 170%;
              margin-top: 0px;
            }
          }
          &__button {
            margin-top: 30px;
            .connect-wallet-button {
              @apply text-lg;
              p {
                @media screen and (max-width: 675px) {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 170%;
                  margin-top: 0px;
                }
                color: #5c5c5c !important;
              }
              #walletButton {
                margin-bottom: 30px;
              }
            }
          }
        }
      }
    }
    &-form {
      &__submit {
        .btn {
          @media screen and (max-width: 675px) {
            @apply w-full;
          }
          @apply uppercase;
        }
      }
    }
  }
}
