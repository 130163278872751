@layer components {
  .footer {
    @apply py-6;
    background-color: #2B2B2B;
  }

  .footer-coming-soon-patrons {
    @screen md {
      font-size: 18px !important;
      line-height: 22px;
    }

  }
}