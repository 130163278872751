@layer components {
  .galleries-page {
    @apply text-primary text-center;
    background-color: #f3f3f3;
    .filter-displayname {
      &__top {
        @apply cursor-pointer capitalize;
        margin-top: 10px;
        font-size: 28px;
        line-height: 150%;
        @media screen and (max-width: 675px) {
          font-size: 16px;
        }
      }
    }
    &__body {
      .marketplace-page__body__result {
        .point-show {
          @media screen and (max-width: 1280px) {
            @apply hidden;
          }
        }
      }
      padding: 40px 0px;
      padding-bottom: 80px;
      @media screen and (max-width: 1070px) {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
      }
      &__title {
        @apply text-4xl font-bold text-left;
        @media screen and (max-width: 675px) {
          margin-top: 10px;
          font-size: 26px;
          line-height: 150%;
        }
      }
      &__filter {
        @apply flex items-center;
        @media screen and (max-width: 825px) {
          @apply relative;
        }
        @media screen and (max-width: 675px) {
          @apply block;
        }
        .marketplace-page_search {
          @media screen and (max-width: 675px) {
            @apply hidden;
          }
        }
        margin-top: 40px;
        &__input {
          @apply uppercase relative text-lg flex items-center gap-2 mr-auto w-8/12;
            @media screen and (max-width: 1100px) {
              @apply w-6/12;
            }
            @media screen and (max-width: 825px) {
              @apply static w-4/12;
            }
            @media screen and (max-width: 675px) {
              @apply w-full;
            }
          color: #a0a0a0;
          input {
            @apply !important uppercase font-medium pl-0 text-primary;
            border: none !important;
            background-color: #f3f3f3;
          }
          input:focus {
            box-shadow: none;
          }
        }
        &__right {
          @apply flex;
          &__field {
            &__sort {
              @apply text-xl font-bold mr-2.5 ml-5;
              color: #a0a0a0;
              @media screen and (max-width: 700px) {
                @apply ml-0;
              }
            }
            @apply text-xl font-bold mr-2.5;
            color: #a0a0a0;
          }
          &__option {
            @apply text-xl font-bold;
          }
        }
      }
      &__cards {
        @apply mt-6 mb-8;
        gap: 30px;
        @media screen and (max-width: 800px) {
          @apply grid grid-cols-1;
        }
      }
    }
    .galleries-page-separator.separator {
      border-top-color: #dbdbdb !important;
      border-bottom-color: #dbdbdb !important;
    }
    .galleries-avatar-filter__profile {
      @apply flex items-center capitalize;
      gap: 10px;
      margin-bottom: 30px;
      &__name {
        @apply font-bold;
        @media screen and (max-width: 675px) {
          font-size: 14px;
          line-height: 120%;
        }
      }
    }
  }
}
