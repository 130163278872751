@layer components {
  .card-user {
    @apply shadow-default border border-gray-800 p-6 bg-white-gradient w-full flex flex-row-reverse justify-end sm:flex-row sm:justify-between items-center;
    &__index {
      @apply mb-1 font-Avenir-NC uppercase text-sm  font-semibold antialiased;
      @screen md {
        @apply mb-4 text-lg;
      }
    }
    &__text {
      @apply ml-4;
      @screen sm {
        @apply ml-0 py-0;
      }
    }
    &__name {
      @apply font-semibold font-Avenir-NC text-xl;
      @screen sm {
        @apply text-2xl;
      }
      @screen md {
        font-size: 30px;
        line-height: 41px;
      }
    }

    &__role {
      @apply uppercase text-gray-darker text-xs;
    }

    &__link {
      line-height: 22px;
    }

    &__avatar {
      @apply shadow-none !important;
      height: auto;
      max-height: 5rem !important;
      max-width: 5rem !important;
      @screen md {
        max-height: 8rem !important;
        max-width: 8rem !important;
      }
    }

    &__nft {
      @apply flex gap-4 items-center mt-2.5;
    }
  }
}
