@layer components {
  .home {
    &-title {
      @apply text-2xl;
      @screen sm {
        @apply text-3xl !important;
      }
    }
  }
}
