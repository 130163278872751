@layer components {
  .collectible-timer {
    &__label {
      @apply inline-block text-gray-400 text-base;
      @screen sm {
        @apply text-lg;
      }
    }
    &__counter,
    &__date {
      @apply inline-block text-white ml-0.5;
      .countdown {
        @apply m-0;
      }
    }
    &__counter {
      @apply capitalize;
    }
  }
}
